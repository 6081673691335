@media only screen and (max-width: $braun-s - 1) {
	.bra-imageBlock {
        width: calc(100% - 40px);
        margin: 0 20px;
        // width: calc(100% - 30px);
		// margin: 0 30px;
		// .bra-title {
		// 	font-size: 35px;
		// 	line-height: 44px;
		// }
		.bra-imageBlock__wrapper {
			.bra-subtitle {
				margin: 10px auto;
			}
			.bra-paragraph {
				margin-bottom: 10px;
				font-size: 13px;
			}
		}

		// &__images {
		// 	flex-direction: column;
		// }

		&--slim {
			.bra-imageBlock__wrapper {
				max-height: 80px;
			}
		}
		&.bra-imageBlock--captioned.bra-imageBlock--spaced {
			& .bra-imageBlock__images {
				flex-wrap: wrap;
				width: 100%;
			}
			& .bra-imageBlock__wrapper {
				flex: initial;
				flex-direction: row;
				flex-wrap: wrap;
				width: 100%;
				margin: 0 0 20px 0;

				&:last-of-type {
					margin: 0;
				}
				& .bra-image {
					width: 100%;
					max-width: 100%;
					height: auto;
					max-height: initial;
				}
			}
			& .bra-imageBlock__content {
				width: 100%;
			}
		}
	}
}