@media only screen and (max-width: $braun-s - 1) {
    .bra-promo-tiles {
        padding: 0 20px;

        .bra-card.bra-card--bg-image,
        .bra-card .bra-card__wrapper,
        .bra-card .bra-card__wrapper {
            max-width: none !important;
            height: auto !important;
            min-height: initial !important;
            max-height: initial !important;
        }

        .bra-card__visual {
            // height: calc(100vw - 40px) !important;
            height: auto !important;
            // height: 0 !important;
            // padding-bottom: 100% !important;
            // max-height: 214px !important;
            max-height: none !important;
            border-radius: 5px 5px 0 0;

            & .bra-image {
                height: 0;
                padding-bottom: 76.42%;
                top: -20px;

                & .bra-image__image {
                    position: absolute;
                }
            }
        }
        & .bra-card__content {
            padding-top: 10px;
        }
        .bra-card__actions {
            border-radius: 0 0 5px 5px;
        }
    }

    .bra-promo-tiles {
        .bra-card {
            width: 100%;
            min-height: initial;
        }

        .bra-card,
        .bra-card__wrapper {
            border-radius: 5px !important;
        }
    }
}