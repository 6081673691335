@charset "utf-8";
/* CSS Document */

* {
	margin: 0;
	padding: 0;
}
*:focus,
input[type="checkbox"]:focus {
    outline: none;
}
.clearer {
	width: 100%;
	clear: both;
}
html,
body {}
img {
	border: none;
}
header, nav, article, aside, section, footer, main, figure, figcaption, hgroup {
	display:block;
}
.clearfix:before, .clearfix:after {
	content: "\0020";
	display: block;
	height: 0;
	overflow: hidden;
}
.clearfix:after {
	clear: both;
}
.clearfix {
	zoom: 1;
}
input::-ms-clear {
	display: none;
}