@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-textbox {
		margin: 0 30px;

		.bra-title {
			font-size: 30px;
		}

		.bra-paragraph {
			font-size: 13px;
		}
    }
}