.bra-pdp__prices {
  display: flex;
  flex-direction: column;
  @include font;

  .--list {
    display: flex;
    align-items: baseline;
    list-style: none;
    flex-wrap: wrap;
    column-gap: 7px;
  }

  .--mainPrice {
    @include fontBold;
    font-size: 24px;
    width: 100%;
  }

  .--rrpLabel,
  .--strikePrice,
  .--discountPrice,
  .--lastLowerPriceLabel,
  .--lastLowerPrice,
  .--discountLastLowerPrice {
    font-size: 15px;
  }

  .--strikePrice {
    margin-top: 14px;
  }

  .--strikePrice,
  .--lastLowerPrice.--line-through {
    text-decoration: line-through;
  }

  .--lastLowerPriceLabel,
  .--lastLowerPrice,
  .--discountLastLowerPrice {
    font-size: 13px;
    color: $ui-09;
  }

  .--lastLowerPrice {
    margin-top: 9px;
  }

  .--icon {
    @include fontBold;
    background-color: $brand-01;
    color: $brand-03;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    top: -2px;
    position: relative;
  }

  .--weeeTax {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 4px;
    margin-block: 5px;
    font-size: 12px;

    .dln-tooltip {
      margin-top: 2px;
    }
  }
}
