@media only screen and (max-width: $braun-s - 1) {
    .bra-frequently-bought-together {
        &__wrapper {
            flex-direction: column;
            align-items: flex-end;

            .bra-btn {            
                margin-left: 0;
                width: 100%;
            }

            .swiper-container {
                width: 80%;
                align-self: flex-start;
                margin: 0;

                .swiper-slide + .swiper-slide:before {
                    transform: translate(calc(-50% - 5px), -50%);
                }
            }
        }

        &__add-to-cart {
            width: 100%;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;
            padding: 0;
            padding-top: 20px;
        }
        &__price-total {
            padding: 0 10px 0 20px;
        }
    }
}
