.bra-link {
    --fontSize: 17px;
    --lineHeight: 23px;

    font-size: 17px;
    line-height: 23px;

    &:link,
    &:visited {
        display: inline-flex;
        flex-wrap: wrap;
        width: auto;
        font-weight: 500;
        color: $brand-01;
        text-decoration: underline;
        transition: color 0.22s linear 0.08s;
    }
    @include hover-supported() {
        &:hover {
            color: $support-02;
            text-decoration: none;
        }
    }
    &__iconWrap {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2em;
        height: auto;
        font-size: 1em;
    }
    &--bold {
        @include fontBold;
    }
    &--medium {
        @include fontMedium;
    }
    &--hideUnderline {
        &:link {
            text-decoration: none;
        }
        @include hover-supported() {
            &:hover {
                text-decoration: underline;
            }
        }
    }
}