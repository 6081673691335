@media only screen and (max-width: $braun-s - 1) {
    .bra-prodTile {
        &__head {
            padding: 0 5px;
            box-sizing: border-box;

            & .bra-btnIcon--wishlist {
                width: 20px;
                height: 20px;

                & .bra-icn {
                    &::before {
                        font-size: 20px;
                    }
                }
            }
        }
        &__product {
            padding: 15px 10px;
        }
        &__imgWrap {
            height: 100px;
        }
        &__priceWrap {
            margin-bottom: 10px;
        }
        &__name,
        &__type,
        &__price {
            line-height: 16px;
            font-size: 13px;
        }
        &__mobile {
            display: block;
        }
        &__desktop {
            display: none;
        }
        &__foot {
            flex-direction: column;
            height: auto;

            & .bra-prodTile__rating {
                margin: 0 0 15px 0;
            }
            & .bra-input--checkbox {
                margin: 0 auto;
            }
        }
        &--compare {
            &.bra-prodTile--collapsed {
                & .bra-prodTile__head {
                    top: 5px;
                    right: 5px;
                }
                & .bra-prodTile__product {
                    justify-content: center;
                    padding: 15px 15px 10px 15px;
                    box-sizing: border-box;
                }
                & .bra-prodTile__imgWrap {
                    width: 60px;
                    height: 60px;
                    margin: 0 0 10px 0;
                }
                & .bra-prodTile__productInfo {
                    width: 100%;

                    & .bra-prodTile__name {
                        display: none;
                    }
                    & .bra-prodTile__type {
                        line-height: 11px;
                        font-size: 11px;
                        text-align: center;
                    }
                }
            }
        }

        &__badges {
            .bra-badge {
                height: unset;
                margin-bottom: 5px;
            }
        }
    }
}
