.bra-grid-box {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;

    & > .page_margin {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
    }
    &__grid {
        display: flex;
        flex-wrap: wrap;
        // align-items: flex-start;
        align-content: flex-start;
        width: 100%;
    }
    & .bra-card {
        width: calc(25% - 15px);
        // flex: 0 1 calc(25% - 20px);
        margin: 0 20px 20px 0;

        &:nth-of-type(4n+4) {
            margin: 0 0 20px 0;
        }
        &__visual {
            padding: 30px 30px 0 30px;
            box-sizing: border-box;

            & .bra-image__image {
                object-fit: contain;
            }
        }
        &__content {
            & .bra-title {
                line-height: 15px;
            }
        }
    }
}