@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .dln-drawer {
	    font-size: 13px;

	    .utilityLinks {
	        font-size: 13px;
	        a {
	        	font-size: 13px;
	        }
	    }

	    .user {
	        font-size: 13px;

	        a,
	        a.bra-btn--rounded {
	        	font-size: 13px;
	        }
	    }
	}
}