.bra-pagination {
	display: inline-flex;

	font-size: 15px;
	line-height: normal;

	.bra-pagination__list {
		display: inline-flex;
		flex-basis: 0;
		flex-grow: 0;

		.bra-pagination__item {
			display: flex;
			flex-basis: 0;
			.bra-link {
				text-align: center;
				padding: 7px 10px;
				text-decoration: none;
				border: 1px solid $ui-01;
				@include fontBold;
				vertical-align: middle;
				display: flex;
				text-align: center;
				text-align-last: center;

				@include hover-supported() {
					&:hover {
						text-decoration: underline
					}
				}

				&__text {
					display: block;
					width: 100%;
					min-width: 1em;

					&:empty {
						display: none
					}
				}
				&__iconWrap {
					width: auto;
					min-width: 1em;
					display: flex;
					height: 100%;
					width: 100%;
					font-size: 10px;

					i {
						display: inline;
						height: auto;
						line-height: 0;

						&::before {
							font-size: 1.7em;
							margin: 0;
						}
					}
				}
			}
		}

		.bra-pagination__item + .bra-pagination__item {
			margin-left: 4px
		}
	}
}