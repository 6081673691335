@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
  .bra-compare-product {
    &__selected {
      .bra-card__visual {
          & .bra-image {
              max-width: 240px;
              height: 220px;

              img {
                object-fit: unset;
            }
          }
      }
    }
    .bra-card__visual {
      & .bra-image {
          max-width: 240px;
          height: 220px;

          img {
              object-fit: unset;
          }
      }
    }
  }
}
