@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-toolbarPlp {
        &__wrap {
            & .bra-btnView {
                display: inline-flex;
            } 
        }
        &__accordion {
            & .bra-input--checkbox {
                width: 33.33%;
            }
            & .bra-radio {
                width: 33.33%;
            }
        }        
    }
}
