.bra-star-rating {
    display: flex;

    &__stars {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        cursor: pointer;
    }

    &__star-input {
        display: none;
        position: absolute;
        pointer-events: none;
    }

    &__item {
        margin-right: 5px;
        font-size: 16px;
        line-height: 1;
        color: $ui-08;
    }

    &__reviewCounter {
        font-size: 15px;
    }

    &:not(.bra-star-rating--disabled) .bra-star-rating__selected {
        .bra-icn::before,
        & ~ .bra-star-rating__item .bra-icn::before {
            color: #e99d1e;
        }
    }

    &:not(.bra-star-rating--disabled) .bra-star-rating__item {
        &:hover .bra-icn::before,
        &:hover ~ .bra-star-rating__item .bra-icn::before {
            color: rgba(233, 157, 30, .5);
        }
    }
    &--static {
        & .bra-star-rating__stars {
            cursor: initial;
            & .bra-star-rating__item {
                pointer-events: none;
            }
        }
    }
}