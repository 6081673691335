@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-navigation {
		&__list {
			&-item {
				label {
					font-size: 24px
				}
			}
		}

		&__footer {
			// width: calc(100% - 70px);
		}
	}
}