.bra-checkbox {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    height: auto;
    min-height: 16px;

    &__wrapper {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
    
        .bra-checkbox {
            margin: 0 10px;
            min-width: initial;
            max-width: 180px;
            width: calc( 33.3% - 20px );
        }
    }

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        width: auto;
        max-width: 100%;
        min-height: 16px;
        padding: 0 0 0 16px;
        box-sizing: content-box;
        position: relative;
        z-index: 1;

        .bra-input__wrapper {
            flex: 1 1 calc(100% - 30px);
        }
    }
    &__input {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 4;
    }
    &__inputPlaceholder {
        display: block;
        width: 14px;
        height: 14px;
        border: 1px solid $ui-02;
        border-radius: 2px;
        background-color: $brand-03;
        position: absolute;
        top: 2px;
        left: 0;
        z-index: 2;
        box-sizing: border-box;
        transition: .3s;
        flex-shrink: 0;

        &::after {
            content: '';
            display: block;
            width: 8px;
            height: 8px;
            border-radius: 1px;
            background-color: $support-02;
            transform: scale(0, 0);
            transform-origin: center;
            transition: transform .08s ease;
            transition: .3s;
            position: absolute;
            top: calc(50% - 4px);
            left: calc(50% - 4px);
            z-index: 3;
        }
    }
    &__input:checked+.bra-checkbox__inputPlaceholder::after {
        transform: scale(1, 1);
    }
    &__label {
        display: inline-block;
        width: auto;
        max-width: 100%;
        min-height: 15px;
        line-height: 15px;
        font-size: 13px;
        font-weight: 400;
        color: $brand-02;
        transition: .2s linear;
        margin: 0 0 0 8px;
    }

    &__checkbox-error {
        padding-left: 30px;
        color: #f18f32;
        font-size: 10px;
        line-height: 2.2;
    }

    &__submit-error {
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: solid 1px #f18f32;
        background-color: rgba(241, 143, 50, 0.1);
        padding: 11px 30px 11px 15px;
        margin-top: 20px;
        max-width: 470px;

        font-size: 13px;
        line-height: 1.46;
        color: $brand-01;

        .bra-icn {
            color: $brand-03;
            width: 20px;
            min-width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #f18f32;
            border-radius: 50%;
            font-size: 18px;
            margin-right: 15px;
        }
    }

    & .bra-checkbox__input:checked+.bra-checkbox__inputPlaceholder+.bra-checkbox__label {
        color: $support-02;
        @include fontBold;
    }
  
    &--interactive {
        display: flex;
        flex-direction: column;

        &.bra-checkbox--tooltip {
            .bra-input__label {
                font-size: 13px;
                line-height: 1.46;
                margin-right: 26px;
                line-height: 16px;
            }
        }

        .bra-checkbox__wrap {
            width: 100%;
        }

        .bra-checkbox__input {
            width: calc( 100% - 16px );

            &:checked + .bra-checkbox__inputPlaceholder + .bra-checkbox__label {
                @include font;
                font-weight: 400;
                
                color: $brand-02;
            }
        }

        .bra-checkbox__tooltipIconWrap {
            position: relative;
            cursor: pointer;

            input:hover + .bra-icn {
                color: $brand-01;
            }

            .bra-checkbox__tooltipIcon {
                border: 1px solid #000;
                border-radius: 10px;
                height: 20px;
                width: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 13px;
                line-height: 1.69;
            }
            .bra-checkbox__tooltipController {
                position: absolute;
                right: 0;
                opacity: 0;

                cursor: pointer;

                width: 100%;
                height: 100%;
                z-index: 10;

                &:checked + .bra-icn {
                    //color: $support-02;
                }
            }
        }
        .bra-checkbox__confirmWrap,
        .bra-checkbox__tooltipWrap {
            height: 0;
            margin-top: 0;
            transition: height 0.22s ease-out,
                        margin-top 0.22s ease-out;
            box-sizing: content-box;
            overflow: hidden;
        }

        .bra-checkbox__tooltipWrap {
            font-size: 11px;
            line-height: 16px;
            @include font;
            color: $ui-09;

            @media only screen and (max-width:599px) {
                font-size: 13px;
            }
        }

        &.tooltipVisible {
            .bra-checkbox__tooltipWrap {
                margin-top: 21px;
                transition: height 0.22s ease-out;
            }
        }

        &.confirmVisible {
            .bra-checkbox__confirmWrap {
                margin-top: 30px;
                transition: height 0.22s ease-out;
            }
        }
    }

    &--submit { 
        .bra-checkbox__wrap {
            padding: 0 0 30px 0;
            width: 90%;
        }

        .bra-input__wrapper {
            align-items: flex-start;
        }

        .bra-input__label {
            padding: 0;
        }

        .bra-checkbox__tooltipWrap {
            font-size: 13px;
            line-height: 1.46;
        }

        &.tooltipVisible {
            .bra-checkbox__tooltipWrap {
                margin-top: 30px;
            }
        }
    }
}

