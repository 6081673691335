$paddingContainer: 30;

@mixin customSimplebar() {
  .simplebar-vertical {
    width: 3px !important;
    background-color: #e6e6e6;
    border-radius: 3px !important;
  }

  .simplebar-scrollbar:before {
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    opacity: 1 !important;
    border-radius: 3px !important;
  }
}

.braun-nav-desktop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 11;
  display: none;

  .hasStripe & {
    padding-top: 46px;
    margin-top: 30px;
  }

  &__mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($brand-01, 0.5);
    cursor: pointer;
  }

  &__drawer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 60px;
    width: 100%;
    max-height: 611px;
    background-color: $brand-03;
    transition: width 0.3s ease-in-out;

    @media screen and (min-width: $braun-l) {
      max-height: 715px;
    }
  }

  &__drawer-content {
    position: relative;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    pointer-events: all;
    overflow-x: hidden;
    max-width: calc(1440px + #{($paddingContainer * 2)}px);
    padding: 120px #{$paddingContainer}px 0;
    margin: 0 auto;
    background-color: $brand-03;
    @include customSimplebar();
  }

  &__close-cta {
    background: none;
    border: 0;
    position: absolute;
    right: #{$paddingContainer}px;
    cursor: pointer;
    z-index: 1;
    background-image: url('#{$pathAssets}/images/icons/braun-header-icon-close.svg');
    background-repeat: no-repeat;
    background-position: center center;
    width: 18px;
    height: 18px;
  }

  &__submenu-wrapper {
    height: 100%;
  }

  &__submenu-headline {
    font-size: 24px;
    line-height: 30px;
    @include fontBold;
    color: $brand-01;
    margin: 0 0 40px 0;
  }

  &__submenu-content {
    display: flex;
    @include gap(5px, 'r');
    padding-bottom: 50px;
  }

  &__submenu-tree {
    flex: 1;
    position: relative;
    width: 50.694444444%;
  }

  &__submenu-card-wrapper {
    width: 49.305555556%;
    display: flex;
    justify-content: flex-end;
  }

  &__submenu2-ul,
  &__submenu3-wrapper {
    width: calc(50% - 10px);
    background-color: $brand-03;
    max-height: 380px;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    @include gap(20px, 'b');

    @media screen and (min-width: $braun-l) {
      max-height: 450px;
    }
  }

  &__submenu3-wrapper {
    display: none;
    width: 50%;
    position: absolute !important;
    right: 0;
    top: 0;
    padding-right: 5px;
    @include customSimplebar();

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  &__submenu2-li,
  &__submenu3-li {
    a,
    button {
      display: inline-block;
      width: 100%;
      height: 100%;
      color: $brand-01;
      text-decoration: none;
      background: none;
      border: 0;
      padding: 0;
      margin: 0;
      font-size: 18px;
      line-height: 24px;
      @include font;
      transition: color 0.1s ease-in-out;
      text-align: left;
      cursor: pointer;

      &.--open,
      &:hover {
        color: $support-02;
      }
    }
  }
}
