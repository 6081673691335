@media only screen and (max-width: $braun-s - 1) {
    .bra-sku-slider {
		&.bra-sku-slider--v2 {
			.swiper-container.sku-slider {
				margin: 0 20px;
				overflow: visible;
				
				.swiper-slide {
					width: 100% !important;
				}
			}
		}
		.swiper-container.sku-slider {
			margin: 0 20px;
			overflow: visible;
			
			.swiper-slide {
				max-width: none;
				width: 100% !important;

				.bra-sideCard {
					&__textContent {
						padding: 30px 20px;
					}

					&__title {
						font-size: 18px;
						line-height: 22px;
					}
					&__description {
						font-size: 13px;
						line-height: 19px;
					}
				}
			}

			.swiper-footer {
				width: 100%;
				margin-top: 30px;
				justify-content: space-around;

				.swiper-scrollbar {
					width: 100%;
				}

				.swiper-button-next,
				.swiper-button-prev {
					display: none;
				}
			}

		}
	}
}
