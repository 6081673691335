.bra-category-shelf {
  &__title {
    @include fontBold;
    font-size: 24px;
    line-height: 1.11;
    color: $brand-01;
    margin: 30px auto;

    @media screen and (min-width: $braun-s) {
      font-size: 36px;
    }

    @media screen and (min-width: $braun-l) {
      font-size: 48px;
    }
  }

  &__grid {
    display: none;
    gap: 20px;
    grid-template-columns: 1fr;

    &:first-child {
      display: grid;
    }

    @media screen and (min-width: $braun-s) {
      grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: $braun-m) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__title,
  &__grid,
  &__toggle-wrapper {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px;

    @media screen and (min-width: $braun-l) {
      padding: 0 30px;
    }
  }

  &__toggle-wrapper {
    display: flex;
    align-items: center;
    @include gap(20px, 'r');
    margin-bottom: 30px;
    @media screen and (min-width: $braun-l) {
      margin-bottom: 50px;
    }
  }

  &__toggle {
    background-color: $ui-02;
    width: 70px;
    height: 40px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    position: relative;

    input {
      display: none;
    }

    label {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 25px;
      transition: all 0.3s ease-in-out;
      display: flex;
      align-items: center;
      padding: 0 5px;
      cursor: pointer;

      span {
        display: inline-block;
        width: 30px;
        height: 30px;
        background-color: $brand-03;
        border-radius: 100%;
        transition: all 0.3s ease-in-out;
      }
    }

    input:checked + label {
      background-color: $brand-01;
    }

    input:checked + label > span {
      transform: translateX(100%);
    }
  }

  &__toggle-label {
    cursor: pointer;
    @include font;
    font-size: 15px;

    @media screen and (min-width: $braun-xl) {
      font-size: 18px;
    }
  }

  .bra-question-button {
    @media screen and (min-width: $braun-s) {
      justify-content: flex-start;
      margin: 55px 0 0 20px;

      .bra-paragraph {
        width: auto;
        margin-right: 20px;
      }
    }

    @media screen and (min-width: $braun-m) { 
      width: 100%;
      max-width: 1560px;
      padding: 0 30px;
      margin: 55px auto 0 auto;
    }
  }
}
