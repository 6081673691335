.bra-filterTag {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    width: auto;
    height: 30px;
    border: 1px solid $ui-09;
    border-radius: 5px;
    padding: 0 10px 0 8px;
    box-sizing: border-box;

    &__remove {
        display: inline-flex;
        align-items: center;
        line-height: 1;
        width: 13px;
        height: 13px;
        border: none;
        background: none;
        background-color: transparent;
        margin: 0 10px 0 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }
}