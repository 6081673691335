.bra-article-stage {
	color: $brand-01;

	font-size: 15px;
	line-height: normal;

	&__wrapper {
		font-size: 15px;
		line-height: normal;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 30px;

		position: relative;

		max-width: 1440px;
		margin: 0 auto;

		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		grid-template-areas: "content visual" "actions visual";
	}

	&__content {
		font-size: 15px;
		line-height: normal;

		grid-area: content;
		flex-direction: column;

		.bra-subtitle {
			@include font;
			color: $ui-09;
			font-size: 18px;
			line-height: 22px;
			margin-bottom: 20px;
		}

		.bra-subtitle,
		.bra-title {
			max-width: calc(100% - 30px)
		}
	}

	&__visual {
		font-size: 15px;
		line-height: normal;

		grid-area: visual;
		border-radius: 5px;
		overflow: hidden;

		.bra-image {
			height: 100%;
			padding-bottom: 0;
		}
	}

	&__heading {
		font-size: 15px;
		line-height: normal;
	}
	&__actions {
		font-size: 15px;
		line-height: normal;

		grid-area: actions;
		width: calc( 100% - 30px );

		margin-top: auto;
		padding: 30px 0;
		border-bottom: 1px solid $ui-08;

		a {
			font-size: 15px;
		}
	}

}