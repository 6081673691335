@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-crosslink-box {
        width: calc(100% - 60px);
        padding: 60px 82px;

		.bra-title {
			font-size: 36px;
			line-height: 40px;
		}
		.bra-paragraph {
			font-size: 15px;
			line-height: 22px;
		}

		&__CTA {
			margin-top: 30px;

			.bra-btn {
				font-size: 15px;
				padding: 0 30px;
			}
		}
	}
}
