.bra-cell {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1.27;
    border-top: 1px solid $ui-08;
    box-sizing: border-box;

    &--bold {
        @include fontBold;
    }
}