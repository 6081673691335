@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-horizontalCard {
        margin-bottom: 30px;
        
        &__image {
			margin: 0 30px 0 0;
		}
		&__wrap {
			width: calc(100% - 80px - 30px);
		}
        &__title {
            font-size : 17px;
            line-height : 24px;
        }
        .bra-paragraph {
            font-size : 15px;
            line-height : 22px;
        }
    }
}