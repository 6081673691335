.bra-search {
	&__wrapper {
		font-size: 15px;
		line-height: normal;

		.bra-title {
			@include font;
			text-align: center;
			margin-bottom: 22px
		}
	}

	&__form {
		display: flex;
		border-bottom: 1px solid $ui-01;
		padding-bottom:40px;
		margin-bottom: 22px;

		font-size: 15px;
		line-height: normal;

		.bra-input__wrapper {
			display: flex;
			flex-basis: 0;
			flex-grow: 1;
			margin-right: 1em;
		}
		.bra-btn {
			display: flex;
			@include fontBold;
		}
	}
}