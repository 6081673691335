
.bra-prodGallery {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    // background: lightgoldenrodyellow;

    // & * {
    //     background: rgba(0,0,0,.1);

    //     &:nth-child(odd) {
    //         background: rgba(0,0,0,.15);
    //     }
    // }
    &__thumbnails {
        display: flex;
        flex-wrap: wrap;
        width: 80px;
        height: 495px;
        margin: 0 20px 0 0;

        & .bra-prodGallery__swiperContainer {
            width: 100%;
            height: calc(100% - (26px * 2));
            padding: 26px 0;
            position: relative;
            z-index: 1;

            & > .swiper-wrapper {
                width: 100%;
                height: 100%;
                z-index: 3;

                & .bra-prodGallery__slide {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 6px;

                    &Image {
                        width: 80px;
                        height: 80px;

                        & > img {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    &Iframe {
                        width: 80px;
                        height: 80px;
                        position: relative;
                        z-index: 1;

                        & > iframe {
                            width: 100%;
                            height: 100%;
                            pointer-events: none;
                        }
                        &Overlay {
                            display: flex;
                            flex-wrap: wrap;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 100%;
                            background-color: rgba($brand-01, .25);
                            transition: opacity 0.22s linear;
                            pointer-events: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 3;

                            & .bra-playBtn {
                                background-color: transparent;
                            }

                            .bra-icn__play {
                                background: url("#{$pathAssets}/images/delonghiIcons/play.svg");
                                z-index: 1;
                            }
                        }
                        &Poster {
                            display: block;
                            width: 100%;
                            height: 100%;
                            transition: opacity 0.22s linear;
                            object-fit: cover;
                            pointer-events: none;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 2;

                            & > img {
                                display: block;
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }
                        }
                        &--hidePoster {
                            & .bra-prodGallery__slideIframe > iframe {
                                pointer-events: initial;
                            }
                            & .bra-prodGallery__slideIframeOverlay,
                            & .bra-prodGallery__slideIframePoster {
                                opacity: 0;
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            & > .swiper-footer {
                display: none;
            }
            & > [class^="swiper-button"] {
                display: flex;
                justify-content: center;
                width: 80px;
                height: 26px;
                background-color: $brand-03;
                background-image: none;
                transform-origin: center;
                margin: 0;
                position: absolute;
                top: auto !important;
                bottom: auto !important;
                left: 0 !important;
                right: auto !important;
                z-index: 4;

                & > .bra-icn {
                    transform: rotate(90deg);
                }
            }
            & > .swiper-button-prev {
                align-items: flex-start;
                top: 0 !important;
                border: none;
                background: none;
            }
            & > .swiper-button-next {
                align-items: flex-end;
                bottom: 0 !important;
                border: none;
                background: none;
            }
        }
    }
    &__preview {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(100% - 80px - 20px);
        height: auto;

        &Image {
            display: none;
            width: 100%;
            height: 495px;

            & > img {
                display: block;
                width: 100%;
                height: 495px;
                object-fit: contain;
            }
        }
        &Iframe {
            display: none;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
            position: relative;
            z-index: 1;

            & > iframe {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
        &Image,
        &Iframe {
            &.bra-prodGallery__previewVisible {
                display: flex;
                z-index: 100;
            }
        }
    }
}