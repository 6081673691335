@media only screen and (max-width: $braun-m - 1) {
	.bra-input {
		&--countrySelect {
			.bra-select__wrapper {
				height: auto;
				select {
					width: auto;
					height: auto;
					font-size: 13px;
				}

				&::before {
					width: 30px;
					height: 30px;
					line-height: 30px;
				}
			}
		}
	}
}