.bra-header {
  &.bra-header--shrink {
    &.hasStripe {
      padding-top: 30px;
    }
  }

  &.isCLSession,
  &.hasStripe {
    padding-top: 70px;

    .bra-stripe {
      display: flex;
      justify-content: center;

      &__container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 30px;
        width: 100%;
        position: fixed;
        left: 0;
        top: 0;
      }

      &__link {
        display: flex;
        align-items: center;
        justify-content: center;

        a,
        span {
          font-size: 12px;
          line-height: 1.2em;
          letter-spacing: 0;
          text-decoration: none;
        }

        a:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &.isCLSession {
    .bra-stripe {
      &__container {
        background-color: $brand-01;
      }

      &__link {
        height: 15px;
        padding: 0 25px;
        border-right: 1px solid rgba($brand-03, 0.2);

        &:last-child {
          border-right: none;
        }

        a {
          color: $brand-03;
        }
      }
    }
  }

  &.hasStripe {
    .bra-stripe {
      &__container {
        background-color: $brand-03;
      }

      &__link {
        a {
          color: $brand-01;
        }
      }

      .link-icon {
        height: 20px;
        margin-right: 10px;
        width: auto;
      }

      .swiper-button-prev,
      .swiper-button-next {
        background-image: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .bra-icn {
          font-size: 10px;
        }
      }

      @media only screen and (min-width: $braun-m + 1) {
        &__link {
          padding: 0 25px;
        }

        .swiper-slide {
          width: auto;
        }

        .swiper-button {
          &-prev,
          &-next {
            display: none;
          }
        }
      }
    }

    &.bra-header--shrink {
      .bra-stripe__container {
        display: none;
      }
    }
  }
}

.braun-header-stripe {
  background-color: $brand-01;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;

  @media only screen and (min-width: $braun-m + 1) {
    position: relative;
    z-index: 12;
  }

  &.bra-stripe {
    &__container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 46px;
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;

      .link-icon {
        height: 20px;
        margin-right: 10px;
        width: auto;
      }

      .bra-stripe__link {
        width: auto;
        justify-content: center;
        height: auto;
        align-items: center;
        display: flex;
      }

      @media only screen and (min-width: $braun-m + 1) {
        z-index: 12;
        .bra-stripe__link {
          padding: 0 25px;
        }

        .swiper-slide {
          width: auto;
        }

        .swiper-button {
          &-prev,
          &-next {
            display: none;
          }
        }
      }
    }
  }

  a {
    color: $brand-03;
    font-size: 12px;
    line-height: 1.2em;
    letter-spacing: 0;
    text-decoration: none;
    border-right: 1px solid rgba($brand-03, 0.2);
    padding: 0 10px;

    &:last-child {
      border-right: none;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &.hasStripe {
    background-color: $brand-03;

    a,
    span {
      color: $brand-01;
      border: none;
    }
  }
}
