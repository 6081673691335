@media only screen and (max-width: $braun-s - 1) {
  .bra-header {
    padding: 39px 20px !important;

    & > * {
      vertical-align: middle;
    }
    .bra-logo__wrapper {
      font-size: 24px;
    }
    .bra-btnIcon {
      font-size: 20px;
    }
    .bra-quickLinks__wrapper {
      display: none;
    }

    &--shrink {
      padding: 20px !important;
    }
    &--open {
      padding: 39px 20px !important;
    }
  }
}
