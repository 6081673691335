.bra-badge {
  @include fontBold;
  display: inline-block;
  width: auto;
  height: 20px;
  line-height: 1.6;
  font-size: 13px;
  color: $brand-03;
  border-radius: 5px;
  background-color: $support-02;
  padding: 0 6px;

  &--out-of-stock {
    background-color: var(--brand-02);
  }
}
