.bra-selectionBar {
	font-size: 15px;
	line-height: normal;

	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background: white;
	box-shadow: 0px 0px 10px 0px rgba(black, 0.1);

	transform: translateY(100%);
	transition: transform 0.4s ease-in-out;

	&__wrapper {
		display: flex;
		justify-content: flex-start;
	}

	&.visible {
		transform: translateY(0%);
		transition: transform 0.4s ease-in-out;
	}

	&__action {
		width: calc(25% - 40px);
		padding: 22px 20px;

		.bra-paragraph {
			margin-bottom: 22px;
		}
	}
	&__carousel {
		width: calc(75% - 30px);
	}
}

.bra-selectionBar__carousel {
	position: relative;

	.comparison-slider {
		height: 100%;

		.swiper-slide {
			box-shadow: inset 1px 0 0 0 $ui-08;

			.compared_product {
				position: relative;
				padding: 20px 20px 60px 20px;

				i {
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 0;
					width: 3em;
					height: 3em;

					&::before {
						width: 3em;
						height: 3em;
						line-height: 3em;
						font-size: 1em;
					}

					&:hover {
						color: $support-02;
					}
				}
			}
		}
		.swiper-slide:last-child {
			box-shadow: inset -1px 0 0 0 $ui-08,
						inset 1px 0 0 0 $ui-08
		}
	}

	.swiper-footer {
		.swiper-button-next,
		.swiper-button-prev {
			background: black;
			width: 20px;
			height: 40px;
			i {
				color: white;
				height: 40px;
				line-height: 40px;
				width: 20px;
				text-align: center;
				display: block;
			}
		}
		.swiper-button-next {
			right: -20px;
		}
		.swiper-button-prev {
			left: -20px;
		}
	}
}