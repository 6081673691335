@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-prodTile {
        & .bra-prodTile__head {
            margin-bottom: 10px;

            & .bra-btnIcon--wishlist {
                width: 20px;
                height: 20px;

                & .bra-icn {
                    &::before {
                        font-size: 20px;
                    }
                }
            }
        }
        &--compare {
            & .bra-prodTile__product {
                padding: 10px 15px 20px 15px;
            }
            & .bra-prodTile__head {
                margin-bottom: 10px;
            }
            & .bra-prodTile__imgWrap {
                height: 159px;
            }
            & .bra-prodTile__foot {
                height: auto;

                & .bra-prodTile__rating {
                    margin: 0 0 20px 0;
                }
                & .bra-input--checkbox {
                    margin: 0 auto;
                }
            }
            &.bra-prodTile--collapsed {
                & .bra-prodTile__product {
                    padding: 20px 15px;
                }
            }
        }
        &__badges {
            .bra-badge {
                height: unset;
                margin-bottom: 5px;
            }
        }
    }
}
