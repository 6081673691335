@media only screen and (max-width: $braun-s - 1) {
    .bra-checkbox {
        &__wrapper {    
            .bra-checkbox {
                margin: 0;
    
                min-width: initial;
                max-width: initial;
                width: 100%;
            }
    
            .bra-checkbox + .bra-checkbox {
                margin-top: 10px;
            }
        }

        &__wrap {
            .bra-input__wrapper {
                flex: 1 1 100%;
                width: 100%;
                min-width: 100%
            }
        }

        &__submit-error {
            align-items: flex-start;
            padding: 11px 15px 13px;
    
            .bra-icn {
                margin-top: 5px;
            }
        }

        &--interactive {    
            &.bra-checkbox--tooltip {
                .bra-input__label {
                    margin-right: 0;
                    min-width: calc(100% - 30px);
                }
            }
        } 

        &--submit {
            .bra-checkbox__wrap {
                width: 100%;
                flex-wrap: wrap;
            }
            
            .bra-checkbox__tooltipIconWrap {
                position: relative;
                width: 100%;
                margin-top: 8px;
                margin-left: 25px;
            }
        }
    }
}