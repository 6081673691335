@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-reviews {
        &__recap {
            padding: 30px;
        }
        &__item-wrap {
            padding-right: 30px;
        }
    }
}
