.bra-input-animated {

    &__wrapper {
        display: inline-block;
        width: 100%;
        text-align: left;
        border: 1px solid $ui-09;
        border-radius: 5px;
        transition: all .4s cubic-bezier(.25, .8, .25, 1);
        position: relative;
    }

    &--active {
        & .bra-input-animated__lbl {
            width: auto;
            line-height: 2.2;
            // transform: translateY(calc(50% - 35px)) scale(.80);
            transform: translateY(-31px) scale(0.8);
            top: 50%;
        }
    }
    &--disabled {
        display: inline-flex;
        flex-wrap: wrap;
        border-color: transparent;

        & .bra-input-animated__lbl {
            display: inline-flex;
            order: -1;
            align-items: center;
            width: auto;
            max-width: 50%;
            transform: none;
            position: relative;
            top: auto;
            left: auto;
        }
        & .bra-input-animated__input {
            width: auto;
            max-width: 50%;
            height: auto;
            padding: 0;
            margin: 0;
        }
    }
    &__input {
        @include fontBold;
        outline: 0;
        width: 100%;
        height: 60px;
        line-height: 16px;
        font-size: 15px;
        border: none;
        background-color: transparent;
        padding: 0 15px;
        box-sizing: border-box;

        &::placeholder {
            color: $ui-02;
            opacity: 0;
        }
        &[disabled] {
            opacity: .8;
        }
        &:-webkit-autofill,
        &:-moz-autofill {
            &~.bra-input-animated__lbl {
                width: auto;
                transform: translateY(calc(50% - 35px)) scale(.75);
            }
        }
    }

    &__lbl {
        font: inherit;
        display: inline-block;
        width: 100%;
        line-height: 1.47;
        font-size: 15px;
        color: $ui-09;
        white-space: nowrap;
        text-overflow: ellipsis;
        transform-origin: 0 0;
        transition: top .4s cubic-bezier(.25, .8, .25, 1), transform .4s cubic-bezier(.25, .8, .25, 1), color .4s cubic-bezier(.25, .8, .25, 1), width .4s cubic-bezier(.25, .8, .25, 1);
        padding: 0;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        top: calc(50% - 8px);
        left: 15px;
    }
    .bra-btnIcon {
        background: none;
        // background: lightblue;
        background-color: transparent;
        position: absolute;
        top: -1px;
        right: -1px;
    }
    &__button {
        position: absolute;
        top: calc(50% - 8px);
        right: 15px;
    }

    &--icon {
        & .bra-input-animated__input {
            padding-right: 40px;
        }
        .bra-icn {
            pointer-events: none;
        }
    }

    &--writing {
        .bra-input-animated__autocompleteContainer {
            display: block;

            height: auto;
            max-height: 240px;
            visibility: visible;
            overflow-x: hidden;
            overflow-y: auto;
        }
    }
    &--icon {
        .bra-icn {
            width: 15px;
            height: 15px;
            font-size: 15px;
            color: $ui-01;
            cursor: pointer;
            position: absolute;
            top: calc(50% - 8px);
            right: 16px;
        }
    }
    &--validate {
        .bra-icn {
            color: $support-02;
            opacity: 0;
            transform: scale(.5);
        }
        &.bra-input-animated--validated {
            .bra-icn {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &__error-message {
        line-height: 2.2;
        font-size: 10px;
        color: #f18f32;
        padding-left: 15px;
    }

    &--invalid {

        .bra-input-animated__wrapper {
            border: 1px solid #f18f32;
        }
        
        .bra-input-animated__lbl {
            color: #f18f32;
        }

        .bra-icn {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px;
            height: 20px;
            font-size: 18px;
            color: $brand-03;
            border-radius: 50%;
            background: #f18f32;
            opacity: 0;
            transform: scale(.5);
        }
        &.bra-input-animated--invalid {
            .bra-icn {
                opacity: 1;
                transform: scale(1);
            }
        }
    }
}