@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-prodTilesGrid {
        & .bra-prodTile__shadow {
            display: none;
        }
        & .bra-prodTile__details {
            display: none;
        }
        &--detail {
            & .bra-prodTile__details {
                display: flex;
                opacity: 1;
                pointer-events: initial;
                position: relative;
                top: initial;
                left: initial;
            }
        }
        &--four {
            & .bra-prodTile {
                width: calc(25% - 15px);
                height: auto;

                &:nth-of-type(3n+3) {
                    margin: 0 20px 20px 0;
                }
                &:nth-of-type(4n+4) {
                    margin: 0 0 20px 0;
                }
            }
            &.bra-prodTilesGrid--detail {
                & .bra-prodTile {
                    width: calc(33.33% - 13.34px);
                    height: auto;
                    margin: 0 20px 20px 0;

                    &:nth-of-type(4n+4) {
                        margin: 0 20px 20px 0;
                    }
                    &:nth-of-type(3n+3) {
                        margin: 0 0 20px 0;
                    }
                    &__imgWrap {
                        height: 228px;
                    }
                    &__foot {
                        flex-direction: row;
                        height: 20px;

                        & .bra-prodTile__rating {
                            width: auto;
                            margin: 0;
                        }
                        & .bra-input--checkbox {
                            margin: 0 0 0 auto;
                        }
                    }
                }
            }
        }
    }
}
