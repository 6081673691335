body.mobileOpen {

	@media (hover: none) {
		// height: 20px;
		height: 100vh;
		overflow:hidden;
		// position:fixed;

		.page__wrapper,
		.bra-footer {
			visibility: hidden;
			pointer-events: none;
		}
	}
	height: 100vh;
	overflow: hidden;
	.page__wrapper {
		z-index:2;
	}
	.bra-footer {
		z-index:1;
	}
}

.bra-header {
	@media all and (-ms-high-contrast:none) {
		*::-ms-backdrop, &__logo,
		*::-ms-backdrop, &__logo .bra-logo__wrapper {
			width: 85px;
			height: 35px;
		}
	}
	line-height: normal;
	font-size: 15px;

	background: $brand-03;

	transform: translate3d(0,0,0);
	transition: all .3s ease-in-out;
	padding: 40px 30px 55px 30px;

	position:fixed;
	top: 0;
	left: 0;
	right: 0;

	z-index: 150;

	&::after {
		content: '';
		width: 150%;
		height: 100%;

		box-shadow: 0 0 0 0px rgba(0,0,0,0);
		transform: translateX(-50%);
		transition: box-shadow .3s ease-in-out;
		position: absolute;
		top: 0;
		left: 50%;
		z-index: -1;
    }

	&__wrapper {
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: space-between;
		margin: 0 auto;
	}

	&--shadow::after {
		box-shadow: 0 30px 10px -35px rgba(0, 0, 0, 0.9);
		transition: box-shadow .3s ease-in-out;
	}

	&__logo {
		display: flex;
		font-size: 35px;

		.bra-logo__wrapper {
			.bra-logo {
				.logo--fill {
					fill: $brand-01;
				}
			}
		}
	}

	.bra-quickLinks__wrapper {
		display: flex;
		transition: opacity .3s ease-in-out;

		.bra-link {
			line-height: 1;
			font-size: 15px;
			color: $brand-01;
			.bra-link__text {
				line-height: 1;
				padding: 0;
				margin: 0;
			}
		}
		.bra-link + .bra-link {
			margin-left: 20px;
		}
	}

	&__buttons-container {
		display: flex;

		.bra-btnIcon {
			font-size: 20px;

			&__wrap {
				.bra-icn {
					color: $brand-01;
				}

				.bra-icn__chat {
					color: $support-02;
				}
			}
		}

		.bra-btnIcon + .bra-btnIcon {
			margin-left: 1em;
		}
	}

	&--shrink {
		background: $brand-01;
		transition: all .3s ease-in-out;

		padding: 20px 30px 30px 30px;

		.bra-quickLinks__wrapper {
			opacity: 0;
		}

		.bra-logo .logo--fill {
			transition: fill .3s linear;
		}
		.bra-icn {
			transition: color .3s linear,background-color .3s linear;
		}

		.bra-header__logo {
			.bra-logo__wrapper {
				.bra-logo {
					.logo--fill {
						fill: $brand-03;
					}
				}
			}
		}
		.bra-quickLinks__wrapper {
			.bra-link {
				color: $brand-03;
			}
		}
		.bra-header__buttons-container {
			.bra-btnIcon {
				&__wrap {
					b {
						background-color: $brand-03;
						transition: color .3s linear,background-color .3s linear;
					}
					.bra-icn {
						color: $brand-03;
					}

					.bra-icn__chat {
						color: $brand-03;
					}
				}
			}
		}
	}
	&--inverted {
		background: $brand-01;
		transition: all .3s ease-in-out;

		.bra-logo .logo--fill {
			transition: fill .3s linear;
		}
		.bra-icn {
			transition: color .3s linear,background-color .3s linear;
		}

		.bra-header__logo {
			.bra-logo__wrapper {
				.bra-logo {
					.logo--fill {
						fill: $brand-03;
					}
				}
			}
		}
		.bra-quickLinks__wrapper {
			.bra-link {
				color: $brand-03;
			}
		}
		.bra-header__buttons-container {
			.bra-btnIcon {
				&__wrap {
					b {
						background-color: $brand-03;
						transition: color .3s linear,background-color .3s linear;
					}
					.bra-icn {
						color: $brand-03;
					}

					.bra-icn__chat {
						color: $brand-03;
					}
				}
			}
		}
	}
	&--transparent {
		background: transparent;
	}


	&--open {
		--bgColor: #{$brand-03};
        --txtColor: #{$brand-01};

		background: transparent;
		transition: all .3s ease-in-out;
		padding: 40px 30px 55px 30px !important;
        z-index: 202;

		* {
			--bgColor: #{$brand-03};
			--txtColor: #{$brand-01};
			//transition: none;
		}

		.bra-logo .logo--fill {
			transition: fill .3s linear;
		}
		.bra-icn {
			transition: color .3s linear,background-color .3s linear;
		}

		.bra-quickLinks__wrapper {
			opacity: 0;
		}

		&::after {
			box-shadow: 0 0 0 0px rgba(0,0,0,0);
			transition: box-shadow .3s ease-in-out;
		}

		.bra-header__logo {
			.bra-logo__wrapper {
				.bra-logo {
					.logo--fill {
						fill: $brand-01;
					}
				}
			}
		}
		.bra-quickLinks__wrapper {
			.bra-link {
				color: $brand-01;
			}
		}
		.bra-header__buttons-container {
			.bra-btnIcon {
				&__wrap {
					b {
						background-color: $brand-01;
						transition: color .3s linear,background-color .3s linear;
					}
					.bra-icn {
						color: $brand-01;
					}

					.bra-icn__chat {
						color: $brand-01;
					}
				}
			}
		}
	}
}