@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-sideCard {
        &__textContent {
            padding-left: 40px !important;
        }
        &__subTitle {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 10px;
        }
        &__title {
            font-size: 30px;
            line-height: 34px;
            margin-bottom: 10px;
        }
        &__description {
            font-size: 13px;
            line-height: 19px;
        }
    }
}