.bra-prodTilesGrid {
    @include flex(wrap);
    width: 100%;

    &--four {
        & .bra-prodTile {
            width: calc(25% - 15px);
            margin: 0 20px 20px 0;

            &:nth-of-type(4n+4) {
                margin: 0 0 20px 0;
            }
        }
    }
}