.bra-padding {
	display: block;
	height: 120px;
	width: 100%;

	font-size: 15px;
	line-height: normal;

	&--large {
		height: 120px;
	}
	&--medium {
		height: 90px;
	}
	&--small {
		height: 60px;
	}
	&--extra-small {
		height: 30px;
	}
}