@media only screen and (max-width: $braun-s - 1) {
    .bra-recipe-box {
        &__img {
            .bra-icn {
                width: 34px;
                height: 34px;
                &:before {
                    font-size: 20px;
                }
            }
        }
        &__title {
            margin: 0 0 8px 0;
        }

        &--big-card {
            .bra-recipe-box__img {
                border-radius: 5px;
            }
            .bra-recipe-box__title {
                font-size: 13px;
                line-height: 16px;
                padding-bottom: 3px;
            }
            .bra-recipe-box__details {
                display: block;
                font-size: 13px;
                line-height: 24px;

                .bra-recipe-box__detail + .bra-recipe-box__detail {
                    margin-top: 5px;
                    margin-left: 0;
                }
            }
        }
    }
}
