.bra-recipe-tiles {
	font-size: 15px;
	line-height: normal;
	width: 100%;
    max-width: calc(1440px + 60px);
    margin: 0 30px;
	// margin: 0;

	

	// @media only screen and (max-width:1500px) {
	// 	padding: 0 30px;
	// 	box-sizing: border-box;

	// 	@media (-ms-high-contrast:none) {
	// 		padding: 0
	// 	}
    // }
    
	.swiper-container.recipe-gallery {

		.swiper-slide {
			max-width: 467px;
            width: calc( calc( 100vw - 40px ) / 3 - 20px );
            
			@media (-ms-high-contrast:none) {
				width: calc( 33% - 10px )
			}

			&:last-of-type {
				margin-right: 0 !important
			}

			.bra-card {
				margin-bottom: 0;
			}
		}
	}
}