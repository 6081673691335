.bra-awards {
    width: 100%;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: calc(50% - 10px);
        border: 1px solid $ui-08;
        border-radius: 5px;
        padding: 30px;
        margin: 0 20px 20px 0;
        box-sizing: border-box;

        &:nth-child(2n+2) {
            margin: 0 0 20px 0;
        }
        &:last-child {
            margin-bottom: 0;
        }
        &:nth-last-child(2) {
            margin-bottom: 0;

            &:nth-child(even) {
                margin-bottom: 20px;
            }
        }
        & > .bra-image {
            align-items: center;
            justify-content: center;
            width: 144px;
            height: 144px;
            background: none;
            background-color: transparent;


            & .bra-image__image {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
                object-fit: contain;
                top: initial;
                left: initial;
            }
        }
    }
    &__text {
        display: block;
        width: calc(100% - 144px - 20px);
    }
    &__title {
        @include fontBold;
        line-height: 1.33;
        font-size: 18px;
        padding-bottom: 5px;
        margin-top: 10px;
    }
    &__description {
        line-height: 1.47;
        font-size: 15px;
    }
    & .bra-image {
        // flex: 0 1 144px;
        margin-right: 20px;
    }
}