.bra-item {
	font-size: 15px;
	line-height: normal;

	@include flex(wrap);
	width: 100%;
	height: auto;

	&__image {
		display: block;
		width: 100%;
		height: 240px;
		background-color: $ui-03;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
	}
	&__info {
		@include flex(wrap);
		width: 100%;
		padding: 40px;
		box-sizing: border-box;
	}
	&__title {
		display: block;
		width: 100%;
		line-height: 32px;
		font-size: 26px;
		font-weight: 400;
		margin: 0 0 16px 0;
	}
	&__text {
		display: block;
		width: 100%;
		line-height: 20px;
		font-size: 15px;
		font-weight: 400;
	}
	&--bold {
		& .bra-item__title {
			line-height: 20px;
			font-size: 15px;
			font-weight: bold;
		}
	}
	&--center {
		& .bra-item__title {
			text-align: center;
		}
		& .bra-item__text {
			text-align: center;
		}
	}
}