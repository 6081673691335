@media only screen and (max-width: $braun-s - 1) {
    .bra-category-slider {
		.swiper-container.category-slider {
			.swiper-slide {
				max-width: none;
				width: calc( calc( 100vw - 40px ) / 1 );
			}
		}
	}
}
