@media only screen and (max-width:$braun-s - 1) {
    .bra-question-button {
        flex-direction: column;
        align-items: flex-start;
        margin-top: 35px;
        width: calc(100% - 40px);
        font-size: 15px;
        line-height: 19;

        .bra-btn {
            margin-top: 20px;
        }
    }
}