@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-multi-accordion{
        &--terms-and-conditions {
            .bra-multi-accordion {
                &__item {
                    .bra-multi-accordion__expander.active {
                        .bra-multi-accordion__title {
                            padding-left: 60px;
                            padding-right: 60px;
                        }
                    }
                }

                &__content {
                    padding-left: 60px;
                    padding-right: 60px;
                }
            }
        }
        &--promo {
            .bra-multi-accordion {
                &__expander {
                  .bra-multi-accordion__title {
                    text-align: center;
                    }
                }
                &__item {
                    .bra-multi-accordion__expander.active {
                        + .bra-multi-accordion__content {
                            max-height: 184px;
                            overflow: visible;
                            opacity: 1;
                        }
                    }
                }

                &__image {
                    width: 226px;
                    min-width: 226px;
                    height: 226px;
                }

            }
        }
    }
}
