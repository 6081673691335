@media only screen and (max-width: $braun-s - 1) {
	.page__wrapper {
		.page_header {
			padding: 22px 0;
		}
		.page__body {
			.page__aside {
				display: none
			}
			.page__content {
				width: 100%;
			}
		}
		.page__closing {
			--bgColor: #{$ui-03};

			background: var(--bgColor);
			margin-top: 33px;
		}
	}
}