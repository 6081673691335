// portrait
@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-chemicalmixes {
        &__flex-grid_item {
            padding: 0 60px;
        }
    }
}

// landscape
@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {}