@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {}


@media only screen and (max-width: $braun-m - 1) {
    .bra-input-animated {
        &--active {
            & .bra-input-animated__lbl {
                transform: translateY(calc(50% - 31px)) scale(.66);
            }
        }
        &__input {
            height: 50px;
            font-size: 13px;
        }
    }
}