#smarteditoverlay {
  z-index: 1001!important;
}

[data-smartedit-component-type="DLGPaddingComponent"] {
  width: 100%!important;
  height: auto!important;
}

[data-smartedit-component-type="DLGSideCardComponent"] {
  margin: 0 30px!important;
  .bra-sideCard {
    margin: 0!important;
  }
}

[data-smartedit-component-type="DLGImageSliderComponent"] {
  .bra-image-slider {
    margin: 0 auto!important;
  }
}

[data-smartedit-component-type="DLGGalleryComponent"] {
  .yCmsComponent {
    height: 100%;
    width: 100%;
  }
}

[data-smartedit-component-type="DLGImageBlockComponent"] {
  .yCmsComponent {
    height: 100%;
    width: 100%;
  }

  .bra-imageBlock--spaced .yCmsComponent + .yCmsComponent {
    margin-left: 20px
  }

  .bra-imageBlock--slim .yCmsComponent {
    height: 200px;
    max-height: 200px;
  }
}

[data-smartedit-component-type="DLGCategorySliderComponent"] {
  .yCmsComponent {
    height: 100%;
    width: 100%;
  }
}
