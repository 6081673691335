@media only screen and (max-width: $braun-s - 1) {
	.bra-footer {
        .bra-footer-wrapper {
            padding: 30px 0;
            margin: 0 20px;
            &--half-padding {
                padding: 30px 0;
            }

            &.bra-footer__header {
                padding: 60px 0;
            }
        }

        &__header {
            .bra-logo__wrapper {
                font-size: 90px;
            }
        }

        &__sitemap {
            &.bra-footer-wrapper {
                padding-bottom: 0;
            }

            .bra-list-link {
                &__list-wrapper {
                    width: calc( 50% - 20px );
                    margin-bottom: 40px;
                }
            }
        }
        &__social {
            #social_wrapper {
                width: auto;
            }
            #country_wrapper {
                .bra-input--countrySelect {
                    width: auto;
                    display: flex;

                    .bra-select__wrapper {
                        max-width: 100%;
                        width: auto;

                        select {
                            white-space: normal;
                            max-width: calc(100% - 30px);
                            padding-right: 0;
                        }
                    }
                }
            }
            #social_wrapper {
                justify-content: flex-end;

                .social_item + .social_item {
                    margin-left: 5%;
                }
            }
        }
        &__legal {
            .list {
                &__list-wrapper {
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
}