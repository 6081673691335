@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-recipe-tiles {
        margin: 0;
		padding: 0;
		.swiper-container.recipe-gallery {
			.swiper-slide {
				max-width: 344px;
				width: calc( calc( 100% - 40px ) / 2 - 20px) !important;
			}
		}
	}
}
