@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-promo-tiles {
        padding: 0 30px;

        .bra-card,
        .bra-card .bra-card__wrapper,
        .bra-card .bra-card__wrapper,
        .bra-card__visual {
            height: 45vw;
            height: calc(calc(100vw / 2) - 40px);

        }
    }
}