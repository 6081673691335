@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-navigation__visual-container {
        display: none
    }

    .bra-navigation {
        &__list {
            &-item {
                label {
                    font-size: 30px
                }
            }
            &-container {
                width: 100%;
            }
            li.active+.bra-navigation__categoryWrapper {
                justify-content: space-between;
            }
        }

        &__footer {
            // width: calc(100% - 40px);
            // width: 100%;
            // max-width: none;
        }
    }

    .bra-navigation__list {
        li.active+.bra-navigation__categoryWrapper {
            justify-content: space-between;
        }

        .bra-navigation__categoryWrapper {
            .bra-navigation__category {
                &-item {
                    width: calc(50% - 10px);

                    &:nth-child(3n+2) {
                        margin: 0;
                    }
                }
            }
        }
    }
}