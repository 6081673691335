.bra-introText {
	display: flex;
	width: 85%;
	margin: 0 auto;
	justify-content: space-between;
	align-items: flex-end;

	margin-bottom: 60px;
	max-width: 1200px;

	font-size: 15px;
	line-height: normal;

	.bra-title {
		font-size: 36px;
		line-height: 40px;

		margin-bottom: auto
	}
	.bra-paragraph {
		font-size: 15px;
		line-height: 22px;
	}

	.bra-title + .bra-paragraph {
		margin-left: 20px
	}
}



/*M*/
@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-introText {
		.bra-title {
			font-size: 36px;
			line-height: 40px;
		}
		.bra-paragraph {
			font-size: 15px;
			line-height: 22px;
		}
	}
}
/*S*/
@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-introText {
		.bra-title {
			font-size: 30px;
			line-height: 34px;
		}
		.bra-paragraph {
			font-size: 13px;
			line-height: 19px;
		}
	}
}
/*XS*/
@media only screen and (max-width: $braun-s - 1) {
	.bra-introText {
		.bra-title {
			font-size: 24px;
			line-height: 27px;
		}
		.bra-paragraph {
			font-size: 13px;
			line-height: 19px;
		}
	}
}