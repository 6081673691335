@media only screen and (max-width: $braun-s - 1) {
	
	.bra-horizontalCard {
        padding: 0;
        margin-bottom: 20px;
        width: 100%;
        
		&__title {
			font-size : 15px;
			line-height : 22px;
		}

		.bra-paragraph {
			font-size : 13px;
			line-height : 19px;
		}

		&__image {
			margin: 0 15px 0 0;
		}

		&__wrap {
			width: calc(100% - 80px - 15px);
		}

		&__wrapper {
			margin: 0 20px;
			padding: 0;
	
			.bra-horizontalCard {
				width: 100%;
				margin-bottom: 20px;
			}
		}
		
		&--small {
			margin: 0 20px;

			.bra-horizontalCard {
				padding: 0;

				&__title {
					font-size : 13px;
					line-height : 19px;
				}
			}
		}

	}
}