.bra-subtitle {
    display: block;
    width: 100%;

    @include fontBold;
    color: $brand-01;

    display: block;
    width: 100%;

    font-size: 15px;
    line-height: normal;

    font-size: 26px;
    line-height: 32px;

    &--underlined {
        border-bottom: 1px solid $ui-01;
        padding: 32px 0 16px 0;
    }
    &--center {
        text-align: center;
    }
    &--brand-03 {
        color: $brand-03;
    }
    &--support-02 {
        color: $support-02;
    }
    &--support-03 {
        color: $support-03;
    }
}

/*M*/
@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-subtitle {
        font-size: 24px;
        line-height: 30px;
    }
}
/*S*/
@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-subtitle {
        font-size: 22px;
        line-height: 28px;
    }
}
/*XS*/
@media only screen and (max-width: $braun-s - 1) {
    .bra-subtitle {
        font-size: 18px;
        line-height: 26px;
    }
}