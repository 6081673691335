@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-compareTray {
        top: calc(100% - 252px);

        & .bra-compareTray__head {
            order: 1;
        }
        & .bra-compareTray__actions {
            order: 3;
            width: 100%;
            padding: 0 30px;
            margin-bottom: 20px;

            & .bra-btn {
                width: calc(33.33% - 13.34px);

                &--compare {
                    margin: 0 auto 0 0;
                }
            }
        }
        & .bra-compareTray__products {
            order: 2;
            width: 100%;
            padding: 0 30px;
            margin-bottom: 20px;
        }
        &--collapsed {
            top: calc(100% - 58px);
        }
        &--expanded {
            top: 0;

            & .bra-compareTray__head {
                height: 90px;

                & .bra-compareTray__title {
                    height: 90px;
                    line-height: 90px;
                }
            }
            & .bra-compareTray__actions {
                display: none;
            }
            & .bra-compareTray__products {
                width: 100%;
                padding: 0 30px 20px 30px;
                margin: 0;
            }
        }
    }
}
