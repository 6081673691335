.bra-category-slider {
	font-size: 15px;
	line-height: normal;

	&.bra-category-slider--subCategorySlider .swiper-slide {
		@include hover-supported() {
            &:hover {
                .bra-card__visual {
                    .bra-image {
                        transform: scale3d(1.09,1.09,1.09) translate3d(0, 0, 0);
                        transition: transform .35s ease-in-out;
                    }
                }
            }
        }
	}

	.swiper-container.category-slider {
		.swiper-slide {
			max-width: 467px;
			width: calc( calc( 100vw - 40px ) / 3 - 20px );

			@media only screen and (min-width:1500px) {
				&:first-of-type {
					margin-left: calc( (100vw - 1440px) / 2 - 30px);
				}
				&:last-of-type {
					margin-right: calc( (100vw - 1440px) / 2 - 30px);
				}
			}

			.bra-card {
				margin-bottom: 0;

				.bra-title,
				.bra-subtitle,
				.bra-paragraph {
					text-align: center;
				}
			}

			.bra-image {
				transform: none;
				img {
					width: 100%;
					height: 100%
				}
			}
		}
	}
}
