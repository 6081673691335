@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-stage {
		&__content {
			.bra-stage__card_info {
				&__heading {
					margin-bottom: 10px;
				}
				&__main {
					.bra-title {
						font-size: 30px;
                        line-height: 34px;
                        // margin-bottom: 26px;

                        & + .bra-btn {
                            text-align: center;
                        }
					}
					.bra-paragraph {
						font-size: 15px;
						line-height: 19px;
					}
					.bra-stage__card_visual {
						min-width: 150px;
						width: 150px;
						max-width: 150px;
						min-height: 200px;
					}
				}
			}

			.bra-stage__discalimerWrapper {
				max-width: 42%;
			}
		}

		&__breadcrumbs {
			padding: 100px 30px 0;
		}
	}
}