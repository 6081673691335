@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-recipe-box {
        &--big-card {
            .bra-recipe-box {
                &__title {
                    font-size: 15px;
                    line-height: 1.27;
                }
                &__detail {
                    font-size: 13px;
                }
            }
        }
    }
}
