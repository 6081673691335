@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-color-slider {
		.swiper-container.color-slider {
			.swiper-footer {
				width: 100%;
				margin-top: 30px;
				justify-content: space-around;

				.swiper-scrollbar {
					width: 100%;
				}

				.swiper-button-next,
				.swiper-button-prev {
					display: none;
				}
			}

		}
	}
}
