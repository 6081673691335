.bra-articleCard {
    @include flex(wrap);
    width: 100%;
    height: auto;

    font-size: 15px;
    line-height: normal;

    &__image {
        @include flex(wrap);
        width: 100%;
        height: 320px;
        text-decoration: none;
        background-color: $ui-03;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }
    &__caption {
        @include flex(wrap);
        width: 100%;
        height: auto;
        background-color: $brand-03;
        padding: 32px 24px;
        box-sizing: border-box;
    }
    &__topic {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 15px;
        margin: 0 0 8px 0;
    }
    &__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 26px;
        font-weight: 500;
        color: $support-02;
        margin: 0 0 24px 0;
    }
    &__description {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 15px;
        font-weight: 400;
        color: $brand-01;
    }
    & .bra-link {
        margin: 0 auto 0 0;
    }    
    &--centerText {
        .bra-articleCard__topic,
        .bra-articleCard__title,
        .bra-articleCard__description {
            text-align: center;
        }        
        .bra-articleCard__link {
            margin: 0 auto;
        }
    }
    &--preview {
        & .bra-articleCard__title {
            font-weight: bold;
            color: $brand-01;
            margin: 0 0 8px 0;
        }
    }
    &--topicSupport-02 {
        & .bra-articleCard__topic {
            color: $support-02;
        }
    }
}