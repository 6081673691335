@font-face {
    src: url('#{$pathAssets}/fonts/#{$fontsIcon}/bra-icons.eot?85022927');
    src: url('#{$pathAssets}/fonts/#{$fontsIcon}/bra-icons.eot?85022927#iefix') format('embedded-opentype'),
         url('#{$pathAssets}/fonts/#{$fontsIcon}/bra-icons.woff2?85022927') format('woff2'),
         url('#{$pathAssets}/fonts/#{$fontsIcon}/bra-icons.woff?85022927') format('woff'),
         url('#{$pathAssets}/fonts/#{$fontsIcon}/bra-icons.ttf?85022927') format('truetype'),
         url('#{$pathAssets}/fonts/#{$fontsIcon}/bra-icons.svg?85022927#bra-icons') format('svg');
    font-family: $fontNameIcons;
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: bra-icons-legacy;
    src: url('#{$pathAssets}/fonts/#{$fontsIcon}/braun-icons_legacy.eot');
    src: url('#{$pathAssets}/fonts/#{$fontsIcon}/braun-icons_legacy.eot#iefix') format("embedded-opentype"),
         url('#{$pathAssets}/fonts/#{$fontsIcon}/braun-icons_legacy.woff') format("woff"),
         url('#{$pathAssets}/fonts/#{$fontsIcon}/braun-icons_legacy.ttf') format("truetype");
    font-weight: 400;
    font-style: normal
}

// [class^="dln-icn"],
// [class*=" dln-icn"] {
//     speak: none;
//     /* opacity: .8; */
//     /* For safety - reset parent styles, that can break glyph codes*/
//     font-variant: normal;
//     /* Font smoothing */
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
//     display: inline-block;
//     width: 1em;
//     font-family: "fontello";
//     font-weight: normal;
//     font-style: normal;
//     text-align: center;
//     text-transform: none;
//     text-decoration: inherit;
//     margin-right: .2em;

//     &::before,
//     &::after {
//         font-family: "fontello";
//     }
// }


body {
    @include font;
    font-size: 13px;
    line-height: 125%;
    color: $brand-01;

    // @include small;
    // p {
    //     @include small;
    // }
    input,
    textarea,
    select,
    button,
    // *,
    ::before,
    ::after {
        @include font;
    }
    b,
    strong {
        @include fontBold;
    }
}

@function strip-unit($number) {
    @if type-of($number) == 'number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }

    @return $number;
}

@function REM($value) {
    $stripValue: strip-unit($value);
    $remValue: ($stripValue / 16) + rem;
    @return $remValue;
}
