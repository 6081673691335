.bra-article-link {
	font-size: 15px;
	line-height: normal;

	width: 100%;
	padding-top: 60px;
	max-width: calc(1440px + 60px);
	margin: 0 auto;

	//background: $ui-07;

	&__wrapper {
		position: relative;
		margin: 0 30px;
		width: calc(100% - 60px);
		display: flex;
		flex-direction: column;
	}

	&__heading {
		padding: 60px 0;
		width: 90%;
		margin: 0 auto;

		.bra-title {
			font-size: 36px
		}
	}
	&__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		padding-top: 40px;
		padding-bottom: 40px;

		border-top: 1px solid $ui-08;
		border-bottom: 1px solid $ui-08;

		.bra-card:not(:nth-child(1)):not(:nth-child(2)) {
			&::after {
				content: '';
				position: absolute;
				top: -40px;
				height: 1px;
				width: 100%;
				background: $ui-08;
			}
		}
		.bra-card:nth-last-child(-n+2) {
			margin-bottom: 0px;
		}
		.bra-card {
			position: relative;
			width: calc(50% - 40px);
			margin-bottom: 80px;
			overflow: visible;

			.bra-card__actions {
				a.bra-btnIcon::before {
					content: '';
					background: #ff000000;
					width: 100%;
					height: 100%;
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					z-index: 0;
				}
			}
		}
	}

}