@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-sku-slider {
		&.bra-sku-slider--v2 {
			.swiper-container.sku-slider .swiper-slide {
				width: calc(25% - 31px) !important;
			}
		}

		.swiper-container.sku-slider {
			.swiper-slide {
				max-width: none;
				width: calc(33% - 32px) !important;

				.bra-sideCard {
					&__textContent {
						padding: 90px;
					}
					&__title {
						font-size: 30px;
						line-height: 34px;
					}
					&__description {
						font-size: 15px;
						line-height: 22px;
					}
				}
			}
		}
	}
}
