.braun-nav-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: none;

  &__mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($brand-01, 0.5);
    cursor: pointer;
  }

  &__drawer {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 85.33333333%;
    height: 100%;
    background-color: $brand-03;
    transition: width 0.3s ease-in-out;

    @media screen and (min-width: $braun-s) {
      width: 72.526041667%;
    }
  }

  &__drawer-content {
    position: relative;
    width: 100%;
    overflow-y: auto;
    height: 100%;
    pointer-events: all;
    overflow-x: hidden;
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    height: 60px;
    margin-bottom: 40px;

    @media screen and (min-width: $braun-s) {
      padding: 0 30px;
    }

    .-cta {
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      color: $brand-01;
    }

    .bra-btnIcon--account {
      > input[type='checkbox'] {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        opacity: 0;
      }
    }

    .bra-icn {
      position: relative;
      font-size: 20px;
    }

    .-counter {
      position: absolute;
      top: -8px;
      right: -8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      min-width: 16px;
      height: 16px;
      padding: 0 2px;
      color: $brand-03;
      background-color: $support-02;
      border-radius: 7.5px;
      font-size: 10px;
      @include fontBold;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    @include gap(15px, 'r');
  }

  &__back-cta {
    opacity: 0;
    font-size: 15px !important;
    top: 2px;
  }

  &__close-cta {
    background-image: url('#{$pathAssets}/images/icons/braun-header-icon-close.svg');
    background-repeat: no-repeat;
    background-position: center center;
    width: 18px;
    height: 18px;
  }

  &__lvl1-ul,
  &__lvl2-ul,
  &__lvl3-ul {
    list-style: none;
    margin: 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    @include gap(20px, 'b');
    background-color: $brand-03;

    @media screen and (min-width: $braun-s) {
      padding: 0 30px;
    }

    button,
    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      width: 100%;
      background-color: transparent;
      border: none;
      margin: 0;
      padding: 0;
      font-size: 21px;
      line-height: 26px;
      @include fontBold;
      color: $brand-01;
      text-decoration: none;
      cursor: pointer;

      span {
        @include fontBold;
      }

      .bra-icn {
        font-size: 15px;
      }

      @media screen and (min-width: $braun-s) {
        font-size: 24px;
        line-height: 30px;
      }

    }
  }

  &__lvl2 {
    position: absolute;
    top: 100px;
    width: 100%;
    height: calc(100% - 100px);
    @include gap(30px, 'b');
    background-color: $brand-03;
    display: none;

    h3 {
      @include fontBold;
      color: $brand-01;
      margin: 0 0 30px 0;
      padding: 0 0 0 20px;
      font-size: 21px;
      line-height: 26px;

      @media screen and (min-width: $braun-s) {
        padding: 0 0 0 30px;
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  &__lvl2-ul {
    padding-bottom: 30px;
    button,
    a {
      font-size: 18px;
      line-height: 24px;
      @include font;

      span,
      i {
        @include font;
        transition: all 0.3s ease-in-out;
      }

      &.--open {
        span {
          color: $support-02;
        }
        i {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__lvl3-ul {
    visibility: hidden;
    height: 0;
    opacity: 0;
  }

  &__card-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px 20px 20px;
    @media screen and (min-width: $braun-s) {
      padding: 0 30px 20px 30px;
    }
  }
}
