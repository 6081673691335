@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-reviews {
        &__recap {
            padding: 30px 15px;
        }
        &__item-wrap {
            padding-right: 30px;
        }
        &__review-title {
            font-size: 15px;
            line-height: 1.47;
        }
        &__message {
            font-size: 13px;
            line-height: 1.46;
        }
    }
}
