@mixin fontIcon {
    font-family: $fontNameIcons,
        sans-serif;
    font-variant: normal;
    text-transform: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@mixin font {
    font-family: var(--fontNameRoman);
    font-weight: 400;
    font-style: normal;
}

@mixin fontMedium {
    font-family: var(--fontNameMedium);
    font-weight: 500;
    font-style: normal;
}

@mixin fontBold {
    font-family: var(--fontNameBold);
    font-weight: 700;
    font-style: normal;
}

@mixin flex($wrap) {
    display: flex;
    flex-wrap: $wrap;
}

@mixin ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

@mixin hover-supported {

    /* 
     * https://developer.mozilla.org/en-US/docs/Web/CSS/@media/pointer 
     * coarse: The primary input mechanism includes a pointing device of limited accuracy.
     * + IE11 support
     */
    @media not all and (pointer: coarse),
    not all and (hover: none),
    (-ms-high-contrast: none) {
        &:hover {
            @content;
        }
    }
}

@mixin IE11 {

    /* 
     * IE11 support
     */
    @media (-ms-high-contrast: none) {
        @content;
    }
}

@mixin gap($gap, $dir) {
    gap: $gap;

    // only for ios v. < 14.1
    @supports (-webkit-touch-callout: none) and (not (translate: none)) {
        >* {
            &:not(:last-child) {
                @if $dir =='t' {
                    margin-top: $gap;
                }

                @else if $dir =='r' {
                    margin-right: $gap;
                }

                @else if $dir =='b' {
                    margin-bottom: $gap;
                }

                @else {
                    margin-left: $gap;
                }
            }
        }
    }
}

@mixin lineClamp($lines: 1) {
    overflow: hidden;
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /* autoprefixer: on */
    -webkit-line-clamp: $lines;
}