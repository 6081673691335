/* Fonts */
$fontsIcon: 'fonts-icon';
$fontNameIcons: 'bra-icons';
$fontNameIcons__legacy: 'bra-icons-legacy';

/* Brand Colors */
$brand-01: #000;
$brand-02: #707070;
$brand-03: #FFF;

/* UI Colors */
$ui-01: #EAEAEA;
$ui-02: #BDBDBD;
$ui-03: #F9F9F9;
$ui-04: rgba(0,0,0,.7);
// $ui-04: #A5A5A5;
// $ui-05: #7f7f7f;
// $ui-06: #8e8e8e;
$ui-07: #F5F5F5;
$ui-08: #E6E6E6;
$ui-09: #9B9B9B;

/* Support Colors */
$support-01: #ff4435;
$support-02: #70c13b;
$support-03: #ff9a16;
$support-04: #f4c800;
$support-05: #c49b6d;

$colors-list:(
  toasters: #f6ad53,
  food-steamers: #88c248,
  steam-irons: #5dc7ec,
  citrus-juicers: #f1cd4f,
  hand-mixers: #9dcb6b,
  food-processors: #dfdedf,
  hand-blenders: #95cb3f,
  jug-blenders: #f6b151,
  spin-juicers: #f29662,
  coffee-machines: #cd9a75,
  kettles: #f9bb74,
  breakfast-orange: #F59E33, // EX yellow-sea
  irons-blue: #007bc2,
  hand-blender-green: #70c13b, // EX support-color02
  care-style-purple: #8d7fa6, // EX steam-generator-irons
  vario-fit-blue: #579fd7,

  corvette: #e8bd7b,
  sweet-corn: #fcdf73,
  cotton-seed: #c1bdb5,
  pastel-purple: #ae9db7,
  carolina-blue: #91c2d8,
  yellow-green: #cae278,
  lochmara: #037acc,
  cooking: #ff0000,
  cooking-transparent: #ff3333,
);

@each $key,
$val in $colors-list {
  .bra-color--bg-#{$key} {
    --bgColor: #{$val} !important;
    background-color: #{$val} !important;
  }
  .bra-color--bgHover-#{$key} {
    @include hover-supported() {
      &:hover {
        background-color: #{$val} !important;
      }
    }
  }
  .bra-color--text-#{$key} {
    --txtColor: #{$val} !important;
    color: #{$val} !important;
  }
  .bra-color--border-#{$key} {
    --borderColor: #{$val} !important;
    border-color: #{$val} !important;
  }
}



/* Breakpoints
$grid-columns: 12;
$grid-gutter-width: 20px;

$grid-breakpoints: ( // Extra small screen / phone
    xs: 0,
    // Small screen / phone
    sm: 599px,
    // Medium screen / tablet
    md: 900px,
    // Large screen / desktop
    lg: 1199px,
    // Extra large screen / wide desktop
    xl: 1480px);

$container-max-widths: (
    sm: 560px,
    md: 860px,
    lg: 984px,
    xl: 1480px);*/

// $braun-xs: 576px !default;
// $braun-s: 768px !default;
// $braun-m: 992px !default;
// $braun-l: 1200px !default;

$braun-xs: 0 !default;
$braun-s: 768px !default;
$braun-m: 1024px !default;
$braun-l: 1200px !default;
$braun-xl: 1440px !default;

$padding: 20px;

:root {
    --braun-xs: #{ $braun-xs };
    --braun-s: #{ $braun-s };
    --braun-m: #{ $braun-m };
    --braun-l: #{ $braun-l };
    --braun-xl: #{ $braun-xl };

    --headerHeight: 100px;
    --bgColor: $brand-01;
    --supportColor: $support-02;
    --txtColor: $brand-01;
    --lineHeight: 1;
    --fontSize: 15px;
    --font-size: 15px;
    --min-width: auto;
    --iconColor: black;
    --height: auto;
    --borderColor: black;
    --padding: $padding;
    --overflow: initial;
    --padding-edge: auto;
    --value: 0;
    --textAlign: left;
    --borderColor: black;
    --hoverColor: $support-02;
    --txtProductColor: $brand-01;
    --pageBackground: #ffffff;
}
