@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-videoBanner {

        &--centered {
            .bra-videoBanner {
                &__caption {
                    width: 90px;
                    height: 90px;
                }
    
                &__playIcon {
                    min-width: 90px;
                    min-height: 90px;
                }
            }
        }
    }
 }  
