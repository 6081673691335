.bra-campaign-steps {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 60px 30px;
    box-sizing: border-box;

    &__title {
        @include fontBold;
        line-height: 1.11;
        font-size: 36px;
    }
    
    &__title,
    &__wrap-list {
        margin-bottom: 60px;
    }
    
    &__item {
        display: flex;
        
        + .bra-campaign-steps__item {
            margin-top: 20px;
        }
    }

    &__item-number {
        @include fontBold;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        min-width: 40px;
        height: 40px;
        line-height: 1.22;
        font-size: 18px;
        border: solid 1px $brand-01;
        border-radius: 20px;
        margin-right: 20px;
    }
    
    &__item-title {
        @include fontBold;
        line-height: 1.22;
        font-size: 18px;
    }

    &__item-description {
        line-height: 1.47;
        font-size: 15px;
        padding-top: 5px;
    }

    &__wrap-image {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    &--with-image{
        .bra-campaign-steps{
                &__wrap-image{
                    & .bra-campaign-steps__wrap-list {
                        width: calc(100% - 40px - 270px);
                    }
                    & .bra-image {
                        width: 270px;
                        min-width: 270px;
                        border-radius: 5px;
                        margin-left: 40px;
                        background-color: transparent;


                        &__image{
                            height: auto;
                            object-fit: contain;
                            border-radius: 5px;
                            position: absolute;
                            top: 0;
                        }
                    }
                }
        }
    }

   

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .bra-campaign-steps {    
        &__wrap-image {
            & .bra-image {
                background: none;
                background-color: transparent;
                
                & .bra-image__image {
                    min-width: auto;
                    min-height: auto;
                    object-fit: auto;
                }
            }
        }
    }    
}