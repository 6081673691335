@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-product-details {
        padding-top: 0;
        width: calc(100% - 120px);
        padding: 0 60px;

        &__grid {
            flex-direction: column;
            margin: 0;
        }

        &__col {
            flex: initial;
            width: 100%;
            max-width: none;
            padding: 0;

            + .bra-product-details__col {
                flex: initial;
                max-width: none;
            }
        }

        &__category-wrap {
            margin-top: 30px;
            margin-bottom: 10px;
        }
        &__stock {
            font-size: 13px;
        }
        &__variables {
            margin-bottom: 40px !important;
        }

        &__wishlist {
            top: 0;
        }

        &__slide {
            margin: 0;
        }
        &__badges {
            .bra-badge {
                height: unset;
            }
        }
    }
}
