@media only screen and (max-width: $braun-s - 1) {
    .bra-prodTilesGrid {
        & .bra-prodTile__shadow {
            display: none;
        }
        & .bra-prodTile__details {
            display: none;
        }
        &--detail {
            & .bra-prodTile__details {
                display: flex;
                opacity: 1;
                pointer-events: initial;
                position: relative;
                top: initial;
                left: initial;
            }
        }
        &--four {
            & .bra-prodTile {
                width: calc(50% - 5px);
                height: auto;
                margin: 0 10px 10px 0;

                &:nth-of-type(4n+4) {
                    margin: 0 10px 10px 0;
                }
                &:nth-of-type(3n+3) {
                    margin: 0 10px 10px 0;
                }
                &:nth-of-type(2n+2) {
                    margin: 0 0 10px 0;
                }
            }
            &.bra-prodTilesGrid--detail {
                & .bra-prodTile {
                    width: 100%;
                    height: auto;
                    margin: 0 0 20px 0;

                    &:nth-of-type(4n+4) {
                        margin: 0 0 20px 0;
                    }
                    &:nth-of-type(3n+3) {
                        margin: 0 0 20px 0;
                    }
                    &:nth-of-type(2n+2) {
                        margin: 0 0 20px 0;
                    }
                    &__product {
                        padding: 20px;
                    }
                    &__imgWrap {
                        height: 200px;
                    }
                    &__name,
                    &__type,
                    &__price {
                        line-height: 19px;
                        font-size: 15px;
                    }
                    &__priceWrap {
                        margin-bottom: 15px;
                    }
                    &__foot {
                        flex-direction: row;
                        height: 20px;

                        & .bra-prodTile__rating {
                            margin: 0;
                        }
                        & .bra-input--checkbox {
                            margin: 0 0 0 auto;
                        }
                    }
                }
            }
        }
    }
}
