@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-highlights {
        .bra-introText {
            margin-bottom: 40px;
        }
        .swiper-footer {
            width: 100%;
        }
    }
}
