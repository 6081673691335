.bra-productComparison {
	&__table {
		position: relative;
		font-size: 15px;
		line-height: normal;

		.bra-productComparison__containerClonedHeader {
			position: fixed;
			width: 100%;
			top: 0;
			left: 0;
			background: white;
			box-shadow: 0 0 10px rgba(black,.1);

			transform: translateY(-100%);
			transition: transform 320ms ease-in-out;

			&.visible {
				transform: translateY( 130px );
				transform: translateY( var(--headerHeight) );
				transition: transform 320ms ease-in-out;
			}

			.bra-productComparison__row.bra-productComparison__row--header.wrapper {
				min-width: 800px;
				padding: 0;
				margin: 0 auto;

				@media only screen and (max-width:1440px) {
					margin: 0 30px;
					width: calc(100% - 60px);
				}

				@media only screen and (max-width:860px) {
					margin: 0 30px;
					min-width: 800px;
					width: 800px;
				}
			}
			.bra-productComparison__column--product {
				.bra-productComparison__cell {
					justify-content: flex-end;
					padding: 20px;
				}
			}
		}

	}
	&__tableWrapper {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex-basis: 0;
		flex: 1;
		min-width: 800px;

		
	}

	&__row {
		display: flex;
		flex-basis: 0;
		flex-grow: 1;
		flex-direction: row;

		&--header {
			.bra-productComparison__column--header {
				.bra-productComparison__cell {
					.bra-paragraph {
						margin-bottom: 22px
					}
				}
			}
			.bra-productComparison__column--product {
				.bra-productComparison__cell {
					.bra-image {
						min-height: 200px;
						margin: 60px 0;
					}
					.bra-paragraph {
						@include fontBold;
					}
				}
			}
		}
		&--body {
			box-shadow: 0 1px 0 0 $ui-01;
			margin-bottom: 1px;
		}
	}

	&__column {
		display: flex;
		flex-grow: 1;
		flex-basis: 0;
		flex-direction: column;

		box-shadow: 1px 0 0 0 $ui-01;

		&--header {
			min-width: 250px;

			.bra-productComparison__cell {
				flex-direction: column;

				@include fontBold;
				line-height: 21px;
			}
		}
		&--product {
			.bra-productComparison__cell {
				flex-direction: column;

				@include font;
				font-size: 15px;
				line-height: 21px;
				color: $brand-02;

				button {
					position: absolute;
					top: 0;
					right: 0;

					width: 3em;
					height: 3em;
					line-height: 3em;

					.bra-btnIcon__wrap {
						font-size: 15px;
					}

					@include hover-supported() {
						&:hover {
							.bra-btnIcon__wrap {
								i {
									color: $support-02;
								}
							}
						}
					}
				}
			}
		}
	}

	&__cell {
		display: flex;
		height: 100%;
		padding: 20px 10px;
		box-shadow: 0 1px 0 0 $ui-01;
		justify-content: center;
		position: relative;

		min-height: 40px;
		vertical-align: middle;
		align-content: center;
		align-items: center;
		text-align: center;

		.bra-productComparison__iconWrapper {
			background: black;
			border-radius: 70px;
			color: white;
			font-size: 12px;
			line-height: 2em;
			display: block;
			width: 2em;
			height: 2em;
			text-align: center;
			i {
				font-size: 11px;
			}
		}
	}
}

