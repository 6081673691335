@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-sideCard {
        &__subTitle {
            font-size: 17px;
            line-height: 24px;
            margin-bottom: 20px;
        }
        &__title {
            font-size: 36px;
            line-height: 40px;
            margin-bottom: 20px;
        }
        &__description {
            font-size: 15px;
            line-height: 22px;
        }
    }
}