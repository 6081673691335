.dln-checkout__payment-methods-wrapper {
  .adyen-checkout__payment-method__radio {
    border: 1px solid var(--ui-02) !important;
    box-shadow: none !important;
    width: 20px;
    height: 20px;

    &--selected {
      background-color: transparent;

      &::after {
        background-color: var(--support-02);
        width: 50%;
        height: 50%;
        left: 1px;
      }
    }
  }

  .adyen-checkout__input {
    border-radius: 2px;
  }

  .adyen-checkout__checkbox {
    .adyen-checkout__checkbox__label {
      &:after {
        background-color: var(--support-02) !important;
      }
    }
  }

  .adyen-checkout__button {
    border-radius: 20px;
    background: var(--brand-01);
    transition: all 0.3s ease-out;
    box-shadow: none;

    &:hover {
      background: var(--brand-01);
      opacity: 0.8;
      box-shadow: none;
    }
  }
}
