.bra-image-slider {
	font-size: 15px;
	line-height: normal;

	position: relative;
	max-width: 1440px;
	width: calc(100% - 60px);

	.bra-sideCard__textContent {
		padding-left: 100px;
	}

	.swiper-container {
		.swiper-slide {
			.bra-sideCard {
				max-width: 1440px;
				margin: 0;
			}
		}
	}

	.swiper-footer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100% !important;
		height: 100% !important;
		max-width: 100% !important;
		margin: 0 !important;

		.swiper-button-next,
		.swiper-button-prev {
			background-image: none;
			background-color: rgba(white,.8) !important;
			width: auto;
			height: auto;
			position: absolute !important;
			font-size: 20px;
			bottom: auto !important;
			width: 40px !important;
			height: 40px !important;
			top: calc(50% - 20px) !important;

			i {
				width: 2em;
				height: 2em;
				display: block;
				text-align: center;
				line-height: 2;
			}

			@include hover-supported() {
				&:hover {
					color: $support-02;
				}
			}
		}
		.swiper-button-next {
			right: 0 !important;
			left: auto !important;
		}
		.swiper-button-prev {
			left: 0 !important;
			right: auto !important;

			// @media (-ms-high-contrast: none) {
			// 	left: -50%;
			// 	transform: translateX(100%);
			// }
		}

		.swiper-pagination {
			background: rgba(white,.8);
			padding: 10px;
			font-size: 0;
			white-space: nowrap;

			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);

			.swiper-pagination-bullet {
				background: transparent;
				border: 1px solid #000;
				border-radius: 50%;
				height: .6666666667em;
				margin: 0 .3333333333em;
				padding: 0;
				width: .6666666667em;
				opacity: 1;
				font-size: 12px;

				&-active {
					background: black;
				}
			}
		}
    }
    
    .swiper-scrollbar {
        display: none;
    }
}