.braun-videoSlider {
  max-width: 1500px;
  margin: 0 auto;
  width: calc(100% - 40px);
  padding: 0 20px;

  @media only screen and (min-width:$braun-s) {
    width: calc(100% - 120px);
    padding: 0 60px;
  }

  @media only screen and (min-width:$braun-m) {
    width: calc(100% - 60px);
    padding: 0 40px;
  }

  .braun-titlebox {
    gap: 10px;
    padding-bottom: 40px;
  }

  .braun-titlebox__title,
  .braun-titlebox__description {
    padding: 0;
    margin: 0;
  }

  .braun-titlebox__title {
    font-size: REM(28px);

    @media only screen and (min-width:$braun-s) {
      font-size: REM(37px);
    }
  }

  .swiper-wrapper {
    position: relative;
    transform: translateX(calc((100% - 313px) / 2));

    @media only screen and (min-width:$braun-s) {
      transform: translateX(calc((100% - 600px) / 2));
    }

    @media only screen and (min-width:$braun-l) {
      transform: translateX(calc((100% - 826px) / 2));
    }
  }

  .swiper-slide {
    position: relative;
    max-width: 313px;
    padding-inline: 7.5px;

    @media only screen and (min-width:$braun-s) {
      max-width: 600px;
      padding-inline: 14px;
    }

    @media only screen and (min-width:$braun-l) {
      max-width: 826px;
      padding-inline: 71px;
    }
  }

  .swiper-container-initialized .swiper-button {
    @media only screen and (min-width:$braun-l) {
      display: flex;
    }
  }

  .swiper-button {
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    background-color: var(--brand-03);
    border: 1px solid var(--ui-11);
    border-radius: 50%;
    top: calc(50% - 27px);
    transform: translateY(-50%);

    &-prev {
      left: calc((100% - 818px) / 2);
    }

    &-next {
      right: calc((100% - 818px) / 2);
    }
  }

  .swiper-pagination {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    padding-top: 23px;
    padding-bottom: 30px;
    bottom: unset !important;
    min-height: 65px;

    @media only screen and (min-width:$braun-l) {
      visibility: hidden;
      pointer-events: none;
    }

    &-bullet {
      width: 8px;
      height: 8px;
      background-color: var(--ui-11);

      &-active {
        width: 12px;
        height: 12px;
        background-color: var(--brand-05);
      }
    }

    &-lock {
      visibility: hidden;
    }
  }
}
