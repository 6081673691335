.bra-stepCard {
    font-size: 15px;
    line-height: normal;

    @include flex(wrap);
    width: 100%;
    height: auto;
    background-color: $ui-03;
    padding: 16px;
    box-sizing: border-box;

    &__imageWrap {
        display: flex;
        flex-wrap: wrap;
        width: 152px;
        height: 152px;
        border-radius: 50%;
        background-color: $ui-01;        
        position: relative;
    }    
    &__stepNumber {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 2.2;
        font-size: 17px;
        font-weight: bold;
        color: $brand-03;
        text-align: center;
        border-radius: 50%;
        background-color: $brand-01;
        padding: 0 1px 0 0;       
        position: absolute;
        top: 0;
        left: 0;         
    }
    &__image {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        overflow: hidden;
    }
    &__wrap {
        @include flex(wrap);
        // align-content: flex-start;
        width: calc(100% - 152px - 32px);        
        margin: 0 0 0 32px;
    }   
    &__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 26px;        
        font-weight: 500;
        color: $brand-01;
        margin: 0 0 24px 0;
    }
    &__subtitle {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 17px;
        font-weight: bold;
        color: $brand-01;        
        margin: 0 0 24px 0;
    }
    &__text {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 17px;
        font-weight: 400;
        color: $brand-01;
        margin: 0 0 24px 0;        
    }
    & .bra-btn {
        margin: auto auto 0 0;
    }
}