@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-pageHeader__wrapper {
		.bra-title {
			font-size: 35px;
			line-height: 44px;
		}

		&.bra-pageHeader--category-header {
			.bra-title {
				font-size: 21px;
				line-height: 26px;
			}
			.bra-link {
				font-size: 13px;
				line-height: 18px;
			}
		}
	}
}
