.bra-horizontalCard {
	font-size: 15px;
	line-height: normal;
	@include flex(wrap);
	width: 100%;
	padding: 16px 0;
    box-sizing: border-box;
    margin-bottom: 30px;

	&__image {
		display: block;
		width: 80px;
		height: 80px;
		border-radius: 50%;
		background-color: $ui-03;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		margin: 0 30px 0 0;

		&--squared {
			border-radius: 0;
		}
	}
	&__wrap {
		width: calc(100% - 80px - 30px);
	}
	&__title {
		@include fontBold;
		font-size : 17px;
		line-height : 24px;

		display: block;
		width: 100%;
		margin: 0 0 10px 0;
	}
	.bra-paragraph {
		font-size : 15px;
		line-height : 22px;
	}

	&__wrapper {
		@include flex(wrap);
		justify-content: space-between;

		max-width: 1440px;
		margin: 0 auto;
		padding: 0 30px;

		@media only screen and (min-width:1500px) {
			padding: 0;
		}

		.bra-horizontalCard {
			width: calc(50% - 10px);
			margin-bottom: 40px;
		}
	}

	&--small {
		padding: 0;
		margin: 0 -30px;

		.bra-horizontalCard {
			width: 50%;
			margin: 0;
			margin-bottom: 40px;
			padding: 0 30px;
		}
	}
}
