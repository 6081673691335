.bra-videoFull {
  position: relative;
  aspect-ratio: 16/9;

  &__mask {
    display: none;
    @media only screen and (min-width:$braun-l) {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(to top, rgba(var(--brand-01), 0.5), transparent);
      z-index: 1;
      height: 50%;
    }
  }

  &__cover {
    position: relative;
    aspect-ratio: 16/9;

    img {
      display: block;
      max-width: 100%;
      height: auto;
      object-fit: cover;
    }
  }

  &.--ready {
    .bra-videoFull__play-button {
      opacity: 1;
      pointer-events: all;
      cursor: pointer;
    }
  }

  &__play-button {
    position: absolute;
    inset: 0;
    background: none;
    border: none;
    opacity: 0.5;
    pointer-events: none;
    z-index: 2;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 67px;
      height: 67px;
      @media only screen and (min-width:$braun-s) {
        width: 112px;
        height: 112px;
      }
    }
  }

  &__texts {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
    gap: 10px;
    padding-inline: 3px;
    text-align: center;

    @media only screen and (min-width:$braun-l) {
      position: absolute;
      top: 50%;
      transform: translateY(100%);
      gap: 0;
      z-index: 3;
    }

    .bra-videoFull__title,
    .bra-videoFull__title p {
      font-size: 25px;
      color: var(--brand-01);
      line-height: 37px;
      @media only screen and (min-width:$braun-l) {
        font-size: 37px;
        color: var(--brand-03);
        margin-top: 10px;
      }
    }

    .bra-videoFull__subtitle,
    .bra-videoFull__subtitle p {
      font-size: 12px;
      color: var(--brand-01);
      letter-spacing: 1.714px;
      opacity: 0.8;

      @media only screen and (min-width:$braun-l) {
        font-size: 14px;
        letter-spacing: 2px;
        color: var(--ui-12);
      }
    }
  }

  &__player-container {
    position: absolute;
    inset: 0;
    z-index: -1;
    opacity: 0;

    &.--no-cover {
      opacity: 1;
    }

    &:hover {
      .bra-videoFull__close-button {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &.--visible {
      z-index: 3;
      opacity: 1;
    }
  }

  .plyr--video {
    aspect-ratio: 16/9;
  }

  .plyr--paused + .bra-videoFull__close-button {
    opacity: 1 !important;
    transform: translateY(0) !important;
  }

  &__close-button {
    position: absolute !important;
    top: 0;
    right: 0;
    z-index: 50;
    width: 3em;
    height: 3em;
    opacity: 0;
    transform: translateY(-100%);
    transition: all 0.4s ease;

    &:hover {
      background-color: var(--plyr-accent-color);
    }

    .bra-btn-close__icon {
      text-align: center;
      cursor: pointer;
      opacity: 1;

      &:not(:disabled):hover {
        opacity: 1;
      }

      &:before {
        content: '';
        display: inline-block;
        position: relative;
        width: 26px;
        height: 26px;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url('#{$pathAssets}/images/icons/braun-header-icon-close.svg');
        background-position: center;
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
      }
    }
  }

  &__safari-overlay {
    display: none;
    position: absolute;
    padding-right: 100%;
    padding-top: 35%;
    z-index: 10;

    &.active {
      display: block;
    }
  }
}
