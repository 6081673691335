@media only screen and (max-width: $braun-s - 1) {
    .bra-prodGallery {
        &__thumbnails {
            & .bra-prodGallery__swiperContainer {    
                & > .swiper-wrapper {
                    margin-bottom: 20px;
                    
                    & .bra-prodGallery__slide {
                        height: 247px;
                        
                        &Image {
                            height: 247px;

                            & > .bra-image {
                                background-color: $brand-03;
                            }
                        }
                    }
                }
            }
        }
    }    
}
