@media only screen and (max-width: $braun-s - 1) {
    .bra-where-to-buy {
        padding: 20px 20px 30px 20px;

        &__grid {
            // flex-direction: column;
            margin: 0;
        }

        &__item {
            flex: initial;
            // flex: 1 0 100%;
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            //
            max-width: 100%;
            padding: 0;
        }
        &__box {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            text-align: center;
            border: 1px solid $ui-08;
            padding: 20px 30px;
            box-sizing: border-box;

            & .bra-image {
                align-items: center;
                justify-content: center;
                height: 83px;
                margin-bottom: 11px;
                background-color: transparent;

                & .bra-image__image {
                    width: auto;
                    max-width: 100%;
                    max-height: 100%;
                    // height: auto;
                    // object-fit: initial;
                    // top: initial;
                    object-fit: contain;
                    // left: initial;
                }
            }
            & .bra-btn--rounded {
                margin: 0 auto;
            }
        }
        &__price-section {
            display: block;
            width: 100%;
            line-height: 19px;
            // line-height: 1.46;
            font-size: 13px;
            margin: 0 0 20px 0;
        }
    }
}
