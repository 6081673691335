@media only screen and (max-width: $braun-s - 1) {
    .bra-compareTray {
        top: calc(100% - 252px);

        &__head {
            order: 1;
            padding: 0 20px;
        }
        & .bra-compareTray__actions {
            order: 3;
            width: 100%;
            padding: 0 20px;
            margin-bottom: 20px;

            & .bra-btn {
                width: calc(50% - 10px);

                &--compare {
                    margin: 0 auto 0 0;
                }
            }
        }
        &__products {
            order: 2;
            width: 100%;
            padding: 0 20px;
            margin-bottom: 20px;

            & .bra-prodTile {
                width: calc(33.33% - 3.33px);
                margin: 0 5px 0 0;

                &--compare {
                    & .bra-prodTile__name {
                        margin-bottom: 10px;
                    }
                }
            }
        }
        &__addProd {
            width: calc(33.33% - 3.33px);
            margin: 0 5px 0 0;
        }
        &--collapsed {
            top: calc(100% - 58px);
        }
        &--expanded {
            top: 0;

            & .bra-compareTray__main {
                &--sticky {
                    padding: 0;
                }
            }
            & .bra-compareTray__head {
                height: 70px;
                margin-bottom: 0;

                & .bra-compareTray__title {
                    height: 70px;
                    line-height: 70px;
                    font-size: 18px;
                }
            }
            & .bra-compareTray__actions {
                display: none;
            }
            & .bra-compareTray__products {
                width: 100%;
                padding: 0 20px 20px 20px;
                margin: 0;

                & .bra-prodTile {
                    &__head {
                        margin-bottom: 5px;

                        & .bra-btnIcon {
                            transform: translateX(10px);
                        }
                    }
                    &__product {
                        padding: 5px 15px 15px 15px;
                        box-sizing: border-box;
                    }
                    &__imgWrap {
                        height: 60px;
                    }
                    & .bra-prodTile__productInfo {
                        width: 100%;
                        margin-bottom: 10px;

                        & .bra-prodTile__name {
                            display: none;
                        }
                        & .bra-prodTile__type {
                            line-height: 11px;
                            font-size: 11px;
                            text-align: center;
                        }
                    }
                    &__priceWrap {
                        display: none;
                    }
                    &__foot {
                        & .bra-prodTile__rating {
                            display: none;
                        }
                        & .bra-btn {
                            min-width: auto;
                            height: 13px;
                            line-height: 13px;
                            text-decoration: underline;
                            border: none;
                            border-radius: 0;
                            padding: 0;
                        }
                    }
                    &--compare.bra-prodTile--collapsed {
                        & .bra-prodTile__product {
                            padding: 15px;

                            & .bra-prodTile__head {
                                & .bra-btnIcon {
                                    transform: none;
                                }
                            }
                            & .bra-prodTile__productInfo {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
        &__main--sticky {
            padding: 0;
        }
    }
}
