@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-breadcrumbs {
        &__pdp {
            width: calc(100% - 120px);
            padding: 0 60px;
        }
    }
}

@media only screen and (max-width: $braun-m - 1) {
    .bra-breadcrumbs {
        &__link {
            font-size: 13px;
            line-height: 18px;
        }
    }
}