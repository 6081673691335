.bra-interactiveBlock {
	width: 100%;
	font-size: 15px;
	line-height: normal;

	&__imageWrapper {
		position: relative;
		overflow: hidden;

		max-width: 1440px;
		margin: 0 auto;
		width: calc(100% - 60px);

		border-radius: 5px;

		* {
			user-select: none;
		}

		.bra-interactiveBlock__image {
			&--before {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				z-index: 10;
			}

			&--handle {
				position: absolute;
				top: 0;
				left: 0;
				transform: translateX(-1px);
				z-index: 10;

				width: 3px;
				height: 9999px;
				background: $support-02;

				&__leftArrow,
				&__rightArrow {
					width: 0;
					height: 0;
					border: 20px inset transparent;
					position: absolute;
					top: 50%;

					&::before,
					&::after {
						content: '';
						border-right: 6px solid $support-02;
						height: 40px;
						position: absolute;
						display: block;
						margin-top: -20px;
					}
					&::after {
						border-right-color: rgba($support-02,.5);
					}
				}
				&__leftArrow {
					transform: translateX(-50%) translateY(-50%);
					border-right: 20px solid $support-02;
					left: 50%;
					margin-left: -64px;

					&::before {
						margin-left: 24px;
					}
					&::after {
						margin-left: 34px;
					}
				}
				&__rightArrow {
					transform: translateX(50%) translateY(-50%);
					border-left: 20px solid #72bf44;
					right: 50%;
					margin-right: -62px;

					&::before {
						margin-left: -30px;
					}
					&::after {
						margin-left: -40px;
					}
				}
			}
		}
	}
}
