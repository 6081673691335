@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-basic-form {
        &__title {
            font-size: 30px;
            line-height: 1.13;
        }
        
        &__wrap {
            width: 100%;
        }
    }
}
