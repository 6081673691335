.bra-pdp.--alternative {
  .page_margin,
  .bra-breadcrumbs__pdp,
  .bra-product-details {
    width: 100%;
    padding-inline: 16px;
    margin: 0;
    @media (min-width: $braun-xl) {
      padding-inline: 0;
    }
  }
}

.bra-pdp-alternative-details {
  margin-top: 24px;
  padding-inline: 0;

  @media (min-width: $braun-m) {
    margin-top: 0;
  }

  .--details__badges {
    display: flex;
    gap: 5px;
    margin-bottom: 24px;
  }

  .--name {
    @include fontBold;
    font-size: 24px;
    line-height: 30px;

    @media (min-width: $braun-m) {
      font-size: 36px;
      line-height: 40px;
    }
  }

  .--code {
    @include font;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .--actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .bra-btn {
      width: 100%;
    }
  }

  klarna-placement {
    .container {
      padding-block: 5px !important;
    }
  }

  .--wishlist-and-stock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    margin-block: 16px;
    padding-block: 16px;

    .bra-btnIcon {
      display: flex;
      align-items: center;
      gap: 5px;
      width: auto;

      &:active {
        color: var(--brand-01);
      }

      &::before {
        color: var(--brand-01);
        font-size: 0.75rem;
      }

      &.bra-icn__wishlist-inactive {
        .--active {
          display: none;
        }
      }

      &.bra-icn__wishlist-active {
        .--inactive {
          display: none;
        }
      }

      .--text {
        @include fontBold;
        font-size: 14px;
        line-height: 20px;
        text-decoration: underline;
      }
    }

    .--stock {
      @include font;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .bra-product-details__infos {
    flex-direction: column;
    gap: 16px;
    margin-bottom: 24px;
    padding-block: 16px;
    border-block: 1px solid $ui-08;
    color: var(--brand-01);

    @media (min-width: $braun-m) {
      flex-direction: row;
      justify-content: space-between;
    }

    .bra-product-details__info {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 10px;
      font-size: 12px;
      line-height: 16px;
      text-decoration: underline;
      flex: unset;
      i {
        text-decoration: none;
      }
      .bra-product-details__info-br-mobile {
        display: none;
      }
    }
  }

  .--description {
    @media (min-width: $braun-m) {
      border-top-color: transparent;
    }

    .bra-multi-accordion__title,
    .bra-multi-accordion__description {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.bra-pdp-alternative-details {
  .--variants {
    button {
      display: block;
      border: none;
      background: none;
      margin-bottom: 16px;

      &.--cursor {
        cursor: pointer;
      }
    }

    .--cta-variant-selector {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      border: 1px solid $ui-08;
      border-radius: 0.3125rem;
      padding: 16px 56px 16px 16px;
      @media (min-width: $braun-m) {
        padding: 24px 64px 24px 24px;
      }

      .--variant-label {
        @include font;
        font-size: 14px;
        line-height: 20px;
        text-align: left;
      }

      .--cta-chevron {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        width: 24px;
        height: 24px;
        background-color: var(--brand-01);
        color: var(--brand-03);
        border-radius: 100%;
        font-size: 14px;
        @media (min-width: $braun-m) {
          right: 24px;
        }
      }
    }

    .--out-of-stock-label,
    .--text-selector {
      @include font;
      font-size: 14px;
      line-height: 20px;
    }

    .--text-selector {
      @include fontBold;
      text-decoration: underline;
    }
  }

  .bra-pdp__prices {
    .--mainPrice {
      text-align: left;
      width: auto;
      color: var(--brand-01);
    }

    .--rrpLabel,
    .--strikePrice,
    .--discountPrice,
    .--lastLowerPriceLabel,
    .--lastLowerPrice,
    .--discountLastLowerPrice {
      margin-top: 0;
      font-size: 12px;
      line-height: 16px;
      color: var(--brand-02);
    }

    .--discountPrice {
      @include fontMedium;
      color: var(--brand-01);
    }

    .--lastLowerPriceLabel,
    .--lastLowerPrice,
    .--discountLastLowerPrice {
      margin-top: 5px;
    }

    .--icon {
      background-color: var(--brand-03);
      color: var(--brand-01);
      border: 1px solid var(--brand-01);
      font-size: 10px;
      width: 12px;
      height: 12px;
    }
  }
}

// drawer ui
.bra-pdp__selector-drawer {
  position: fixed;
  inset: 0;
  z-index: 9998;
  display: none;
  .--mask {
    position: absolute;
    inset: 0;
    background-color: rgba($brand-01, 0.5);
    cursor: pointer;
  }

  .--content {
    position: absolute;
    top: 0;
    right: -100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: var(--brand-03);
    border-top-left-radius: 0.8125rem;
    border-bottom-left-radius: 0.8125rem;
    padding-top: 24px;

    @media (min-width: $braun-m) {
      width: 480px;
      padding-top: 32px;
    }
  }

  .--close-cta {
    background-color: var(--brand-01);
    color: var(--brand-03);
    width: 32px;
    min-height: 32px;
    border: none;
    border-radius: 100%;
    cursor: pointer;
    align-self: flex-end;
    margin-right: 16px;
    @media (min-width: $braun-m) {
      margin-right: 32px;
    }
  }

  .--title {
    @include fontBold;
    font-size: 24px;
    line-height: 30px;
    padding-inline: 16px;
    @media (min-width: $braun-m) {
      padding-inline: 32px;
    }

    i {
      @include font;
    }
  }

  .--variant-list {
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 20px;

    padding-inline: 16px;
    @media (min-width: $braun-m) {
      padding-inline: 32px;
    }

    .--item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      border: 1px solid $ui-08;
      border-radius: 0.3125rem;
      padding: 16px;
      transition: all 0.3s ease;
      text-decoration: none;
      order: 1;

      &:not(:first-child) {
        cursor: pointer;
      }

      &:first-child {
        border-color: var(--support-02);
      }

      &:hover:not(:first-child) {
        border-color: var(--ui-10);
      }

      &.--first {
        order: 0;
      }

      .--name {
        @include font;
        font-size: 16px;
        line-height: 22px;
        color: var(--brand-01);
      }

      .--code {
        @include fontMedium;
        font-size: 10px;
        line-height: 16px;
        color: var(--brand-02);
        margin-bottom: 0;
      }
    }

    .bra-pdp__prices {
      .--mainPrice {
        font-size: 14px;
        line-height: 20px;
      }

      .--discountPrice {
        padding: 1px 4px;
        border-radius: 4px;
        background-color: var(--support-02);
        color: var(--brand-03);
      }
    }
  }
}
