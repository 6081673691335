@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
}

@media only screen and (max-width: $braun-l - 1) {
    .bra-index {
        font-size: 15px;
        line-height: 21px;

        &__link {
            font-size: 15px;
            line-height: 21px;
        }
    }
}