.bra-reviews {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    & .page_margin {
        width: calc(100% - 60px);
    }
    &__grid {
        display: flex;
        margin: 0 -10px;
        align-items: flex-start;
    }

    &__recap,
    &__lists {
        margin: 0 10px;
    }

    &__recap {
        flex: 1 1 20%;
        background: $ui-07;
        border-radius: 5px;
        text-align: center;
        padding: 30px;

        .bra-star-rating {
            justify-content: center;
            margin-bottom: 30px;
        }
    }

    &__total-reviews {
        @include fontBold;
        font-size: 72px;
        line-height: normal;
        margin-bottom: 15px;
    }
    
    &__lists {
        flex: 1 1 80%;
    }

    &__item-wrap {
        border: 1px solid $ui-08;
        border-radius: 5px;
        padding: 30px;
        padding-right: 100px;
        display: flex;

        + .bra-reviews__item-wrap {
            margin-top: 20px;
        }
    }
    
    &__username-col {
        flex: 1 1;
        padding-right: 20px;

        .bra-star-rating {
            margin: 10px 0;
        }
    }

    &__review-col {
        flex: 4 1;
    }

    &__username {
        @include fontBold;
        font-size: 15px;
        line-height: 1.6;
    }

    &__time-send {
        font-size: 15px;
        line-height: 1.47;
        color: $ui-09;
    }

    &__review-title {
        @include fontBold;
        font-size: 18px;
        line-height: 1.33;
        margin-bottom: 10px;
    }

    &__message {
        font-size: 15px;
        line-height: 1.47;
    }

    &__show-more {
        display: flex;
        justify-content: center;
        margin-top: 30px;
    }
}