@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-article-stage {
        & .bra-subtitle {
            line-height: 22px;
            font-size: 15px;
        }
        &__actions {
            a {
                font-size: 13px;
            }
        }
    }
}
