@media only screen and (max-width: $braun-s - 1) {
	.page__wrapper {
		.page__body {
			.page__aside,
			.page__content {
				display: block;
				width: 100%;
			}
		}
	}

	.productsList__filterBar {
		> div {
			margin-top: 10px;
			margin-bottom: 10px;
			margin-right: 10px;
		}
		&.productsList__filterBar--upper {
		}
		&.productsList__filterBar--lower {
		}

		.bra-pagination {
			margin-left: initial;
		}
		.bra-input--select + .bra-input--select {
			margin-left: initial;
		}
	}

	.productsList__productsConatiner {
		display: flex;
		flex-direction: inherit;
		flex-wrap: wrap;
		justify-content: space-around;

		.bra-productCard {
			width: calc(50% - 20px);
		}
		.bra-productCard + .bra-productCard {
			margin-left: 0
		}
	}
}