@media only screen and (max-width: $braun-s - 1) {
    .bra-reviews {
        &__grid {
            flex-wrap: wrap;
        }
    
        &__recap {
            flex: 1 1 100%;
            margin-bottom: 20px;

            .bra-star-rating {
                margin-bottom: 20px;
            }
        }

        &__total-reviews {
            font-size: 48px;
        }
        
        &__lists {
            flex: 1 1 100%;
        }
        
        &__item-wrap {
            padding: 20px;
            padding-right: 20px;
            flex-wrap: wrap;
        }

        &__username-col {
            flex: 1 1 100%;
            display: flex;
            flex-wrap: wrap;

            .bra-star-rating {
                flex: 1 1 100%;
                order: 3;
                margin: 15px 0;
            }
        }
    
        &__review-col {
            flex: 1 1 100%;
        }

        &__username {
            font-size: 13px;
            line-height: 1.69;
            flex: 1 1 50%;
            order: 1;
        }
        
        &__time-send {
            font-size: 13px;
            line-height: 1.46;
            flex: 1 1 50%;
            order: 2;
            text-align: right;
        }
    
        &__review-title {
            font-size: 15px;
            line-height: 1.47;
        }
    
        &__message {
            font-size: 13px;
            line-height: 1.46;
        }
    }
}
