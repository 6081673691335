@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
}

@media only screen and (max-width: $braun-m - 1) {
	.bra-footer {
		&__country {
			height: 30px;

			&Code {
				width: 30px;
				height: 30px;
				line-height: 30px;
			}
			&Name {
				height: 30px;
				line-height: 30px;
			}
		}
	}
}
