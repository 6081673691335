.bra-btnIcon {
  @include hover-supported() {
    color: $ui-04;
    & .bra-icn,
    .bra-icn {
      color: $ui-04;
    }
  }

  --bgColor: #{$brand-03};
  --txtColor: #{$brand-01};
  --supportColor: #{$support-02};

  display: inline-block;
  width: 1em;
  line-height: normal;
  font-size: 15px;
  font-size: 20px;
  color: $brand-01;
  text-decoration: none;
  border: none;

  background-color: transparent;
  box-sizing: content-box;

  appearance: none;
  overflow: visible;
  cursor: pointer;

  position: relative;

  & .bra-icn {
    line-height: 1;
    font-size: 1em;
    color: $brand-01;
    margin: 0;
  }
  &__wrap {
    width: auto;
    cursor: pointer;
  }
  &--cart {
    & .bra-btnIcon__icon {
      margin: 0 4px 0 0;
    }
  }
  &__checkoutCounter {
    display: flex;
    align-items: center;
    align-content: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $support-02;
    position: absolute;
    top: -8px;
    right: -8px;

    &Text {
      @include fontBold;
      /*  */
      display: inline-block;
      width: auto;
      height: 11px;
      line-height: 100%;
      font-family: sans-serif;
      font-size: 11px;
      font-style: inherit;
      color: $brand-03;
      text-align: center;
      padding: 0 0;
      margin: 0 auto;
    }
  }

  &--chat {
    width: 1.8em;

    & .bra-icn {
      color: $support-02;
    }
  }
  &:active {
    color: $support-02;
    .bra-icn {
      color: $support-02;
    }
  }

  &:disabled,
  &:disabled:hover {
    cursor: default;
    .bra-icn {
      color: $brand-02 !important;
    }
  }

  &--circle {
    @include hover-supported() {
      &:hover {
        background: $brand-03;
        transition: background 0.2s ease-in-out;
        .bra-btnIcon__circle {
          background: $brand-03;
          transition: background 0.2s ease-in-out;
          .bra-icn {
            color: $brand-01;
            transition: color 0.2s ease-in-out;
          }
        }
        .bra-icn {
          color: $brand-01;
          transition: color 0.2s ease-in-out;
        }
      }
    }
    width: auto;
    height: auto;
    border-radius: 150px;
    overflow: hidden;
  }

  &__circle {
    @include hover-supported() {
      &:hover {
        background: $brand-03;
        transition: background 0.2s ease-in-out;

        .bra-icn {
          color: $brand-01;
          transition: color 0.2s ease-in-out;
        }
      }
    }
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 2em;
    height: 2em;
    border-radius: 50%;
    background-color: $brand-01;
    transition: background 0.2s ease-in-out;

    .bra-icn {
      color: $brand-03;
    }
  }

  &--text {
    @include hover-supported() {
      &:hover {
        text-decoration: underline;
      }
    }
    display: inline-flex;
    align-items: baseline;
    width: auto;
    margin-right: 20px;
    &:active {
      color: $support-02;
      .bra-icn {
        color: $support-02;
      }
    }
  }

  &__text {
    @include fontBold;
    display: inline-block;
    width: auto;
    // margin: 0 10px 0 0;
    margin-left: 0.5em;
  }

  &--hamburger-menu {
    display: inline-flex;
    align-items: flex-end;

    .bra-btnIcon__wrap {
      justify-content: flex-start;
      width: 1em;
      height: 1em;
      position: relative;

      input {
        -webkit-touch-callout: none;
        display: block;
        width: 2em;
        height: 2em;

        opacity: 0; /* hide this */

        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2; /* and place it over the hamburger */
      }

      b {
        display: block;
        width: 1em;
        height: 0.15em;
        border-radius: 1px;
        background: $brand-01;
        margin-bottom: 0.25em;
        position: relative;

        &:nth-last-child(1) {
          width: 0.5em;
          margin-bottom: 0;
        }
      }
    }
  }

  &.hasDropdown {
    &:hover {
      z-index: 2100;
    }
  }
  &.displayNotificationDrawer {
    .dln-drawer {
      display: block;
      visibility: visible;
      z-index: 2000;
    }
  }

  .dln-drawer {
    display: none;
    visibility: hidden;
  }
  &--account {
    position: relative;

    .bra-btnIcon__wrap {
      cursor: pointer;
      position: relative;
      z-index: 10;
    }

    &:before {
      content: ' ';
      display: block;
      width: 34px;
      height: 34px;
      background: rgba(white, 0);
      transform: translate(-50%, 0%);
      position: absolute;
      top: 0;
      left: 50%;
    }

    &.touch {
      &::before {
        content: none;
      }
    }

    &.hover {
      &:before {
        height: 80px;
        transform: translate(-50%, 0%);
        top: 0;
      }

      .dln-drawer {
        display: block;
        visibility: visible;
        // transform: translateX(-50%);
        transform: none;
        position: absolute;
        top: calc(24px + 15px) !important;
        // left: 50%;
        left: auto;
        right: -80px !important;
        // transform: none;
        // position: fixed;
        // left: initial;
        // right: 30px;

        &::after {
          content: none;
        }
      }
    }

    &-input {
      width: 100%;
      height: 100%;
      opacity: 0;

      appearance: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      left: 0;

      z-index: 10;
    }
  }

  &--active {
    .bra-btnIcon__wrap {
      .bra-icn {
        color: $support-02;
      }
      &:hover {
        .bra-icn {
          color: $support-02;
        }
      }
    }
  }
  &--filter {
    @include hover-supported() {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &--reverse {
    flex-direction: row-reverse;
    align-items: center;

    & .bra-btnIcon__text {
      margin-right: 0.5em;
      margin-bottom: 2px;
      margin-left: 0;
    }
  }
}
