.bra-crosslink-box {
    display: flex;
    flex-wrap: wrap;
    // width: 100%;
    width: calc( 100% - 60px);
    max-width: calc(1440px + 60px);
    border-radius: 5px;
    background-color: $brand-01;
    padding: 60px 97px;
    margin: 0 auto;
    box-sizing: border-box;

    &__wrap,
    &__content {
        display: flex;
        flex-wrap: wrap;
        width: calc(50% - 30px);
        box-sizing: border-box;        
    }
    &__wrap {
        margin: 0 60px 0 0;

        & .bra-title {
            display: block;
            width: 100%;
            line-height: 40px;
            font-size: 36px;
            color: $brand-03;
            margin-bottom: 30px;
        }
    }
    &__content {
        // display: flex;
        // flex-wrap: wrap;
        // width: 100%;

        & .bra-paragraph {
            @include font;
            display: block;
            width: 100%;
            line-height: 22px;
            font-size: 15px;
            color: $brand-03;
        }
    }
    & .bra-btn--mobile {
        display: none;
    }
    // &__CTA {
    //     margin-top: 30px;

    //     .bra-btn {
    //         font-size: 15px;
    //         padding: 0 30px;
    //     }
    // }
}