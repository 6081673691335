.bra-product-details {
  padding: 0 30px;
  width: calc(100% - 60px);
  padding-top: 40px;

  &__grid {
    display: flex;
    align-items: flex-start;
    margin: 0 -10px;

    &-5-margin {
      flex-direction: row !important;
      margin: 0 -5px;
    }
  }

  &__col {
    flex: 6 1 55%;
    max-width: 55%;
    padding: 0 10px;
    position: relative;

    &-3 {
      flex: 3;
      padding: 0;
      margin: 0 5px;
    }

    &-6 {
      flex: 6;
      padding: 0;
      margin: 0 5px;
    }

    + .bra-product-details__col {
      flex: 5 1 45%;
      max-width: 45%;
    }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      & .bra-prodGallery {
        & .bra-prodGallery__previewImage {
          height: auto;

          & img {
            max-width: none;
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }

  &__variables {
    margin-bottom: 40px !important;

    & .swiper-wrapper {
      & .swiper-slide {
        max-width: none;
        width: auto;
        margin: 0 10px 0 0;
      }
    }

    &-active {
      .bra-radio {
        &__placeholder {
          border: 1px solid $brand-01;

          &:checked {
            border: 1px solid $brand-01;
          }
        }

        .bra-radio {
          &__label {
            color: $brand-01;

            + .bra-radio__border-hack {
              border: 1px solid $brand-01;
            }
          }
        }
      }
    }
  }

  &__category-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  &__category {
    font-size: 18px;
    line-height: 1.22;
  }

  &__wishlist {
    border-radius: 50%;
    background: $ui-07;
    line-height: 1;

    .bra-btnIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      font-size: 16px;

      .bra-icn {
        color: $ui-09;

        &:hover .bra-icn {
          color: $ui-09;
        }
      }
      &--active {
        .bra-icn {
          color: $support-02;
        }

        &:hover .bra-icn {
          color: $support-02;
        }
      }
    }
  }

  &__badges {
    display: flex;
    margin-bottom: 10px;

    & .bra-badge {
      @include fontBold;
      font-size: 13px;
      height: unset;
      display: flex;
      align-items: center;
      background: $support-02;
      color: $brand-03;
      padding: 0 6px;
      border-radius: 5px;

      + .bra-badge {
        margin-left: 5px;
      }
    }
  }

  &__name {
    @include fontBold;
    font-size: 36px;
    line-height: 1.11;
    margin-bottom: 5px;
  }

  &__code {
    font-size: 14px;
    line-height: 1.57;
    color: $ui-09;
    margin-bottom: 20px;
  }

  &__reviews-link {
    color: $brand-01;
    text-decoration: none;

    &.--underline {
      text-decoration: underline;
    }
  }

  &__star-ratings {
    margin-bottom: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__stock {
    @include fontBold;
    font-size: 15px;
    line-height: 1.6;
    color: $support-02;
  }

  // GRAY BOX
  &__action-box {
    padding: 24px;
    background: $ui-07;
    border-radius: 5px;
    margin-bottom: 20px;

    a + .bra-btn {
      margin-top: 20px;
    }

    .bra-btn {
      flex: 1 1 100%;

      &--notify-me {
        background: $ui-09;
        border-color: $ui-09;
      }
    }
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }
  &__price {
    @include fontBold;
    flex: 1 1 50%;
    font-size: 24px;
    line-height: 1.25;
    margin-bottom: 16px;
  }
  &__price-total {
    @include font;
    font-size: 18px;
    line-height: 1.33;
    color: $ui-09;
    padding: 0 10px 0 20px;
    text-decoration: line-through;
  }
  &__discount {
    font-size: 18px;
    line-height: 1.33;
  }
  &__where {
    @include fontBold;
    display: flex;
    color: $brand-01;
    align-items: center;
    font-size: 15px;
    text-decoration: underline;
    margin-bottom: 20px;
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
      background: none;
    }

    .bra-icn {
      margin-right: 10px;
    }
  }
  // GRAY BOX

  &__infos {
    color: $ui-09;
    display: flex;
    margin-bottom: 40px;
  }

  &__info {
    font-size: 13px;
    flex: 1 1 33.3%;
    display: flex;
    align-items: center;
    line-height: 20px;

    &-br-mobile {
      display: none;
    }

    .bra-icn {
      margin-right: 5px;
      font-size: 20px;
    }
  }

  &__description {
    &-title {
      @include fontBold;
      font-size: 18px;
      line-height: 1.22;
      margin-bottom: 10px;
    }
    &-text {
      font-size: 15px;
      line-height: 1.47;
    }
    p {
      font-size: inherit;
      line-height: inherit;
    }
  }

  &--where-to-buy {
    .bra-product-details__action-box {
      padding: 30px;
    }
  }
}
