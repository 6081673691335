.bra-movingLetters {
  @media screen and (max-width: 520px) {
    max-width: 520px;
  }

  &__container {
    @media screen and (max-width: 520px) {
      height: 60px;
    }
  }

  &__title {
    @media screen and (max-width: 520px) {
      padding-bottom: 5px;
      font-size: 60px;
    }
  }

  &__subtitle {
    @media screen and (max-width: 520px) {
      padding-top: 15px;
      font-size: 18px;
      line-height: 1.33;
    }
  }
}
