.bra-stage {
    &__wrapper {
        display: flex;
        height: 100vh;
        min-height: 720px;
        line-height: normal;
        font-size: 15px;
        overflow: hidden;
        position: relative;
        ///
        z-index: 1;
        ///

        &.centered {
            box-shadow: 0 10px 5px -5px rgba(0, 0, 0, .2);

            .bra-stage__content {
                align-self: center;
                font-size: 3rem;
            }

            .bra-stage__cardWrapper {
                background-color: rgba(0, 0, 0, .7);
            }
        }

        &.countdown {
            .bra-stage__content .bra-stage__card_info__heading .bra-subtitle--countdown {
                @include font;
                color: $brand-03;
            }

            .bra-stage__content .bra-stage__card_info__main .bra-btn {
                @include hover-supported() {
                    &:hover {
                        color: $brand-01;
                        background: $support-02;
                        border-color: $support-02;
                    }
                }

                background: $brand-03;
                color: $brand-01;
                border-color: $brand-03;
            }
        }
    }

    &__image {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1;
        z-index: 2;
        ///

        .bra-image {
            --overflow: 80px;
            min-height: calc(720px + var(--overflow));

            img {
                width: calc(100% + 0px);
                height: calc(100% + var(--overflow));
                object-position: center top;
                top: 0px;
            }
        }
    }

    &__videoBgContainer {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        // z-index: -1;
        z-index: 2;
        ///
    }

    &__videoBg {
        display: block;
        // Commented lines in case a quick revert is needed.
        // width: auto;
        // min-width: 100%;
        width: 100%;
        height: auto;
        // min-height: 100%;
        transform: translate(-50%, -50%);
        position: absolute;
        top: 50%;
        left: 50%;
    }

    &__content {
        display: flex;
        align-self: flex-end;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;
        max-width: calc(1440px + 60px);
        opacity: 0;
        padding: 30px;
        margin: 0 auto;
        box-sizing: border-box;
        ///
        z-index: 3;
        position: relative;
        ///

        &.animating {
            opacity: 1;
        }
        & .bra-stage__cardWrapper {
            display: flex;
            align-self: flex-start;
            width: calc(50% - 20px);
            border-radius: 5px;
            background: rgba(black, 0.85);
            transform-origin: 0 0;
            margin-bottom: 66px;

            .bra-stage__card {
                display: flex;
                flex-direction: column;
                width: 100%;
                margin: 30px;
                overflow: hidden;
            }
        }

        .bra-stage__card_info {
            &__heading {
                margin-bottom: 15px;

                .bra-stage__badgesList {
                    display: inline-block;
                    width: auto;
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    margin-right: 10px;

                    .bra-stage__badge {
                        @include fontBold;
                        display: inline-block;
                        line-height: 1;
                        font-size: 15px;
                        border-radius: 5px;
                        padding: 5px 10px;
                    }

                    .bra-stage__badge+.bra-stage__badge {
                        margin-left: 10px;
                    }
                }

                .bra-subtitle {
                    display: inline-block;
                    width: auto;
                    line-height: 20px;
                    font-size: 18px;
                }
            }

            &__main {
                display: flex;
                justify-content: space-between;

                .bra-stage__card_info__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    margin-right: 20px;
                }

                .bra-title {
                    line-height: 40px;
                    font-size: 36px;
                    margin-bottom: 20px;
                }

                .bra-paragraph {
                    line-height: 22px;
                    font-size: 18px;
                }

                .bra-btn {
                    margin-top: auto;
                }

                .bra-stage__card_visual {
                    width: 180px;
                    min-width: 180px;
                    max-width: 180px;
                    min-height: 210px;

                    margin-top: auto;
                    margin-bottom: auto;

                    .bra-image {
                        height: auto;
                        background: transparent;
                    }
                }
            }
            &__content-wrapper {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
            }
        }

        .bra-stage__chevron {
            transform: translateX(-50%);
            position: absolute;
            bottom: 30px;
            left: 50%;
            z-index: 10;

            .bra-btnIcon {
                --txtColor: white;
                font-size: 24px;
            }
        }

        .bra-stage__discalimerWrapper {
            max-width: 35%;

            .bra-stage__discalimer {
                line-height: 12px;
                font-size: 9px;
                color: #{$brand-01};
            }
        }
    }

    &__countdown {
        display: flex;
        margin-bottom: 30px;

        &-label {
            font-size: 13px;
            line-height: normal;
            margin-top: 10px;
            text-align: center;
        }

        &-days,
        &-hours,
        &-minutes,
        &-seconds {
            @include fontBold;
            font-size: 60px;
            line-height: normal;
            z-index: 1;
        }

        &-wrap {
            + .bra-stage__countdown-wrap {
                margin-left: 5px;
            }
        }
        &-box {
            position: relative;
            height: 90px;
            width: 90px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #1f1f1f;

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                width: 100%;
                height: 1px;
                transform: translateY(-50%);
                background: $brand-01;
                z-index: 0;
            }
        }
    }
    &__wrapper {
        @each $key,
        $val in $colors-list {
            &.bra-theme-color--#{$key} {
                & .bra-stage__card {
                    & .bra-stage__badgesList {
                        & .bra-stage__badge {
                            background-color: #{$val};
                        }
                    }
                    & .bra-subtitle {
                        color: #{$val};
                    }
                    & .bra-stage__card_info__main {
                        & .bra-stage__card_info__content {
                            & .bra-btn {
                                border-color: #{$val};
                                background-color: #{$val};

                                &:hover {
                                    color: #{$val};
                                    border-color: $brand-03;
                                    background-color: $brand-03;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &__breadcrumbs {
        width: 100%;
        position: absolute;
        z-index: 3;
        padding: 100px 30px 0;

        .wrapper {
            margin: 0;

            @media only screen and (min-width: $braun-s) and (max-width:1440px) {
                padding: 0;
            }
        }

        &.bra-stage__breadcrumbs--text-white {
            .bra-breadcrumbs__link {
                color: $brand-03;

                @include hover-supported() {
                    &:hover {
                        color: $support-02;
                        text-decoration: underline;
                        &::before {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        &.bra-stage__breadcrumbs-text-black {
            .bra-breadcrumbs__link {
                color: $brand-01;

                @include hover-supported() {
                    &:hover {
                        color: $support-02;
                        text-decoration: underline;
                        &::before {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .bra-breadcrumbs-header {
            border-bottom: 0;
        }
    }
}

.bra-stage {
    &__content {
        color: $brand-03;

        .bra-stage__card_info {
            &__heading {
                .bra-stage__badgesList {
                    .bra-stage__badge {
                        // color: $bra-lochmara;
                        background: $brand-03;
                    }
                }

                .bra-subtitle {
                    // color: $bra-lochmara;
                }
            }

            &__main {

                .bra-title,
                .bra-paragraph {
                    color: $brand-03;
                }

                .bra-btn {
                    @include hover-supported() {
                        &:hover {
                            // color: $bra-lochmara;
                            border-color: $brand-03;
                            background: $brand-03;
                        }
                    }

                    // border-color: $bra-lochmara;
                    // background: $bra-lochmara;
                }
            }
        }
    }

    &__wrapper {

        // @each $key,
        // $val in $category-colors {
        //     &.bra-#{$key} {
        //         .bra-stage__content {
        //             .bra-stage__card_info {
        //                 &__heading {
        //                     .bra-stage__badgesList {
        //                         .bra-stage__badge {
        //                             color: #{$val};
        //                         }
        //                     }

        //                     .bra-subtitle {
        //                         color: #{$val};
        //                     }
        //                 }

        //                 &__main {
        //                     .bra-btn {
        //                         @include hover-supported() {
        //                             &:hover {
        //                                 color: #{$val};
        //                             }
        //                         }

        //                         border-color: #{$val};
        //                         background: #{$val};
        //                     }
        //                 }
        //             }
        //         }
        //     }
        // }
    }
}
