@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-prodTile {
        &__imgWrap {
            height: 166px;
        }
        &__foot {
            flex-direction: column;
            height: auto;

            & .bra-prodTile__rating {
                margin: 0 0 20px 0;
            }
            & .bra-input--checkbox {
                margin: 0 auto;
            }
        }
        &__badges {
            .bra-badge {
                height: unset;
                margin-bottom: 5px;
            }
        }
    }
}
