.bra-footer {
	max-width: calc(1440px + 60px);
	line-height: normal;
	font-size: 15px;
	margin: 0 auto;

	.bra-footer-wrapper {
		padding: 60px 0px;
		margin: 0 30px;
		&--half-padding {
			padding-top: 30px;
			padding-bottom: 30px;
		}
	}
	.bra-footer-wrapper + .bra-footer-wrapper,
	.bra-footer-wrapper--slim {
		border-top: 1px solid $ui-08;
	}
	.bra-footer-wrapper--slim {
		padding: 47px 0 32px;
	}

	&__header {
		.bra-logo__wrapper {
			display: flex;
			font-size: 118px;
			margin: 0 auto;
		}
	}
	&__sitemap {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.bra-list-link {
			&__list-wrapper {
				display: flex;
				width: calc( 25% - 20px );
			}
		}
	}
	&__social {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		#social_wrapper {
			display: flex;
			list-style: none;
			padding: 0;
			margin: 0;

			.social_item {
				.social_link {
					display: inline-block;
					width: 40px;
					height: 40px;
					line-height: 46px;
					font-size: 20px;
					font-weight: bold;
					color: $ui-07;
					text-align: center;
					border-radius: 50%;
					background: $brand-01;
					position: relative;

					.bra-icn {

					}
				}
			}
			.social_item + .social_item {
				margin-left: 20px;
			}
		}

	}
	&__country {
		display: inline-flex;
		align-items: center;
		width: auto;
		height: 40px;
        margin: 0 auto 0 0;
        cursor: pointer;
		border: none;
		background: none;

		&Code {
			display: block;
			width: 40px;
			height: 40px;
			line-height: 40px;
            font-size: 13px;
			color: $brand-03;
			text-align: center;
			border-radius: 50%;
			background: $brand-01;
            // transition: background-color 0.2s linear, color 0.2s linear;
			transition: background .2s ease-in-out, color .2s ease-in-out;
			margin: 0 10px 0 0;
		}
		&Name {
			@include fontBold;
			display: inline-block;
			width: auto;
			height: 40px;
			line-height: 40px;
			font-size: 13px;
            color: $ui-09;            
            transition: background .2s ease-in-out, color .2s ease-in-out;
        }
        &:hover {
            & .bra-footer__countryCode {
                color: $brand-01;
                background-color: $brand-03;
            }
            & .bra-footer__countryName {
				color: $brand-01;
			}
        }
	}
	&__legal {
		line-height: 19px;
		font-size: 13px;
		text-align: center;

		.legalText {
			display: block;
			margin-bottom: 20px;
		}

		.list {
			&__list-wrapper {
				display: inline-block;
				width: auto;
				line-height: 19px;
				font-size: 13px;
				margin: 0 20px;

				.list__item {
					.list-link__link {
						@include fontBold;

						@include hover-supported() {
							&:hover {
								text-decoration: underline;
							}
						}
						display: block;
						color: $ui-09;
						text-decoration: none;
						padding-right: 10px;

						padding-left: 10px;
						margin-bottom: 10px;
					}
				}
			}

			&__item {
				display: inline-block;
			}
		}
	}
}