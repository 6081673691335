@media only screen and (max-width: $braun-s - 1) {
    .dln-drawer {
        top: calc(100% + 20px);
        font-size: 13px;

	    .utilityLinks {
	        font-size: 13px;
	        a {
	        	font-size: 13px;
	        }
	    }

	    .user {
	        font-size: 13px;

	        a,
	        a.bra-btn--rounded {
	        	font-size: 13px;
	        }
	    }
	}
}