@media only screen and (max-width: $braun-s - 1) {
    .bra-content-slider {
		.swiper-container.content-slider {
			.swiper-slide {
				max-width: calc(100vw - 60px);

				.bra-sideCard {
					&__textContent {
						padding: 30px 20px;

						.bra-sideCard__title {
							font-size: 18px;
							line-height: 22px;
						}
						.bra-sideCard__description {
							font-size: 13px;
							line-height: 19px;
						}
					}
				}
			}
		}
	}

	.bra-content-slider--primary {
		.swiper-container.content-slider {
			.swiper-slide {
				display: block;
				max-width: calc(100vw - 40px)
			}
		}
	}

	.bra-content-slider.bra-content-slider--secondary {
		scroll-margin-top: 75px;
		.swiper-container.content-slider {
			padding: 0 20px 0 20px !important;

			.swiper-slide {
				display: block;
				max-width: none;
				width: 100% !important;

				.bra-card__content {
					padding: 20px;
					padding-bottom: 20px;

					.bra-title {
						margin-bottom: 12px;
					}
					.bra-paragraph {
						font-size: 13px;
						line-height: 19px;
					}
				}
				.bra-card__actions {
					padding: 20px;
					padding-top: 0;
					padding-bottom: 30px;
				}
			}
		}
		& .swiper-footer {
			width: 100%;
		}
	}
}
