.bra-asideNavigation {
	font-size: 15px;
	line-height: normal;

	display: block;
	margin-bottom: 30px;

	.bra-list-link__title,
	.bra-asideNavigation--accordion__filterTitle {
		background-color: $brand-01;
		color: $brand-03;
		width: auto;

		@include font;

		font-size: 26px;
		line-height: 32px;
		padding: 20px 20px 15px;
		margin: 0;

		display: flex;
		justify-content: space-between;

		span.bra-asideNavigation--accordion__filterTotal {
			font-size: 17px;
			line-height:32px
		}
	}
	.bra-list-link__list,
	.bra-asideNavigation--accordion__content {
		border: 1px solid $ui-01;
		border-bottom-width: 2px;
		border-top: 0;

		.bra-list-link__item {
			width: auto;
			padding: 0 20px;

			.bra-list-link__link {
				padding: 20px 0;

				@include fontBold;
				text-decoration: none;

				@include hover-supported() {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
		.bra-list-link__item + .bra-list-link__item {
			margin: 0;
			border-top: 1px solid $ui-01;
		}
	}

	&--accordion {
		border-bottom: 1px solid $ui-01;
	}
	&--accordion__list {
		transition: max-height 0.5s ease-in-out;
		will-change: max-height;
		box-sizing: content-box;
		overflow: hidden;

		.bra-asideNavigation--accordion__header {
			padding: 20px;
			border: 1px solid $ui-01;
			@include fontBold;
			font-size: 17px;
			line-height: 24px;

			display: flex;
			justify-content: space-between;

			&::after {
				content: '';
				font-family: $fontNameIcons__legacy;
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				width: 1em;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				-webkit-font-smoothing: antialiased;
			}
		}
		.bra-asideNavigation--accordion__content {
			border-top: 0;
			border-bottom: 0;
			padding: 10px 20px;
		}

		&.bra-accordion--open {
			.bra-asideNavigation--accordion__header {
				&::after {
					content: '';
				}
			}
		}
	}
}