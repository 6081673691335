@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-card {
		--padding: 20px;

		&__content {
			padding: 20px;
			padding-bottom: 15px;

			.bra-subtitle {
				font-size: 15px;
				line-height: 22px;
				margin-bottom: 5px;
			}
			.bra-title {
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 0;
			}
			.bra-paragraph {
				font-size: 15px;
				line-height: 22px;
			}
		}
		&__actions {
			padding: 20px;
		}
		&--category {
			.bra-card__content {
                padding: 30px 20px;

                .bra-title--category {
					font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 5px;
				}

				.bra-paragraph--category {
                    // font-size: 15px;
                    font-size: 13px;
					line-height: 22px;
				}
			}

			.bra-card__actions {
				padding: 20px;
			}
		}

		&--bg-image {
			.bra-card__content {
				padding-bottom: 20px;
            }
            & .bra-card__actions {
                padding: 0 20px 20px 20px;
            }
		}

		&--product {
			.bra-card__content {
				padding: 25px 20px 20px 20px;
			}
			.bra-card__actions {
				padding: 20px;
				padding-top: 30px;
			}
			.bra-card__visual {
				.bra-image {
					max-width: 200px;
				}
			}
		}

		&--recipe {
			.bra-card__visual {
				.bra-image {
					img {
						transform: scale3d(1.05, 1.05, 1.05);
						transition: transform .8s ease-in-out;
					}
				}
			}
			.bra-card__content {
				opacity: 1;
				transform: translateY(-54px);
				transition: transform .6s ease-in-out;
			}
			.bra-card__actions {
				transform: translateY(0);
				transition: transform .6s ease-in-out;

				.bra-btn {
					opacity: 1;
					transition: opacity .6s ease-in-out;
					transition-delay: .2s;
				}

			}
		}

		&--horizontal {
            .bra-card__visual {
				object-fit: cover;
				.bra-image {
					transform: none;
					height: auto;
					padding-bottom: 0;
					object-fit: cover;
				}
            }

			.bra-card__content {
				.bra-subtitle {
					font-size: 15px;
					line-height: 22px;
					margin-bottom: 5px;
				}
				.bra-title {
					font-size: 18px;
					line-height: 22px;
					margin-bottom: 0;
				}
				.bra-paragraph {
					font-size: 15px;
					line-height: 22px;
				}
			}
		}

		&--box {
			.bra-card {
				&__content {
					.bra-title {
						font-size: 13px;
					}
				}
            }
            & .bra-card__visual {
                & .bra-image {
                    max-width: 162px;
                    height: 216px;
                }
            }
        }
		&--highlights {
			.bra-card {
				&__content {
					.bra-title {
						margin-bottom: 5px;
						font-size: 15px;
						line-height: 1.47;
					}

					.bra-paragraph {
						font-size: 13px;
  						line-height: 1.46;
					}
				}
			}
		}
	}
}