.bra-subHead {
    @include flex(wrap);
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 20px 0 0 0;
    margin: 0 0 30px 0;

    & .bra-subHeadline__wrapper {
        display: inline-block;
        width: auto;
        max-width: 50%;
        padding: 0;
        margin: 0 auto 0 0;

        & .bra-subHeadline {  
            line-height: 40px;
            font-size: 36px;  
        }
    }
    & .bra-subHead__text {
        display: inline-block;
        width: auto;
        line-height: 32px;  
        font-size: 18px;
        padding: 8px 0 0 0;
    }
}