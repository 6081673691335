.bra-stage-image {
  &__wrapper {
    position: relative;

    @each $key, $val in $colors-list {
      &.bra-theme-color--#{$key} {
        a {
          border-color: #{$val};
          background-color: #{$val};

          &:hover {
            color: #{$val};
            border-color: $brand-03;
            background-color: $brand-03;
          }
        }
      }
    }
  }

  &__text {
    position: absolute;
    color: $brand-03;
    bottom: 0;
    padding: 0 26px 30px 20px;

    @media (min-width: $braun-s) {
      padding: 0 365px 60px 40px;
    }

    @media (min-width: $braun-m) {
      padding: 0 491px 40px 40px;
    }

    @media (min-width: $braun-xl) {
      padding: 0 852px 60px 40px;
    }

    &--title {
      font-size: 30px;
      line-height: 1.13;
      margin-bottom: 10px;

      @media (min-width: $braun-s) {
        font-size: 36px;
        line-height: 1.11;
        margin-bottom: 15px;
      }

      @media (min-width: $braun-xl) {
        font-size: 48px;
        line-height: 1.13;
      }
    }

    &--subtitle {
      font-size: 13px;
      line-height: 1.46;

      &.--has-link {
        margin-bottom: 20px;
      }

      @media (min-width: $braun-s) {
        font-size: 16px;
        line-height: 1.5;
      }
    }
  }
}
