.bra-richlink {
	position: relative;
	display: flex;
	flex-direction: column;
	flex-basis: 0;
	flex-grow: 1;
	height: 100%;
	text-align: center;
	text-decoration: none;

	font-size: 15px;
	line-height: normal;

	* {
		display: block;
		text-align: center;
		margin: 0 auto;
		color: $brand-01;
		transition: color .3s ease-in-out;
	}

	.bra-richlink--link {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.bra-icn {
		font-size: 50px;
		color: $support-02;
		width: 1em;
		height: 1em;
		&::before {
			margin:0;
		}
	}
	.bra-subtitle {
		font-size: 26px;
		line-height: 32px;
		margin: 30px 0 22px 0;
	}
	.bra-text {
		@include fontBold;
		font-size: 17px;
		line-height: 24px;
		margin-top: auto;
		margin-bottom: 0;
		text-decoration: underline;
	}

	@include hover-supported() {
		&:hover {
			* {
				color: $support-02;
			}
		}
	}
}