@media only screen and (max-width: $braun-s - 1) {
	.bra-productComparison {
		&__table {
			width: calc(100vw - 40px);
			overflow-x: scroll;

			.bra-productComparison__containerClonedHeader {
				display: none !important;
			}
		}
		&__row {
			&--header {
				.bra-productComparison__column--product {
					.bra-productComparison__cell {
						.bra-image {
							min-height: initial;
							margin: 20px 0;
						}
					}
				}
			}
		}
	}
}