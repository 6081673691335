@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-productComparison {
		&__row {
			&--header {
				.bra-productComparison__column--product {
					.bra-productComparison__cell {
						.bra-image {
							min-height: initial;
							margin: 30px 0;
						}
					}
				}
			}
		}
	}
}
