@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-content-slider {
		.swiper-container.content-slider {
			.swiper-slide {
				// max-width: calc(100vw - 60px);

				.bra-sideCard {
					&__textContent {
						padding: 40px;

						.bra-sideCard__title {
							line-height: 30px;
							font-size: 24px;
						}
						.bra-sideCard__description {
							line-height: 19px;
							font-size: 13px;
						}
					}
				}
			}
		}
	}

	.bra-content-slider.bra-content-slider--primary {
		.swiper-container.content-slider {
			.swiper-slide {
				.bra-sideCard__image {
					width: 50%;
					// display: block;
					// min-height: initial;
				}
				.bra-sideCard__textContent {
					width: 50%;
					//display: block;
				}
			}
		}
	}

	.bra-content-slider.bra-content-slider--secondary {		
		scroll-margin-top: 95px;
		.swiper-container.content-slider {
			padding: 0 30px !important;

			.swiper-slide {
				width: calc(50% - 10px) !important;

				&:last-child {
					margin-right: 0 !important;
				}
				.bra-card__content {
					padding: 20px;
					padding-bottom: 40px;

					.bra-title {
						margin-bottom: 12px;
					}
					.bra-paragraph {
						line-height: 19px;
						font-size: 13px;
					}
				}
				.bra-card__actions {
					padding: 20px;
					padding-top: 0;
					padding-bottom: 30px;
				}
			}
		}
		& .swiper-footer {
			width: 100%;
		}
	}
}
