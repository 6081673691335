.bra-loadMore {
    @include flex(wrap);
    align-content: flex-start;
    justify-content: center;
    width: 100%;
    padding: 30px 0 0 0;

    &__text {
        display: block;
        width: 100%;
        line-height: 19px;
        font-size: 18px;
        text-align: center;
        margin: 0 0 30px 0;
    }
    & .bra-btn {
        width: 230px;
        margin: 0 auto;
    }
}