.bra-compare-product {
    width: 100%;

    &__wrapper,
    &__table {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    &__wrapper {
        align-items: stretch;
        border-radius: 5px;
        overflow: hidden;
    }
    &__table {
        flex: 2;
        border-bottom: 1px solid $ui-08;
        margin: 0 10px;
    }

    & .page_margin {
        padding-left: 30px;
        padding-right: 30px;
    }

    & .bra-card {
        &--box {
            .bra-card__visual {
            @media only screen and (min-width: $braun-l) and (max-width: $braun-xl){
                & .bra-image {
                    max-width: 200px; 
                    height: 220px;
                    img {
                        object-fit: unset;
                    }
                }
            }
            @media only screen and (min-width: $braun-xl) {
                    & .bra-image {
                        max-width: 265px; 
                        height: 280px;

                        img {
                            object-fit: unset;
                        }
                    }
                }
            }
        }
    }
    &__selected,
    &__slides {
        flex: 1;
        border-radius: 5px;
        background: $ui-07;
        margin: 0 10px !important;
        

        & .bra-card {
            &__visual {
                width: 100%;
                padding: 50px 35px 20px;
                box-sizing: border-box;
            }
       
            &__content {
                padding: 0 20px 15px 20px;
            }
        }
    }
    &__col {
        flex: 1 1 33.33%;
        display: flex;
        align-items: stretch;
        min-height: 60px;
    }
    .swiper-footer {
        width: 100% !important;
        margin: 0 !important;
        position: absolute;
        top: calc(50% - 18.5px);
        left: 0;

        .swiper-button-prev,
        .swiper-button-next {
            font-size: 15px;
            padding: 10px;
            position: absolute;
            top: 50%;
            left: auto;
            right: auto;
        }
        .swiper-button-prev {
            left: 0;
        }
        .swiper-button-next {
            right: 0;
        }
    }
}