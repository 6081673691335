@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
}

@media only screen and (max-width: $braun-m - 1) {
	.bra-richlink {
		.bra-icn {
			font-size: 50px;
		}
		.bra-subtitle {
			font-size: 21px;
			line-height: 26px;
			margin: 30px 0 45px 0;
		}
		.bra-text {
			font-size: 15px;
			line-height: 21px;
		}
	}
}