.bra-pageHeader__wrapper {
	display: flex;
	align-items: baseline;

	width: 100%;
	padding-bottom: 11px;
	margin-bottom: 22px;

	border-bottom: 1px solid $ui-01;

	font-size: 15px;
	line-height: normal;

	.bra-title {
		display: flex;
		width: auto;
		@include font;
		font-size: 44px;
		line-height: 55px;
	}
	.bra-link {
		margin-left: auto;
	}

	&.bra-pageHeader--category-header {
		.bra-title {
			font-size: 26px;
			line-height: 32px;
		}
		.bra-link {
			margin-left: auto;
			font-size: 15px;
			line-height: 21px;
		}
	}
}