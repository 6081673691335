.bra-category-shelf {
  position: relative;
  &__filter-wrapper {
    overflow: hidden;
    margin: 60px auto;
    max-width: 1500px;
    padding: 0 0 0 20px;

    @media screen and (min-width: $braun-l) {
      padding: 0 30px;
    }
  }

  .swiper {
    opacity: 0;
    overflow: hidden;
    padding-right: 2px;
    position: relative;
    .swiper-wrapper {
      position: relative;
      .swiper-slide {
        border-top: 1px solid $ui-02;
        border-bottom: 1px solid $ui-02;

        &:nth-child(2) {
          border-left: 1px solid $ui-02;
          border-top-left-radius: 30px;
          border-bottom-left-radius: 30px;
        }

        &:last-child {
          border-right: 1px solid $ui-02;
          border-top-right-radius: 30px;
          border-bottom-right-radius: 30px;
        }
      }
    }
  }

  .swiper-slide {
    cursor: pointer;
    @include font;
    font-size: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 39.3%;
    height: 62px;
    @media screen and (min-width: $braun-l) {
      // width: 25%;
    }

    @media screen and (min-width: $braun-xl) {
      font-size: 18px;
      // width: 20%;
    }

    &.--active {
      position: relative;
      color: $brand-03;
    }
  }

  .filter-selector {
    position: absolute;
    z-index: -1;
    height: 62px;
    background-color: $brand-01;
    border-radius: 30px;
  }
}
