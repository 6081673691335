.bra-multi-accordion {
    border-top: 1px solid $ui-08;
    border-bottom: 1px solid $ui-08;

    &__item {
        list-style: none;

        .bra-multi-accordion__expander.active {
            .bra-multi-accordion__plusIcon::after {
                transform: translateX(-50%) rotate(90deg);
            }

            + .bra-multi-accordion__content {
                max-height: 250px;
            }
        }

        + .bra-multi-accordion__item .bra-multi-accordion__expander {
            border-top: 1px solid $ui-08;
        }
    }

    &__expander {
        margin: 0;
        width: 100%;
        border: none;
        background: none;
        padding: 30px 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;   
    }

    &__plusIcon {
        height: 20px;
        width: 20px;
        min-width: 20px;
        position: relative;
        pointer-events: none;

        &::before,
        &::after {
            content: ' ';
            position: absolute;
            display: block;
            background: black;
            border-radius: 2px;
        }

        &::before {
            width: 20px;
            height: 3px;
            top: 50%;
            transform: translateY(-50%);
        }
        &::after {
            height: 20px;
            width: 3px;
            left: 50%;
            transform: translateX(-50%);
            transition: transform 0.5s ease;
        }
    }

    &__content {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ease;
    }

    &__title {
        @include fontBold;
        font-size: 18px;
        line-height: 1.33;
        pointer-events: none;
    }

    &__description {
        font-size: 15px;
        line-height: 1.47;
        padding-bottom: 30px;
    }

    &__list-wrap {
        padding-bottom: 30px;
    }

    &__list-item {
        padding: 20px 15px;
        font-size: 15px;
        border-radius: 5px;

        &:nth-child(odd) {
            background: $ui-07;
        }
    }

    &__list-label {
        @include fontBold;
    }


    &--specifications {
        .bra-multi-accordion__item .bra-multi-accordion__expander.active {
            + .bra-multi-accordion__content {
                max-height: 500px;
            }
        }
    }

    &--gray {
        .bra-multi-accordion__item {
            background: $ui-07;
        }
    }

    &--terms-and-conditions {
        width: calc(100% - 60px);
        margin: 0 auto;
        border: none;
        border-radius: 5px;
        overflow: hidden;

        .bra-multi-accordion {
            &__item {
                border: none;
                padding-left: 20px;
                padding-right: 20px;

                .bra-multi-accordion__expander.active {
                    + .bra-multi-accordion__content {
                        max-height: 4000px;
                        padding-bottom: 20px
                    }

                    .bra-multi-accordion__title {
                        font-size: 24px;
                        padding-top: 30px;
                        padding-left: 76px;
                        padding-right: 76px;
                    }
                    .bra-multi-accordion__subTitle {
                        display: none;
                    }

                }
            }

            &__expander {
                align-items: flex-start;
            }

            &__title {
                font-size: 18px;
                line-height: 0.92;
            }

            &__subTitle {
                @include font;
                font-size: 15px;
                line-height: 1.47;
                padding-left: 20px;
                a {
                    @include fontBold;
                    color: black;
                }
            }

            &__content {
                padding-left: 76px;
                padding-right: 76px;
                ;
            }

            &__description {
                font-size: 15px;
                line-height: 1.47;
                padding-bottom: 60px;

                b {
                    display: block;
                    margin-top: 30px;
                }
            }
        }
    }

    &--centered {
        &.bra-multi-accordion--promo {
            .bra-multi-accordion__title {
               margin: 0 auto;
            }
        }
    }


    &--promo {
        border: none;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;

        .bra-multi-accordion {
            &__content {
                display: flex;
                width: 70%;
                margin: 0 auto;
                align-items: center;
                overflow: hidden;
                padding-left: 76px;
                padding-right: 76px;
                top: -42px;
                position: relative;
                opacity: 0;
            }
            &__item {
                border: none;
                padding-left: 20px;
                padding-right: 20px;

                .bra-multi-accordion__expander.active {
                    + .bra-multi-accordion__content {
                        // max-height: 134px;
                        overflow: visible;
                        opacity: 1;
                    }

                    .bra-multi-accordion__title {
                        opacity: 0;
                        pointer-events: none;
                    }
                }
            }

            &__expander {
                align-items: flex-start;
                padding: 30px 0;
                display: flex;
                justify-content: space-between;

                .bra-multi-accordion__title {
                    text-align: center;
                }
            }

            &__title {
                width: calc(100% - 30px);
                font-size: 15px;
                line-height: 19px; 
            }
            

            &__description-wrap {
                padding-left: 20px;

                .bra-multi-accordion__title {
                    font-size: 24px;
                    line-height: 0.92;
                    margin: 0 0 10px;
                }
            }
            &__image {
                width: 174px;
                min-width: 174px;
                height: 174px;
                overflow: hidden;

                & .bra-image__image {
                    object-position: center;
                }
            }

            &__title {
                font-size: 18px;
                line-height: 0.92;
            }

            &__subTitle {
                @include font;
                font-size: 15px;
                line-height: 1.47;
                padding-left: 20px;

                &-underline {
                    @include fontBold;
                    padding-left: 30px;
                    text-decoration: underline;
                }

                a {
                    @include fontBold;
                    color: black;
                }
            }

            &__description {
                font-size: 15px;
                line-height: 1.47;
                padding-bottom: 20px;

                b {
                    display: block;
                    margin-top: 30px;
                }
            }
        }
    }
}
