@media only screen and (max-width: $braun-s - 1) {
    .bra-stories {
        &__container {
            padding: 0 20px;            
        }
		&__wrapper {        
            width: 100%;
			// margin: 0 20px;
			padding-top: 150%;
		}
		&__screen {
            height: 100%;
            
			.bra-subtitle {
				font-size: 18px;
				line-height: 24px;
			}
		}
	}

	.swiper-container.stories-slider {
		.swiper-slide {
			&.topAligned {
				align-items: flex-end;
			}
			.bra-stories__slide--content {
				font-size: 13px;
				line-height: 19px;

				left: 20px;

				margin: 50px auto;
				max-width: calc( 100% - 40px );

				opacity: .85;
			}
			.bra-stories__slide--visual {
				.mobileVersion {
					display: block;
					width: 100%;
					height: 100%;
				}
				.desktopVersion {
					display: none
				}
			}
		}
		.swiper-footer {
			.swiper-button-next,
			.swiper-button-prev {
				display: none
			}
		}
		.swiper-pagination {
			bottom: 20px;
			width: calc(100% - 40px);
		}
		.swiper-button-close {
			padding: 20px;
		}
	}
}
