.bra-movingLetters {
  @media screen and (max-width: $braun-s) {
    max-width: 628px;
  }

  &__container {
    @media screen and (max-width: $braun-s) {
      height: 96px;
    }
  }

  &__title {
    @media screen and (max-width: $braun-s) {
      font-size: 96px;
    }
  }

  &__subtitle {
    @media screen and (max-width: $braun-s) {
      padding-top: 10px;
      line-height: 1.25;
    }
  }
}
