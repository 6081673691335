.bra-product-details {

  &__where {
    align-self: flex-start;
  }

  &__vat-included {
    @include font;
    display: block;
    font-size: 14px;
    line-height: 2.14;
    letter-spacing: normal;
    color: var(--ui-06);
  }
}
