@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-stage {
		&__content {
			.bra-stage__cardWrapper {
				width: 100%;
				margin-bottom: 24px;
			}

			.bra-stage__card_info {
				&__main {
					.bra-paragraph {
						font-size: 18px;
						line-height: 22px;
					}
					.bra-btn {
						font-size: 15px;
					}
				}
			}
		}
		
		&__wrapper {
			&.countdown {
				.bra-stage__card_info__content-wrapper {
					width: 100%;
					display: flex;
					justify-content: space-between;
				}
				.bra-title--countdown {
					width: 254px;
					font-size: 30px;
				}
			}
		}

		&__countdown {
			&-days,
			&-hours,
			&-minutes,
			&-seconds {
				font-size: 48px;
			}
	
			&-box {
				width: 75px;
			}
		}

		&__breadcrumbs {
			padding: 100px 30px 0;
		}
	}
}
