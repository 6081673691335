@media only screen and (max-width: $braun-s - 1) {
  .bra-breadcrumbs {

    &__link {
      display: none;
      &:before {
        content: '‹';
      }
    }

    a:last-of-type {
      display: inline-block;
    }

    &__pdp {
        width: calc(100% - 40px);
        padding: 0 20px;
    }
  }
}
