@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-campaign-steps {
        &__title {
            font-size: 30px;
            line-height: 1.13;
        }

        &__wrap-list {
            margin-bottom: 40px;
        }

        &__item-title {
            font-size: 15px;
            line-height: 1.27;
        }
    
        &__item-description {
            font-size: 13px;
            line-height: 1.46;
            padding-top: 5px;
        }
    }
}
