@media only screen and (max-width: $braun-s - 1) {	
	.bra-image-slider {
        width: calc(100% - 40px);
        margin: 0 20px;
        
		.bra-sideCard__image {
			min-height: initial
		}
		.bra-sideCard__textContent {
			min-height: initial;
			padding: 20px;
			padding-bottom: 60px;
		}
	}
}