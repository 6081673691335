@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-crosslink-box {
        width: calc(100% - 60px);
		margin: 0 30px;
        padding: 60px;

        &__wrap,
        &__content {
            width: calc(50% - 20px);
        }
        &__wrap {
            margin: 0 40px 0 0;
        }

        &__inner {
            width: 100%;
            margin: 0 60px;
        }

        .bra-title {
            font-size: 30px;
            line-height: 34px;
        }
        .bra-paragraph {
            font-size: 13px;
            line-height: 19px;
        }

        &__CTA {
            margin-top: 30px;

            .bra-btn {
                font-size: 13px;
                padding: 0 25px;
            }
        }
        &__content {
            & .bra-paragraph {
                line-height: 19px;
                font-size: 13px;
            }      
        }
    } 
}
