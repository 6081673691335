@media only screen and (max-width: $braun-s - 1) {
	.bra-textbox {
        margin: 0 20px;

        .bra-title {
			font-size: 24px;
		}

		.bra-paragraph {
			font-size: 13px;
		}

		&--two-columns {
			.bra-textbox__wrapper {
				.bra-paragraph {
					column-count: 1;
					text-align: center;
				}
			}
		}
	}
}
