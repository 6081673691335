.bra-navigation {

	-ms-overflow-style: none;  // IE 10+
	scrollbar-width: none;  // Firefox
	-webkit-overflow-scrolling: touch;
	height: 0;
	line-height: normal;
	font-size: 15px;

	background: $brand-03;

	transition: background .3s ease-in-out,
				height .3s ease-in-out;
	overflow-y: hidden;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 90;
	&::-webkit-scrollbar {
		display: none;  // Safari and Chrome
    }

	#bra-navigation__list-spacer {
		flex: none;
		display: block;
	}
	#bra-navigation__list-spacer--fixed {
		width: 100%;
		background: $brand-03;
		opacity: 0;

		transition: background .3s ease-in-out;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 600;
	}

	&__wrapper {
		//
		display: flex;
		flex-direction: row;
		width: calc(100% - 60px);
		height: 100%;
		padding: 0;
		padding-left: 30px;
		padding-left: 0;
		//justify-content: space-between;

		//max-width: 1440px;
		margin: 0 auto;
		position: relative;
	}

	&__list {
		list-style: none;
		margin-bottom: 40px !important;

		&-container {
			display: flex;
			flex-direction: column;
			width: 40%;
			opacity: 0;
			transition: width 0.4s ease-in-out;
		}

		.bra-navigation__categoryWrapper {
			flex: 0;
			display: none;
			flex-direction: row;
			flex-wrap: wrap;
            width: 100%;
            margin-bottom: 0 !important;
			//overflow: hidden;

			list-style: none;
            padding: 0;

            &.active {
                margin-bottom: 40px !important;
            }

			// transition: margin-bottom .4s linear;

			// &.active {
			// 	margin-bottom: 60px;

			// 	transition: margin-bottom .4s linear;
			// }

			.bra-navigation__category-item {
				width: calc(33.33333% - 14px);

				&:nth-child(3n+2) {
					margin-right: 20px;
					margin-left: 20px;
				}

				.mobileOnly {
					@include fontBold;
					display: none;
					font-size: 18px;
					text-decoration: none;
					color: $brand-01;
				}

				.bra-card__actions,
				.bra-card__content {
					padding: 20px;
				}

				.bra-image {
					transform: none;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}

		&-title {

			@include hover-supported() {
				clear: both;
			}
			display: block;
			font-size: 15px;
			font-weight: normal;
			color: $support-02;
			margin: 0;
			margin-bottom: 25px;
		}

		&-item {

			transition: opacity .4s ease-in-out;
			float: left;
			clear: left;

			label {

				@include fontBold;
				display: inline-block;
				font-size: 30px;
				padding-bottom: 15px;
				cursor: pointer;
			}
			input {
				display: none;
			}


		}

		li.active + .bra-navigation__categoryWrapper {
			display: flex;
			//justify-content: space-between;
			width: calc(100% - 30px);
			width: 100%;
			padding-top: 15px;
		}


		&.faded {
			.bra-navigation__list-item {

				@include hover-supported() {
					&:hover {
						opacity: 1;
						transition: opacity .2s ease-in-out;
					}
				}
				opacity: 0.3;
				transition: opacity .4s ease-in-out;

				&.active {
					opacity: 1;
				}
			}
		}
	}

	&__footer {

		display: flex;
		justify-content: space-between;
		width: 100%;
        // max-width: calc( calc( 100vw - 60px ) / 100 * 40 - 40px );
        max-width: 453px;
		padding: 40px;
        padding-right: calc(100% - 453px);
        padding-left: 0;

		margin-top: auto;

		.bra-link {
			line-height: 22px;
			font-size: 15px;
		}
	}
	&__supportWrapper,
	&__socialWrapper {
		display: flex;
		align-items: center;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	&__socialWrapper {
		flex-direction: row;

		&-item {
			.bra-btnIcon__circle {

				@include hover-supported() {
					&:hover {
						background: $support-02;

						.bra-icn {
							color: $brand-03;
						}
					}
				}
				font-size: 15px;
			}
		}
		&-item + &-item {
			margin-left: 10px;
		}
	}


	&__visual {
		&-container {
			width: 0;

			height: 100vh;
			transform: translateZ(0);

			transition: width .4s ease-in-out;
			box-sizing: border-box;
			position: fixed;
			top: 0;
			// left: 40%;
			right: -30px;
			z-index: 601;

			&.visible {

				@media only screen and (max-width:1500px) {
					width: calc(100vw - 40%);
				}
				width: calc( 60% + calc( calc(100vw - 1440px) / 2 ) );
				width: 60%;

				transition: width .4s ease-in-out;

				.bra-navigation__visual-categoryItem {
					// width: 100%;

					// img {
					// 	transform: translateX(-50%) translateY(-50%) translateZ(0);
					// }
				}
			}
		}
		&-categoryItem {
			width: 0;
			height: 100%;
			overflow: hidden;

			user-select: none;
			position: absolute;
			top: 0;
			right: 0;

			//transform: translateX(20%);

			img {
				min-width: 60vw;
				height: 100%;

				opacity: 0;
				transition: opacity .4s ease-in-out;
				// width: 100%;
				// position: absolute;
				// top: 50%;
				// left: 50%;
				// transform: translateX(-50%) translateY(-50%) translateZ(0);

				object-fit: cover;
			}

			&.visible {
				width: 100%;

				img {
					opacity: 1;
					transition: opacity .3s ease-in-out;
				}

				// img {
				// 	transform: translateX(-50%) translateY(-50%);
				// 	transition: all .4s ease-in-out;
				// }
			}
		}
	}


	&--open {


		// @include hover-supported() {
		// 	&.active {
		// 		.bra-navigation__list-container {
		// 			display: block;
		// 		}
		// 	}
		// }
		-webkit-overflow-scrolling: touch;
		// height: 100vh;
		height: 100%;
        overflow-y: auto;
        z-index: 201;
		//min-height: 100vh;

		#bra-navigation__list-spacer--fixed {
			opacity: 0.8;
			transition: background .3s ease-in-out;
		}

		.bra-navigation__list-container {
			opacity: 1;
		}
	}

	&.animating {
        overflow: hidden;
		#bra-navigation__list-spacer--fixed {
			opacity: 0;
			transition: background .3s ease-in-out;
		}
		.bra-navigation__list-container {
			overflow: hidden;

			li {
				transition: none !important;
			}
		}
	}

	&.active {
		background: $ui-07;
		transition: background .3s ease-in-out,
					height .3s ease-in-out;

		#bra-navigation__list-spacer--fixed {
			background: $ui-07;
			transition: background .3s ease-in-out;
		}

		.bra-navigation__list-container {
			width: 100%;
			opacity: 1;
			transition: width 0.4s ease-in-out;

			.bra-navigation__list-item {
				opacity: 0.3;

				&.active {
					opacity: 1;
				}
			}
		}
		.bra-navigation__visual-container {
			width: 0;
			overflow: hidden;
		}
	}
}