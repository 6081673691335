@keyframes pulse {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
// :root{
//     // --plrColor: #00b3ff;
//     --plrColor: #{$support-02};
// }

.bra-videoBanner {
  font-size: 15px;
  line-height: normal;

  @include flex(wrap);
  width: calc(100% - 60px);
  position: relative;
  max-width: 1440px;
  margin: 0 30px;
  overflow: hidden;

  &.ready {
    .bra-videoBanner__link {
      display: block;
    }
    .bra-videoBanner__playIcon {
      opacity: 1;
    }
    .bra-loader {
      opacity: 0;
      height: 0;
      z-index: -10;
      pointer-events: none;
      visibility: hidden;
    }
  }

  &__link {
    display: none;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
  }

  &__preview {
    width: 100%;
    height: 100%;
    background-color: $ui-03;
  }
  &__caption {
    position: absolute;
    left: 0;

    display: flex;
    align-self: flex-end;
    align-items: flex-start;
    width: 100%;
    background-color: rgba($brand-01, 0.8);
    padding: 8px 16px;
    box-sizing: border-box;

    &Wrap {
      @include flex(wrap);
      width: calc(100% - 63px);
      align-content: center;
    }
  }
  &__playIcon {
    @include flex(wrap);
    align-items: center;
    justify-content: center;
    width: 47px;
    height: 47px;
    margin: 0 16px 0 0;

    opacity: 0.4;
  }
  &__title {
    display: block;
    width: 100%;

    @include fontBold;
    font-size: 15px;
    line-height: 21px;
    color: $brand-03;
  }
  &__infoText {
    //@include ellipsis;
    display: block;
    width: 100%;

    @include fontBold;
    line-height: 20px;
    font-size: 17px;
    color: $brand-03;
    margin-top: 4px;
  }

  &__player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    button.bra-btnIcon--close {
      position: absolute;
      top: 0;
      right: 0;
      width: 3em;
      height: 3em;
      background: rgba(white, 0.8);
      font-size: 15px;

      i {
        transform: rotate(45deg);
      }
    }

    &.visible {
      z-index: 10;
    }
  }

  &--double-video-aside {
    width: calc(50% - 45px);
    margin: 0;
    height: 100%;
    display: inline-flex;

    &:first-child {
      margin: 0 30px 0 0;
    }
  }

  &--popup-player {
    .bra-videoBanner__player.visible {
      position: fixed;
      z-index: 1100;
      background: rgba(white, 0.9);

      .bra-videoBanner__playerCostrain {
        position: absolute;
        background: #000;
        overflow: hidden;
        width: 80%;
        margin: 0;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        box-shadow: 0 0 0.6666666667rem rgba(0, 0, 0, 0.1);
      }
    }
  }

  &--centered {
    width: calc(100% - 60px);
    border-radius: 5px;
    max-width: none;
    margin: 0 auto;

    .plyr--video {
      border-radius: 5px;
    }

    .bra-videoBanner {
      &__caption {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 120px;
        height: 120px;
        background: none;
        padding: 0;
      }

      &__playIcon {
        min-width: 120px;
        min-height: 120px;
        margin: 0;
      }
    }
  }
}
