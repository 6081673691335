@media only screen and (max-width: $braun-s - 1) {
    .bra-product-details {
        width: calc(100% - 40px);
        padding: 0 20px;
        padding-top: 0;

        &__grid {
            flex-direction: column;
            // margin: 0 -5px;
            margin: 0;
        }

        &__col {
            flex: initial;
            // flex: 1 1 100%;
            // max-width: 100%;
            width: 100%;
            max-width: none;
            padding: 0;
            // box-sizing: border-box;

            + .bra-product-details__col {
                flex: initial;
                max-width: none;
                // flex: 1 1 100%;
                // max-width: 100%;
            }
        }

        &__category-wrap {
            margin-top: 20px;
            margin-bottom: 10px;
        }

        &__category {
            line-height: 1.27;
            font-size: 15px;
        }

        &__wishlist {
            top: 0;
        }

        &__name {
            line-height: 1.25;
            font-size: 24px;
        }

        &__code {
            line-height: 1.46;
            font-size: 13px;
        }

        &__star-ratings {
            margin-bottom: 40px;
        }
        &__stock {
            font-size: 13px;
        }
        &__variables {
            width: calc(100vw - 40px);
            transform: translateX(-20px);
            padding: 0 20px !important;
            margin-bottom: 30px !important;

            & .swiper-wrapper {
                width: 100%;
                margin: 0;

                & .swiper-slide {
                    width: initial;
                    width: auto;
                    margin: 0 10px 0 0;

                    &:last-child {
                        margin: 0;
                    }
                }
            }
        }

        // GRAY BOX
        &__action-box {
            padding: 20px;
            .bra-btn {
                order: 2;
            }
        }

        &__price {
            order: 1;
            line-height: 1.25;
            font-size: 24px;
        }
        &__price-total {
            line-height: 1.33;
            font-size: 18px;
        }
        &__discount {
            line-height: 1.33;
            font-size: 18px;
        }
        &__where {
            order: 3;
            font-size: 15px;
            margin-top: 20px;
            margin-bottom: 0;
        }
        // GRAY BOX

        &__info {
            flex: 1 1 33.3%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 11px;
            text-align: center;

            &-br-mobile {
                display: block;
            }

            .bra-icn {
                margin-right: 0;
            }
        }

        &__description {
            &-title {
                @include fontBold;
                line-height: 1.47;
                font-size: 15px;
            }
            &-text {
                line-height: 1.46;
                font-size: 13px;
            }
            p {
                font-size: inherit;
                line-height: inherit;
            }
        }

        &__badges {
            .bra-badge {
                height: unset;
            }
        }
    }
}
