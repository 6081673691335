body {
  &.page-homepage {
    background: #f5f5f5;
    --pageBackground: #f5f5f5;
  }
}

.page__wrapper .content_section .bra-highlights .page_margin {
  margin-left: 0;
  margin-right: 0;
}

.bra-sideCard__disclaimer.bra-sideCard__disclaimer--light,
.bra-stage__content .bra-stage__discalimerWrapper .bra-stage__discalimer--light {
  color: $brand-03;
}

.bra-miniCartWrapper {
  position: fixed;
  width: 100%;
  max-width: 400px;
  transition: opacity .2s ease-in-out;
  z-index: 2000;
}

.bra-miniCart {
  display: none;

  @media only screen and (min-width: $braun-l) {
    display: flex;
  }

  .dlg-omnibus-price {
    flex-direction: column;
    align-items: flex-start;
  }
}

.dln-popup--fullsize-desktop-mobile .dln-popup__wrapper {
  height: auto;
}

// Checkout
.dln-checkout--editing .dln-accordion--payment.dln-accordion--editing .dln-checkout__formField .dln-checkServiceBox__cardList {
  display: inline-flex;
}

@media only screen and (max-width: 599px) {
  .dln-accordion.dln-accordion--payment.dln-accordion--editing.dln-accordion--showInfo {
    border-bottom: none;
  }
  .dln-checkout .dln-accordion--payment.dln-accordion--showInfo .dln-accordion__content .dln-checkout__form {
    margin-right: 10px;
    padding-right: 10px;
  }

  .dln-accordion.dln-accordion--payment.dln-accordion--open .dln-checkout__content .dln-checkout__form .dln-checkout__formField .dln-checkout__formField--billingAddress {
    margin-right: 10px;
    padding-right: 10px;
  }
  .dln-checkout__content .dln-accordion {
    width: auto;
  }
}

.dln-checkout--editing .dln-accordion--payment.dln-accordion--editing .dln-accordion__content--submit .dln-checkout__paragraph {
  display: inline;
  margin-bottom: 24px;
}

.dln-checkout__content .dln-accordion--showInfo .dln-accordion__titleLabel {
  display: inline;
}

// Checkout Adyen
.dlg-adyen-checkout .adyen-checkout__card__exp-cvc .adyen-checkout__field:not(.adyen-checkout__field__cvc) {
  display: none;
}

#js-adyen-card-checkout {
  margin-bottom: 24px;
}

.adyen-checkout__field--50:nth-child(2) {
  margin-left: 0;
}

@media only screen and (min-width: $braun-l) {
  .dln-checkout__formGroup {
    width: 60%;
  }
}

// Order History
.dln-order {
  &__dlgBackTo {
    > span {
      @include fontBold;
      font-style: normal;
      line-height: 21px;
      font-size: 14px;
      font-weight: bold;
      color: var(--brand-01);
      text-decoration: underline;
    }

    > .dln-icn {
      width: 10px;
      height: 21px;
      line-height: 21px;
      font-size: 10px;
      color: var(--brand-01);
      text-align: center;
      text-decoration: none;
      margin: 0 8px 0 0;
    }
  }
}

.dln-orderRev__actions > a {
  text-decoration: none;
}

.dln-braun .dln-orderDetailInfo {
  border-top: 1px solid var(--ui-01);
}

// loqate dropdown menu
.dln-braun {
  .pca {
    position: relative;

    .pcaautocomplete {
      z-index: 1 !important;
      left: 0 !important;
      top: 0 !important;
    }
  }
}
