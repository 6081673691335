@media only screen and (max-width: $braun-s - 1) {
	.bra-pagination {
		.bra-pagination__list {
			.bra-pagination__item {
				.bra-link {
					padding: 4px 10px;
				}
			}
		}
	}
}