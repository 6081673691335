@media only screen and (max-width: $braun-s - 1) {
    .bra-product-gallery-popup {
        .bra-product-gallery{
            &__slide {
                top: 20px;
                transform: none;
                bottom: 0;

                .swiper-container {
                    width: 100%;
                    height: 100%;
                    margin: 0;
                }
            }
        }
    }
}
