@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-frequently-bought-together {
        &__wrapper {
            flex-direction: column;
            align-items: flex-end;

            .bra-btn {
                margin-top: 0;
                margin-left: 30px;
            }
            .swiper-container {
                width: 100%;
            }
        }

        &__add-to-cart {
            display: flex;
            align-items: center;
            flex: 1;
            padding: 0;
            padding-top: 20px;
        }

        &__price-total {
            padding: 0 10px 0 20px;
        }
    }
}
