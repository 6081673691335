.bra-list-link {
	font-size: 15px;
	line-height: normal;

	&__list-wrapper {
		font-size: 18px;
		line-height: 18px;

		display: flex;
		flex-direction: column;
		width: 100%;

		.list__item {
			.list-link__link {
				@include hover-supported() {
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}

	&__title {
		font-size: 18px;
		line-height: 24px;

		display: block;
		width: 100%;
		margin-bottom: 20px;

		color: $brand-01;

		@include fontBold;

		text-decoration: none;
	}

	&__list {
		list-style: none;
		margin: 0;
		padding: 0;
	}

	&__item {
		display: block;
		width: 100%;
	}

	&__link {
		@include font;
		font-size: 15px;
		line-height: 30px;

		display: block;
		color: $ui-09;

		text-decoration: none;

		@include hover-supported() {
			&:hover {
				color: $support-02;
				text-decoration: none;
			}
		}
	}
}