.bra-recipe-box {

    @include hover-supported() {
        &:hover {
            .bra-image__image {
                transform: scale3d(1.09,1.09,1.09) translate3d(0, 0, 0);
            }
            & .bra-recipe-box__title {
                border-color: $ui-01;
            }
        }
    }
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    border-color: $brand-03;
    // cursor: pointer;

    &__img {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 152px;
        background: $ui-03;
        padding: 0;
        margin-bottom: 10px;
        position: relative;

        & .bra-image__image {
            transition: transform .35s ease-in-out;
        }
        & .bra-btnIcon--delete {
            width: auto;
            margin: 5px 5px 0 auto;
        }
        .bra-btnIcon {
            width: 40px;
            height: 40px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 10;

            .bra-icn {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 24px;
                height: 24px;
                font-size: 18px;
                border-radius: 12px;
                background-color: rgba(255, 255, 255, .7);
            }
        }

        picture {
            width: 100%;
            height: 100%;
            border-radius: 5px;
            position: absolute;
            top: 0;
            z-index: 0;
        }
    }
    &__title {
        display: block;
        width: 100%;
        // min-height: 45px;        
        border-style: solid;
        border-width: 0 0 1px 0;
        border-color: transparent;
        padding: 0 0 14px 0;
        margin: 0 0 16px 0;
        
        & > a {
            &:link,
            &:visited,
            &:hover,
            &:active {
                @include fontBold;
                display: block;
                width: 100%;
                line-height: 16px;
                font-size: 14px;
                color: $brand-01;
                text-decoration: none;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    &__topic {
        display: block;
        width: 100%;
        height: auto;
        line-height: 20px;
        font-size: 12px;
        color: $brand-02;
    }

    &__details {

        @include font;

        @media only screen and (max-width:599px) {
            font-size: 12px;
        }
        justify-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        line-height: 16px;
        font-size: 14px;
        color: $brand-02;
        margin-top: auto;

        .bra-icn {
            line-height: normal;
            font-size: 20px;
            margin-right: 7px;
        }

        .bra-recipe-box__detail {

            @media only screen and (max-width:599px) {
                width: 100%;
            }
            display: flex;
            margin-bottom: 8px;
        }

    }

    &--v2 {
        .bra-recipe-box__img {
            .bra-btnIcon--big-card {
                .bra-icn {
                    color: $support-02;
                }
            }
            .bra-btnIcon {
                .bra-icn {
                    font-size: 12px;
                }
            }
        }
    }

    &--big-card {
        .bra-btnIcon {
            top: 10px;
            right: 10px;

            .bra-icn {
                width: 40px;
                height: 40px;
                font-size: 18px;
                color: $ui-09;
                border-radius: 50%;
                background-color: $brand-03;

                &__bookmark-active {
                    color: $support-02;
                }
            }
        }
        .bra-recipe-box__img {
            height: auto;
            padding-top: 100%;
            position: relative;

            & .bra-image {
                top: 0;
            }
        }

        .bra-recipe-box__title {
            line-height: 22px;
            font-size: 18px;
            font-weight: bold;
            padding-bottom: 7px;
            margin-bottom: 7px;
        }

        .bra-recipe-box__details {
            justify-content: flex-start;
            line-height: 24px;
            font-size: 15px;
            color: $ui-09;

            .bra-recipe-box__detail {
                margin: 0;
            }
            .bra-recipe-box__detail--difficultyLevel {
                flex: 5;
                margin-right: 10px;
            }
            .bra-recipe-box__detail--preparationTime {
                flex: 7;
            }

            .bra-icn {
                height: 20px;
                font-size: 20px;
                &:before {
                    width: 20px;
                    height: 20px;
                }
            }
        }
    }
}