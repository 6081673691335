@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
}

@media only screen and (max-width: $braun-m - 1) {
	.bra-list-link {
		&__title {
			font-size: 15px;
			line-height: 19px;
		}
		&__link {
			font-size: 13px;
			line-height: 26px;
		}
	}
}
