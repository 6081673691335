.bra-titleStep {
    font-size: 26px;
    line-height: 32px;

    display: block;
    width: 100%;
    height: auto;

    @include font;
    color: $brand-01;

    &__step  {
        color: $support-03;
    }
}

/*M*/
@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-titleStep {
        font-size: 24px;
        line-height: 30px;
    }
}
/*S*/
@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-titleStep {
        font-size: 22px;
        line-height: 28px;
    }
}
/*XS*/
@media only screen and (max-width: $braun-s - 1) {
    .bra-titleStep {
        font-size: 18px;
        line-height: 26px;
    }
}