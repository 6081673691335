@media only screen and (max-width: $braun-s - 1) {
    .bra-subHead {
        margin-bottom: 25px;
        
        & .bra-subHeadline__wrapper {
            width: 100%;    
            max-width: none;

            & .bra-subHeadline {
                line-height: 27px;
                margin-bottom: 15px;
            }
        }
        & .bra-subHead__text {
            width: 100%;
            line-height: 18px;    
            padding: 0;
        }
    }
}
