@media only screen and (max-width: $braun-s - 1) {
    .bra-multi-accordion {
        &__title {
            font-size: 15px;
            line-height: 1.47;
        }

        &__description {
            font-size: 13px;
            line-height: 1.47;
        }

        &--specifications {
            .bra-multi-accordion {
                &__title {
                    font-size: 18px;
                    line-height: 1.33;
                }
                &__list-item {
                    font-size: 13px;
                    line-height: 1.23;
                }
            }
        }
    }


    .bra-multi-accordion--terms-and-conditions {
        width: calc(100% - 30px);
        padding-left: 15px;
        padding-right: 15px;

        .bra-multi-accordion {
            &__item {
                .bra-multi-accordion__expander.active {
                    .bra-multi-accordion__title {
                        font-size: 15px;
                        padding-top: 0;
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }

            &__expander {
                padding: 15px 0 20px;
            }

            &__title {
                font-size: 15px;
                max-width: 80%;
            }

            &__subTitle {
                font-size: 13px;
                line-height: 1.46;
                display: block;
                padding-left: 0;
                padding-top: 8px;
            }

            &__description {
                font-size: 11px;
                line-height: 1.45;
            }

            &__content {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .bra-multi-accordion--promo {
        .bra-multi-accordion {
            &__item {
                .bra-multi-accordion__expander.active {
                    + .bra-multi-accordion__content {
                        display: inline-block;
                        max-height: 800px;
                        width: 90%;
                        overflow: visible;
                        opacity: 1;
                        padding: 0;
                        margin: 0;
                    }
                }
                & .bra-multi-accordion__expander {
                    & .bra-multi-accordion__title{
                        width: calc(100% - 30px);
                        margin: 0 auto 0 0;
                        line-height: 19px;
                        font-size:15px;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        text-align: left;
                    }
                    & .bra-multi-accordion__subTitle {
                        display: none;
                    }
                }
            }

            &__image {
                width: 202px;
                min-width: 202px;
                height: 202px;
            }
            &__description-wrap {
                padding-top: 12px;
                padding-left: 0;

                .bra-multi-accordion__title {
                    font-size: 15px;
                    line-height: 1.27;
                }
            }
            &__description {
                font-size: 13px;
                line-height: 1.46;
            }
        }
    }
}
