@media only screen and (max-width: $braun-s - 1) {
    .bra-crosslinkCardGrid {
        &--two {
            & .bra-card.bra-card--crosslink   {
                width: 100%;
                margin: 0 0 20px 0;

                &:nth-child(2n+2) {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}
