@media only screen and (max-width: $braun-s - 1) {
	.bra-input {
		&__input {
			height: 2.4em;
			font-size: 17px;
			line-height: 1;
		}
		&__label {
			font-size: 13px;
			line-height: 18px;
			padding: 0;
		}

		&--checkbox,
		&--checkbox--image {
			label {
				font-size: 15px;
				line-height: 21px;
				padding: 0;
			}
			.bra-input__label--sup {
				font-size: 15px;
				line-height: 21px;
			}
		}

		&--select {
			.bra-select__wrapper {
				height: 2.5em;
				select {
					width: 100%;
					height: 2.5em;
					font-size: 15px;
				}
				&::after {
					height: 2.5em;
					line-height: 2.5em;
					width: 2.5em;
				}
			}
			.bra-input__label {
				font-size: 13px;
				line-height: 18px;
			}
		}

		&--countrySelect {
			.bra-select__wrapper {
				height: auto;
				select {
					width: auto;
					height: auto;
					font-size: 13px;
				}

				&::before {
					width: 30px;
					height: 30px;
					line-height: 30px;
				}
			}
		}
	}
}