.bra-sku-slider {
    width: 100%;
	font-size: 15px;
	line-height: normal;

	&.bra-sku-slider--v2 {
		.swiper-container.sku-slider .swiper-slide {
			width: calc(25% - 31px) !important;
		}
	}

	.swiper-container.sku-slider {
		position: relative;
		max-width: 1440px;
		overflow: visible;

		.swiper-slide {
			border-radius: 5px;
			box-shadow: inset 0 0 0 1px $ui-08;
			overflow: hidden;
			max-width: none;
			width: calc(33% - 32px) !important;

			.bra-card {
				margin: 0;
				.bra-card__wrapper {
					background: transparent;
				}
			}

			.bra-sideCard {
				--textAlign: left;
				background: transparent;

				&__textContent {
					background: transparent;
					padding: 90px;
				}
			}
		}

		.swiper-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;

			width: 85%;
			max-width: 1200px;
			margin: 0 auto;
			margin-top: 55px;

			.swiper-button-prev {
				order: 1;
			}
			.swiper-scrollbar {
				order: 2;
			}
			.swiper-button-next {
				order: 3;
			}

			.swiper-scrollbar {
				position: relative;
				left: 0;
				bottom: 0;
				height: 3px;
				width: 85%;
				background: $ui-08;

				.swiper-scrollbar-drag {
					background: $brand-01;
				}
			}

			.swiper-button-next,
			.swiper-button-prev {
				position: relative;
				background: none;

				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: auto;
				height: auto;
				margin: 0;
			}
		}

	}
}