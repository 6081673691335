@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-imageBlock {
        width: calc(100% - 40px);
        margin: 0 20px;
        
		& .bra-imageBlock__content {
			& .bra-paragraph {
				font-size: 13px;
			}
		}
	}
}
