.bra-sticky-navigation {
    position: fixed;
    width: 100%;
    top: -100%;
    opacity: 0;
    box-sizing: border-box;
    transition: all .5s;
    background: $ui-07;
    padding: 18px 30px 0;
    z-index: 100;

    .swiper-slide {
        width: auto;
    }
    .swiper-scrollbar,
    .swiper-pagination {
        display: none !important;
    }
    
    &.open {
        top: 85px;
        opacity: 1;
    }

    &[data-new-header].open {
        top: 80px;
        opacity: 1;
    }

    &[data-new-header].open.--top {
        top: 0;
    }

    &__product-details {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
    }

    &__col {
        display: flex;
        align-items: center;
    }

    &__name {
        // @include fontBold;
        // font-size: 18px;
        // line-height: 1.67;
        // margin-right: 10px;
        // max-width: calc(100% - 136px);
        display: flex;
        flex-wrap: nowrap;
    }
    &__identifier,
    &__modelNum {
        display: block;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        @include fontBold;
        font-size: 18px;
        line-height: 1.67;
        margin-right: 10px;
    }
    &__modelNum {
        display: none;
    }
    &__price {
        @include fontBold;
        font-size: 15px;
        margin-right: 20px;
    }
    &__item {
        font-size: 15px;
        
        + .bra-sticky-navigation__item {
            margin-left: 20px;    
        }
    }
    &__link {
        display: block;
        text-decoration: none;
        color: $ui-09;
        padding-bottom: 10px;
        font-size: 15px;
        border-bottom: 3px solid transparent;
        
        &:hover,
        &.selected{
            color: $brand-01;
            border-color: $brand-01;
        }
    }

    // &__gradient {
    //     display: none;
    // }
}