.bra-input {
	&__wrapper {
		font-size: 15px;
		line-height: normal;

		--txtColor: #{$brand-01};
		--bgColor: #{$brand-03};
		--borderColor: #{$ui-01};
		--supportColor: #{$support-02};

		display: flex;
		flex-direction: column;
		position: relative;

		cursor: pointer;
		* {
			cursor: pointer;
		}
	}

	&__input {
		display: block;
		appearance: none;
		resize: none;
		box-sizing: border-box!important;

		width: 100%;
		height: 2.4em;

		@include font;
		font-size: 17px;
		line-height: 1;

		padding: 0 10px;

		border-radius: 0;
		border: 2px solid $ui-01;

		&:focus {
			outline-color: $support-02;
			outline-style: auto;
			outline-width: 5px;
		}

		&[type=search] {
			&::-webkit-search-cancel-button,
			&::-webkit-search-decoration {
				-webkit-appearance: none;
			}
		}
	}
	&__label {
		display: flex;
		align-items: center;

		order: -1;
		padding: 0.5em 0;

		@include font;
		font-size: 17px;
		line-height: 24px;
	}
	&__notification {
		display: none;
		padding: 0.5em 0;
		font-size: 14px;
	}
	&__suggestionWrapper {
		display: none;

		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		right: 0;

		border: 1px solid $ui-01;

		box-shadow: 0 0 10px rgba(black,.1);
		background-color: #fff;
		padding: 20px;

		.bra-suggestionList__results,
		.bra-suggestionList__hint {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				padding: 0.05em;
			}

			.bra-link {
				display: block;
			}
		}
		.bra-suggestionList__hint {
			display: none;
			margin-top: .5em;
		}

		&.hasHint {
			.bra-suggestionList__hint {
				display: block;
			}
		}
	}

	&--disabled {
		cursor: default;
		* {cursor: default;}
		.bra-input__input,
		.bra-input__label {
			opacity: .7;
		}
		input::before {
			box-shadow: inset 0 0 0 30px $ui-01;
		}
	}

	&__wrapper.hasSuggestion {
		z-index:999;
		.bra-input__suggestionWrapper {
			display: block;
		}
	}

	&--checkbox,
	&--checkbox--image {
		flex-direction: row;
		align-items: center;

		input {
			width: 1em;
			min-width: 1em;
			max-width: 1em;
			height: 1em;
			min-height: 1em;
			max-height: 1em;
			padding: 0;
			border: 0;
			margin-right: 0.5em;

			&.bra-input__error:before  {
				box-shadow: 0 0 0 1px #f18f32;
			}

			&::before {
				content: '';
				display: block;
				width: 100%;
				height: 100%;
				border-radius: 2px;
				box-shadow: 0 0 0 1px $ui-01;
			}
			&:checked {
				&::before {
					box-shadow: 0 0 0 1px $ui-01,
								inset 0 0 0 3px white,
								inset 0 0 0 15px $support-02;
				}
			}

			&:disabled {
				&::before {
					box-shadow: inset 0 0 0 15px $ui-01;
				}
			}
		}

		label {
			order: 1;
		}
		.bra-input__label--sup {
			order: 2;
			display: inline-block;
			margin-left: 0.5em;
			opacity: 0.6
		}
	}

	&--checkbox-v {
		input {
			width: 20px;
			min-width: 20px;
			max-width: 20px;
			height: 20px;
			min-height: 20px;
			max-height: 20px;
			padding: 0;
			border: 0;
			margin-right: 10px;
			position: relative;
			border-radius: 5px;
			box-shadow: 0 0 0 1px $ui-09;
			display: flex;
			align-items: center;
			justify-content: center;

			&.bra-input__error  {
				box-shadow: 0 0 0 1px #f18f32;

				&:before {
					box-shadow: none;
				}
			}

			&:focus {
				outline: none;
			}

			&::before {
				position: static;
				box-shadow: none;
			}
			
			&:checked{
				&::before {
					width: auto;
					height: auto;
					font-family: "bra-icons";
					content: '\e80B';
					font-size: 18px;
					box-shadow: none;
				}
			}
		}
	}

	&--checkbox--image {
		display: inline-flex;
		flex-direction: column-reverse;
		padding: 10px;

		input {
			margin: 0;
		}
		label {
			user-select: none;
			display: block;
			position: relative;
			margin-bottom: 0.5em;
			padding: 0;
			width: 70px;
			height: 70px;
			text-align: center;
			font-size: 14px;
			color: white;

			&:after {
				content: '';
				width: 70px;
				height: 70px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background: linear-gradient(180deg,#4d4c4c 0,#000);
				border-radius: 50%;
				z-index:-1;
			}
			&::before {
				color: $support-02;
				content: '';
				font-family: $fontNameIcons__legacy;
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: block;
				text-decoration: inherit;
				width: 100%;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				font-size: 1.5em;
				line-height: 1.2em;
				margin-top: 0.5em;
				margin-left: 0;
				margin-right: 0;
				-webkit-font-smoothing: antialiased;
			}
		}

		.bra-input__label--sup {
			order: 0;
			display: block;
			margin: 0;
			margin-bottom: 0.5em;
			color: black;
			font-size: 17px;
			width: 100%;
			text-align: center;
		}
	}

	&--select {
		--txtColor: #{$brand-01};
		--bgColor: #{$brand-03};
		--borderColor: #{$ui-01};
		--supportColor: #{$support-02};

		display: inline-flex;
		flex-direction: row;
		position: relative;

		.bra-select__wrapper {
			flex: 1;
			border-radius: 0;
			border: 1px solid $ui-01;

			select {
				display: block;
				appearance: none;
				resize: none;
				border: 0;
				background: transparent;
				padding: 0 10px;
				box-sizing: border-box!important;
				padding-right: calc(2em + 10px);

				width: 100%;
				height: 2.4em;

				@include font;
				font-size: 17px;

				&:focus {
					outline-color: $support-02;
					outline-style: auto;
					outline-width: 5px;
				}
			}

			&::after {
				position: absolute;
				right: 0;
				top: 0;

				content: '';
				font-family: $fontNameIcons__legacy;
				font-style: normal;
				font-weight: normal;
				speak: none;
				display: inline-block;
				text-decoration: inherit;
				width: 2em;
				font-size: 1.5em;
				line-height: 2em;
				margin-right: 0;
				margin-left: 0;
				text-align: center;
				font-variant: normal;
				text-transform: none;
				
				z-index: -1;
				-webkit-font-smoothing: antialiased;
			}
		}
		.bra-input__label {
			display: flex;
			flex: 0;
			margin-right: 1em;

			@include fontBold;
			font-size: 15px;
			line-height: 22px;

			&::after {
				content: ':'
			}
		}
	}

	&--countrySelect {
		.bra-select__wrapper {
			display: flex;
			border: 0;

			&::after {
				display: none;
			}

			select {
				display: flex;
				font-size: 13px;
				line-height: initial;
				height: auto;
				padding: 0 10px;
				width: auto;
				outline: none;
				@include fontBold;
				color: $ui-09;

				&:focus {
					outline: none;
				}
			}

			&::before {
				content: attr(data-value);
				display: block;
				border-radius: 150px;
				@include fontBold;
				font-size: 13px;
				width: 40px;
				height: 40px;
				background: black;
				color: white;
				text-align: center;
				line-height: 40px;
			}
		}
	}

}