@media only screen and (max-width: $braun-s - 1) {
    .bra-sideCard {
        margin: 0 20px;
        
        &__subTitle {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 10px;
        }
        &__title {
            font-size: 24px;
            line-height: 27px;
            margin-bottom: 10px;
        }
        &__description {
            font-size: 13px;
            line-height: 19px;
        }
        &__disclaimer {
            position: static;
            left: initial;
            bottom: initial;
            padding: 0 20px 20px;
        }
        &.bra-sideCard--with-subtitle.bra-sideCard--reverse.bra-sideCard--text-aside {
            & .bra-sideCard__image {
                order: 1;
            }
            & .bra-sideCard__textContent {
                order: 2;
            }
            & .bra-sideCard__disclaimer {
                order: 3;
            }
        }

        &__image,
        &__textContent {
            width: 100%;
        }

        &__textContent {
            padding: 20px;
        }

        &--reverse {
            & .bra-sideCard__image {
                order: 1;
            }

            & .bra-sideCard__textContent {
                order: 2;
            }
        }

        &__image {
            order: 1;
        }

        &__textContent {
            order: 2;
        }

        &__disclaimer {
            order: 99;
        }
    }
}