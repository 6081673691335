.bra-productCard {
	display: flex;
	flex-direction: column;
	padding: 33px 10px;

	font-size: 15px;
	line-height: normal;

	.js-prod-anchor {
		visibility: hidden;
		position: absolute;
		top: -120px;
	}

	&__figure {
		display: flex;
		flex-direction: column;
		position: relative;
		height: 100%;

		.bra-productCard__link {
			margin-bottom: 22px;
		}
		.bra-image {
			
		}

		.bra-productCard__badgesList {
			position: absolute;
			top: -1em;
			right: 0;
			list-style: none;
			padding: 0;
			margin: 0;

			.bra-productCard__badge {
				@include font;
				font-size: 15px;
				line-height: 21px;

				padding: 8px 20px;
				text-transform: lowercase;

				&--new {
					background-color: $support-02;
					color: $brand-03;
				}

			}
		}

	}

	&__figureCaption {
		display: flex;
		flex-direction: column;
		flex: 1;
		text-align: center;
		margin: 0 40px;

		.bra-productCard__link {
			display: block;
			color: $brand-01;
			text-decoration: none;
			transition: color .13s ease-in-out;

			@include hover-supported() {
				&:hover {
					color: $support-02;
					text-decoration: underline;
					transition: color .13s ease-in-out;
				}
			}
		}

		.bra-productCard__title {
			@include fontBold;
			font-size: 17px;
			line-height: 24px;

			.bra-icn {
				font-size: 10px;
			}
		}

		.bra-productCard__compareWrapper {
			margin-top: auto;

			.bra-input__wrapper {
				display: inline-flex;
				margin: 10px auto;
			}
		}
	}
}