@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-grid-box {
        .bra-card {
            // flex: 0 1 calc(33.33% - 20px);
            width: calc(33.33% - 13.34px);
            // margin: 0 20px 20px 0;
            &:nth-of-type(4n+4) {
                margin: 0 20px 20px 0;
            }
            &:nth-of-type(3n+3) {
                margin: 0 0 20px 0;
            }
        }
    }
}
