@media only screen and (min-width: $braun-s) and (max-width: $braun-m - 1) {
  .bra-header {
    padding-right: 30px !important;
    padding-left: 30px !important;

    .bra-quickLinks__wrapper {
      display: none;
    }
  }

  .dln-country-stripe {
    & .dln-country-stripe__text {
      width: calc(100% - 32px - 464px - 16px) !important;

      & .dln-country-stripe__buttons {
        margin-right: 16px !important;
      }
    }
  }
}
