@keyframes tapAnimation__inner {
	0% {
		transform: scale(1);		
	}
	20% {
		transform: scale(1);		
	}
	28% {
		transform: tcale(.8);		
	}
	59% {
		transform: tcale(.8);		
	}
	63% {
		transform: scale(1);		
	}
	100% {
		transform: scale(1);		
	}
}
@keyframes tapAnimation__outer {
	0% {
		transform: scale(2.2);
		opacity: 0;
	}
	24% {
		transform: scale(1.6);
		opacity: 1;
	}
	26% {
		transform: scale(1.6);
		opacity: 1;
	}
	48% {
		transform: scale(2.2);
		opacity: 0;
	}
	100% {
		transform: scale(2.2);
		opacity: 0;
	}
}



.bra-stories {
    &__container {
        width: 100%;
        height: auto;
        display: flex;
        flex-wrap: wrap;
        box-sizing: border-box;
        padding: 0 30px;
    }
	&__wrapper {
		width: 100%;
		overflow: hidden;
		background: white;

		position: relative;

		width: 1440px;
		height: 720px;
		// margin: 0 30px;

		font-size: 15px;
		line-height: normal;

		//transition: height .3s ease-in-out;

		color: white;

		border-radius: 5px;
		overflow: hidden;


		@media only screen and (max-width:1500px) {
			width: 100%;
			height: 0;
            padding-top: 50%;
		}

		&.ready {
			.bra-stories__screen--start {
				opacity: 1;
				z-index: 50;
				visibility: visible;
			}
			.bra-stories__screen--end,
			.bra-stories__screen--loading {
				opacity: 0;
				z-index: 0;
				visibility: hidden;
			}

		}
		&.running {
			.bra-stories__screen--start,
			.bra-stories__screen--end,
			.bra-stories__screen--loading {
				opacity: 0;
				z-index: 0;
				visibility: hidden;
			}
			.bra-stories__slider {
				opacity: 1;
				z-index: 1;
				visibility: visible;
			}
		}
		&.end {
			.bra-stories__screen--end {
				opacity: 1;
				z-index: 50;
				visibility: visible;
			}
			.bra-stories__screen--start,
			.bra-stories__screen--loading {
				opacity: 0;
				z-index: 0;
				visibility: hidden;
			}
		}
		& .bra-stories__slider {
			& .swiper-slide {
				& .bra-stories__slide--content {
					& > strong, 
					& > p {
						font-size: 15px;
						line-height: 22px;
					}
				}
			}
		}
	}

	&__screen {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		border-radius: 5px;
		overflow: hidden;

		transition: opacity .3s ease-in-out,
					height .3s ease-in-out;

		display: flex;
		align-content: center;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;

		&--content {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 10;
			height: 100%;
			width: 100%;

			display: flex;
			align-items: center;
		}

		&--background {
			width: 100%;
			height: 100%;
			transition: height .3s ease-in-out;
			user-select: none;
			pointer-events: none;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: rgba(black,.25);
			}
		}

		.buttonTap {
			width: 100px;
			// position: relative;
			padding: 7px;
			margin-top: 70px;
			// transform: translateX(-50%);
			user-select: none;
			// left: calc(50% - 50px);
			box-sizing: border-box;
			// bottom: 30px;
			// background-color: lightskyblue;

			@include fontBold;

			&::after,
			&::before {
				display: block;
				position: absolute;
				top: -60px;
				left: calc(50% - 15px);
				content: '';
				width: 30px;
				height: 30px;
				box-shadow: 0 0 0 3px white;
				border-radius: 60px;
				transform-origin: center;
				// background: lightslategray;
				// transform: translateX(-50%) translateY(50%);
			}
			&::after {
				box-shadow: 0 0 0 0.7px rgba(white,.8);
				animation: tapAnimation__outer 2.5s infinite ease-in-out;
				// animation-direction: alternate;
			}
			
			&::before {
				animation: tapAnimation__inner 2.5s infinite ease-in-out;
				// animation-direction: alternate;
			}
		}

		&--loading {
			background: white;

			font-size: 12px;
			color: black;

			opacity: 1;
			z-index: 50;
		}
		&--start {
			opacity: 0;
			z-index: 0;

			cursor: pointer;

			.buttonTap {
				position: absolute;
				bottom: 30px;
				left: calc(50% - 50px);
			}
		}
		&--end {
			opacity: 0;
			z-index: 0;

			cursor: pointer;

			.tapToReplay {
				// left: 50%;
				left: calc(50% - 50px);
				position: absolute;
			}
		}

		.bra-subtitle {
			color: white;
			@include fontBold;
			font-size: 36px;
			line-height: 45px;

			max-width: 75%;
			margin: 0 auto;
			padding-bottom: 50px;
		}
	}

	&__slider {
		opacity: 0;
		z-index: 0;
		visibility: hidden;
		transition: opacity .3s ease-in-out,
					height .3s ease-in-out;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
        height: 100%;

        & .swiper-scrollbar {
            display: none !important;
        }
	}
}


@keyframes fillBar {
	from {width: 0%}
	to {width: 100%}
}

.swiper-container.stories-slider {
	border-radius: 5px;
	overflow: hidden;
	width: 100%;
	height: 100%;

	.swiper-slide {
		width: 100%;
		display: flex;
		align-items: flex-end;
		border-radius: 5px;
		overflow: hidden;
		transition: height .3s ease-in-out;

		&.topAligned {
			align-items: flex-start;
			.bra-stories__slide--content {
				bottom: 0;
				top: 0;
			}
		}

		.bra-stories__slide--visual {
			width: 100%;
			height: 100%;

			video {
				width: 100%;
				min-height: 100%;
				object-fit: cover;

				left: 50%;
				position: relative;
				transform: translateX(-50%);
			}

			.mobileVersion {
				display: none;
			}
			.desktopVersion {
				display: block;
				height: 100%;
			}

			.bra-image {
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					content: '';
					background: rgba(black,.15);
				}
			}
		}
		.bra-stories__slide--content {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 10;
			margin: 90px auto;
			margin-left: 10%;
			max-width: calc(80% / 2);
			overflow: hidden;

			font-size: 18px;
			line-height: 27px;

			strong {
				@include fontBold;
			}

			* {
				display: inline-block;
				opacity: 0;
				transform: translateX(-60px);
				transition: all .8s ease-in-out;
			}
		}


		.bra-image {
			transition: height .3s ease-in-out;
			img {
				transition: height .3s ease-in-out;
			}
		}

		&.swiper-slide.current {
			.bra-image {
				img {
					transform: scale(1.1);
					transition: transform 9s linear,
								height .3s ease-in-out;
				}
			}

			.bra-stories__slide--content {
				* {
					opacity: 1;
					transform: translateX(0);
				}
			}
		}
	}

	.swiper-pagination {
		position: absolute;
		bottom: 30px;
		left: 50%;
		transform: translateX(-50%);
		display: table;
		width: calc( 80% + 8px );
		border-collapse: separate;
		border-spacing: 4px 0;

		&-bullets {
			.swiper-pagination-bullet {
				display: table-cell;
				border-radius: 0;
				height: 3px;
				background: rgba(white,.5);
				opacity: 1;

				i {
					display: block;
					height: 3px;
					width: 0;
					background: white;

					transition: width 1s ease-in-out;
				}
			}
			.swiper-pagination-bullet.full {
				i {
					width: 100%;
					transition: width 1s ease-in-out;
				}
			}
			.swiper-pagination-bullet.current {
				i {
					transition: none;
					animation: fillBar 8s linear 1 normal forwards;
				}
			}
		}
	}

	.swiper-footer {
		position: absolute;

		top: 0;
		height: 100%;
		width: 100%;
		max-width: none;
		margin: 0 auto;

		transition: height .3s ease-in-out;

		display: flex;
		justify-content: space-between;
		align-items: center;

		.swiper-button-next,
		.swiper-button-prev {
			position: relative;
			background: none;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			width: auto;
			height: auto;
			margin: 30px;

			&.swiper-button-disabled {
				opacity: 0;
			}
			& .bra-icn__arrow--left,
			& .bra-icn__arrow--left::before,
			& .bra-icn__arrow--right,
			& .bra-icn__arrow--right::before {
				color: $brand-03;
			}
		}

		.swiper-button-prev {
			order: 1;
			margin: 30px auto 30px 30px;
		}
		.swiper-button-next {
			order: 3;
			margin: 30px 30px 30px auto;
		}

		.swiper-button-close {
			position: absolute;
			top: 0px;
			right: 0px;
			padding: 30px;
			z-index: 50;

			cursor: pointer;
		}
	}
}