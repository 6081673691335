.bra-attachment-explanation {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 20px;

  @media screen and (min-width: $braun-m) {
    flex-direction: row;
    margin: 0 30px;
  }

  &__slider,
  &__attachments {
    display: grid;
    width: 100%;
    height: auto;

    @media screen and (min-width: $braun-m) {
      width: 50%;
      height: auto;
    }
  }

  &__slider {
    order: 2;
    @media screen and (min-width: $braun-m) {
      order: 1;
    }
  }

  &__attachments {
    position: relative;
    order: 1;
    @media screen and (min-width: $braun-m) {
      order: 2;
    }

    &__top,
    &__mid,
    &__bottom {
      grid-column: 1;
      grid-row: 1;
      display: flex;
      top: 0;
      overflow: hidden;
    }

    &__top {
      z-index: 2;
    }

    &__mid {
      z-index: 1;
    }
  }

  &__single-slide {
    position: relative;
    .bra-slide-title {
      @include font;
      width: calc(100% - 40px);
      padding: 0 20px;
      position: absolute;
      top: 0%;
      margin-top: 78px;
      font-size: 24px;
      line-height: 30px;
      color: $brand-03;

      @media screen and (min-width: $braun-s) {
        font-size: 40px;
        line-height: 45px;
        margin-top: 88px;
      }

      @media screen and (min-width: $braun-m) {
        margin-top: 94px;
        font-size: 36px;
        line-height: 42px;
      }

      @media screen and (min-width: $braun-l) {
        margin-top: 115px;
        font-size: 48px;
        line-height: 54px;
      }
    }
  }

  &[class^='bra-'] {
    .swiper-header {
      display: flex;
      position: absolute;
      margin-top: 25px;
      width: 100%;

      @media screen and (min-width: $braun-s) {
        margin-top: 30px;
      }

      @media screen and (min-width: $braun-l) {
        margin-top: 40px;
      }

      .swiper-pagination {
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        .bra-btn {
          min-width: auto;
          background-color: transparent;
          color: $brand-03;
          border-color: $brand-03;

          &.swiper-pagination-bullet-active,
          &:hover {
            color: $brand-01;
            background-color: $brand-03;
          }

          &:first-child {
            margin-left: 20px;
          }

          &:last-child {
            margin-right: 20px;
          }
        }

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
    .swiper-footer {
      position: absolute;
      bottom: 0;
      max-width: 100%;
      width: auto;
      margin: 20px;

      .swiper-button-next,
      .swiper-button-prev {
        display: block;

        .bra-btnIcon__circle {
          background: $brand-03;
          width: 60px;
          height: 60px;

          @media screen and (min-width: $braun-m) {
            width: 72px;
            height: 72px;
          }
          @include hover-supported() {
            &:hover {
              background: $brand-01;

              .bra-icn {
                color: $brand-03;
              }
            }
          }

          .bra-icn {
            color: $brand-01;
          }
        }
      }

      .swiper-button-prev {
        margin-right: 15px;
      }
    }
    .swiper-scrollbar {
      display: none;
    }
  }

  .bra-image__image {
    flex: none;
  }

  /* Only for Safari  */
  .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets::after {
    content: '.';
    color: transparent;
  }
}
