@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
}

@media only screen and (max-width: $braun-m - 1) {
    .bra-btn {
        font-size: 13px;
        height: 34px;
        min-width: 120px;
        padding: 0 20px;
        line-height: 31px;
    }
}
