.bra-movingLetters {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 20px;

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 120px;
  }

  &__title {
    @include fontBold;
    padding-bottom: 10px;
    font-size: 120px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: var(--brand-01);

    &--moving {
      position: absolute;
      opacity: 0;
      transition: opacity 0.3s;

      &--active {
        opacity: 1;
      }
    }
  }

  &__subtitle {
    @include font;
    padding-top: 20px;
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: var(--brand-01);
  }
}
