.bra-prodTile {
    @include flex(wrap);
    width: 100%;
    border-radius: 5px;
    background-color: transparent;
    position: relative;
    z-index: 1;

    &__wrap {
        @include flex(wrap);
        width: 100%;
        height: auto;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
        position: absolute;
        top: 0;
        left: 0;
    }
    &__product {
        @include flex(wrap);
        flex-direction: column;
        width: 100%;
        height: auto;
        border-radius: 5px;
        background-color: $ui-07;
        transition: border-radius 0.18s ease;
        padding: 20px;
        position: relative;
        z-index: 4;
    }
    &__body {
        width: 100%;
        flex-grow: 2;
    }
    &__head {
        @include flex(wrap);
        width: 100%;
        height: 20px;
        margin: 0 0 10px 0;

        & .bra-btnIcon--wishlist {
            margin: 0 0 0 auto;

            & .bra-icn__wishlist {
                &-inactive {
                    &::before {
                        color: $ui-09;
                    }
                }
                &-active {
                    &::before {
                        color: $support-02;
                    }
                }
            }
        }
    }
    &__imgWrap {
        @include flex(wrap);
        width: 100%;
        height: 195px;
        text-decoration: none;
        background-color: $brand-03;
        margin: 0 0 10px 0;
        cursor: pointer;
        position: relative;
        z-index: 1;
    }
    &__img {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;

        &File {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            pointer-events: none;
            user-select: none;
        }
    }
    &__imgOverlay {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 3;
        background-color: rgba(0,0,0,.04);
    }
    &__productInfo {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        text-decoration: none;
        margin: 0 0 5px 0;
    }
    &__badges {
        @include flex(wrap);
        margin: 0 0 5px;
        max-width: 100%;

        & .bra-badge {
            margin: 0 5px 5px 0;
            height: unset;

            &:last-of-type,
            &:only-of-type {
                margin: 0 auto 5px 0;
            }
        }
    }
    &__name {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 19px;
        font-size: 15px;
        font-weight: 700;
        color: $brand-01;
    }
    &__type {
        @include fontBold;
        display: block;
        width: 100%;
        line-height: 19px;
        font-size: 15px;
        font-weight: 700;
        color: $brand-01;
    }
    &__priceWrap {
      @include flex(wrap);
      align-items: center;
      width: 100%;
      height: 19px;
      line-height: 1.27;
      font-size: 15px;
      margin: 0 0 15px 0;
  
      .--list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        column-gap: 5px;
        list-style: none;
        margin-top: 2px;
      }
  
      .--mainPrice {
        @include fontMedium;
        font-size: 15px;
        color: $brand-01;
      }

      .--info {
        display: flex;
        gap: 5px;
      }
  
      .--strikePrice {
        text-decoration: line-through;
        order: 1;
      }
  
      .--strikePrice,
      .--labelPrice {
        font-size: 13px;
        color: $ui-09;
      }
  
      .--labelPrice {
        word-break: break-all;
      }
    }
    &__mobile {
        display: none;
    }
    &__desktop {
        display: block;
    }
    &__bv-review {
        min-height: 20px;
    }
    &__foot {
        @include flex(wrap);
        align-items: center;
        width: 100%;

        & .bra-prodTile__rating {
            display: inline-flex;
            flex-wrap: wrap;
            align-items: center;
            width: auto;
            height: 20px;

            & .bra-prodTile__stars {
                @include flex(wrap);
                width: 78px;
                height: 20px;
                margin: 0 6px 0 0;

                & .bra-icn {
                    margin: 0 2px 0 0;

                    &:last-of-type {
                        margin: 0;
                    }
                    &__outline-star {
                        &::before {
                            color: $ui-09;
                        }
                    }
                    &__star {
                        &::before {
                            color: $support-02;
                        }
                    }
                }
            }
            & .bra-prodTile__reviewCounter {
                display: inline-block;
                width: auto;
                height: 20px;
                line-height: 20px;
                font-size: 11px;
            }
        }
        & .bra-input__wrapper {
            align-items: center;
            margin: 0 0 0 auto;

            & .bra-input__input {
                order: 3;
                width: 20px;
                min-width: initial;
                max-width: none;
                height: 20px;
                min-height: initial;
                max-height: none;
                margin: 0 0 0 5px;
                position: relative;
                z-index: 1;
            }
            & .bra-input__label {
                @include fontBold;
                order: 1;
                height: 20px;
                font-size: 11px;
                padding: 0;

                &--sup {
                    order: 2;
                    height: 20px;
                    line-height: 20px;
                    font-size: 11px;
                    margin: 0 0 0 3px;
                }
            }
        }
    }
    &__details {
        @include flex(wrap);
        width: 100%;
        height: auto;
        border-radius: 0 0 5px 5px;
        background-color: $ui-07;
        opacity: 0;
        transition: opacity 0.18s linear;
        padding: 15px 20px 20px 20px;
        box-sizing: border-box;
        overflow: hidden;
        pointer-events: none;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 4;

        &:hover {
            opacity: 1;
            pointer-events: initial;

            & + .bra-prodTile__shadow {
                opacity: 1;
            }
        }
        &::before {
            content: '';
            display: block;
            width: calc(100% - 40px);
            height: 1px;
            background-color: $ui-08;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 20px;
        }
        &List {
            @include flex(wrap);
            width: 100%;
            list-style: outside;
            list-style-type: disc;
            padding: 0 0 0 20px;
            margin: 0 0 15px 0;
        }
        &Item {
            // display: block;
            width: 100%;
            height: auto;
            line-height: 22px;
        }
        & .bra-btn {
            width: 100%;
            margin: 0 0 10px 0;

            &:last-of-type {
                margin: 0;
            }
            &--outOfStock {
                border-color: $ui-09;
                background-color: $ui-09;
                pointer-events: none;
            }
        }
    }
    &.hover .bra-prodTile__details {
        opacity: 1;
        pointer-events: initial;

        & + .bra-prodTile__shadow {
            opacity: 1;
        }
    }
    &__shadow {
        width: 100%;
        height: 100%;
        border-radius: 5px;
        box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
        opacity: 0;
        transition: opacity 0.18s linear 0.08s;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
    }
    &.hover .bra-prodTile__product,
    & .bra-prodTile__product:hover {
        border-radius: 5px 5px 0 0;

        & + .bra-prodTile__details {
            opacity: 1;
            pointer-events: initial;

            & + .bra-prodTile__shadow {
                opacity: 1;
            }
        }
    }
    &.hover,
    &:hover {
        z-index: 2;
    }

    &--compare {
        height: auto;

        & .bra-prodTile__head {
            justify-content: flex-end;
            height: 12px;
            margin: 0 0 23px 0;

            & .bra-btnIcon {
                width: 12px;
                height: 12px;
                font-size: 12px;

                & .bra-btnIcon__wrap {
                    width: 12px;
                    height: 12px;

                    & .bra-icn {
                        width: 12px;
                        height: 12px;
                        line-height: 12px;
                        font-size: 12px;
                    }
                }
            }
        }
        & .bra-prodTile__foot {
            flex-direction: column;
            justify-content: center;
            height: auto;

            & .bra-prodTile__rating {
                margin-bottom: 15px;
            }
            & .bra-btn {
                width: 100%;
            }
        }
        &.bra-prodTile--collapsed {
            & .bra-prodTile__product {
                align-items: center;
            }
            & .bra-prodTile__head {
                width: 10px;
                height: 10px;
                margin: 0;
                position: absolute;
                top: 10px;
                right: 10px;

                & .bra-btnIcon {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 10px;
                    height: 10px;
                    line-height: 10px;

                    & .bra-btnIcon__wrap {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        line-height: 10px;

                        & .bra-icn {
                            display: inline-block;
                            line-height: 10px;
                            font-size: 10px;
                        }
                    }
                }
            }
            & .bra-prodTile__imgWrap {
                width: 80px;
                height: 80px;
                margin: 0 20px 0 0;
            }
            & .bra-prodTile__productInfo {
                width: calc(100% - 80px - 20px);
                margin: 0;

                & .bra-prodTile__name,
                & .bra-prodTile__type {
                    line-height: 16px;
                    font-size: 13px;
                    text-align: left;
                }
            }
            & .bra-prodTile__priceWrap,
            & .bra-prodTile__foot {
                display: none;
            }
        }
    }
}