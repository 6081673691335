.bra-textbox {
	max-width: calc(1440px - 60px);
	margin: 0 30px;
	font-size: 15px;
	line-height: normal;

	&--two-columns {
		.bra-textbox__wrapper {
			.bra-paragraph {
				column-count: 2;
				column-gap: 1em;
			}
		}
	}

	&--two-columns,
	&--text-aside {
		.bra-textbox__wrapper {
			.bra-paragraph {
				text-align: left;

				[dir="rtl"] & {
					text-align: right;
				}
			}
		}
	}

	& .bra-title {
		font-size: 36px;
		line-height: 1.16;
	}

	& .bra-paragraph {
		font-size: 15px;
		line-height: 1.35;
		text-align: center;
	}
}
