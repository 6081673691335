.dln-drawer {

    display: none;
    min-width: 170px;
    line-height: normal;
    font-size: 15px;

    color: $brand-01;
    text-align: center;
    border-radius: 5px;
    background: white;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.2);
    visibility: hidden;
    transform: translateX(-50%);
    padding: 20px;

    box-sizing: border-box;

    position: absolute;
    top: calc(100% + 30px);
    left: 50%;
    z-index: 2000;

    // &:after {
    //     bottom: 100%;
    //     left: 50%;
    //     border: solid transparent;
    //     content: " ";
    //     height: 0;
    //     width: 0;
    //     position: absolute;
    //     pointer-events: none;
    //     border-color: rgba(255, 255, 255, 0);
    //     border-bottom-color: #ffffff;
    //     border-width: 6px;
    //     margin-left: -6px;
    // }

    .txt_container {
        .icon {
            display: block;

            .dln-icn {
                color: $brand-01;
            }
        }
        .text {
            @include fontBold;
            line-height: 21px;
            font-size: 15px;
        }

        & + .cta_container {
            margin-top: 10px;
        }
    }
    .cta_container {
        .text {
            display: block;
            line-height: 20px;

            font-size: 14px;
            margin-bottom: 20px;

            a {
                line-height: 20px;
                font-size: 14px;
                color: $brand-01;
            }
        }

        .ctas {
            .dln-btn {

                @include fontBold;
                outline: none;
                display: block;
                width: auto;
                line-height: 21px;
                font-size: 14px;
                color: $brand-03;
                text-align: center;

                text-decoration: none;
                white-space: nowrap;
                /* reset */
                border: none;
                background-color: $brand-01;
                box-shadow: none;
                transition: all 0.3s linear;
                padding: 10px;
                // margin: 10px 0;
                -webkit-appearance: none;
                -moz-appearance: none;
                overflow: hidden;
                overflow: hidden;
                cursor: pointer;
                user-select: none;
            }
        }
    }
    // .userNotLogged {
    //     > .dln-btn {
    //         display: block;

    //         @include fontBold;
    //         outline: none;
    //         width: auto;
    //         line-height: 21px;
    //         font-size: 14px;
    //         color: $brand-03;
    //         text-align: center;
    //         /* reset */
    //         border: none;
    //         background-color: $brand-01;
    //         box-shadow: none;
    //         transition: all 0.3s linear;
    //         padding: 10px;
    //         // margin: 10px 0;
    //         -webkit-appearance: none;
    //         -moz-appearance: none;
    //         overflow: hidden;
    //         overflow: hidden;
    //         cursor: pointer;
    //         user-select: none;

    //         text-decoration: none;
    //         white-space: nowrap;
    //     }
    // }
    hr {
        width: calc( 100% + 40px );
        height: 1px;
        border: 0;
        background: $ui-08;
        margin: 20px -20px;
    }

    .utilityLinks {
        text-align: left;

        a {
            display: block;
            font-size: 15px;
        }

        a + a {
            margin-top: 5px;
        }
    }

    .user {
        font-size: 15px;
        color: $ui-09;
        text-align: left;

        a {
            font-size: 15px;
        }

        a.bra-btn--rounded {
            width: 100%;
            margin-bottom: 20px;
        }


        &--logged {
            .greetingMessage {
                @include fontBold;
                color: $support-02;
            }
        }
    }

    &--slim {
        padding: 15px;
        .txt_container {
            display: flex;
            align-items: center;

            .icon {
                margin-right: 9px;
            }
        }
    }

    &--account {
        width: calc(100vw - 40px);
        // min-width: 280px;
        // box-sizing: content-box;
        max-width: 280px;
    }
}