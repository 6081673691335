@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-recipe-box {
        &--big-card {
            .bra-recipe-box__title {
                font-size: 15px;
                line-height: 19px;
                padding-bottom: 7px;
            }
            .bra-recipe-box__details {
                font-size: 13px;
            }
        }
    }
}
