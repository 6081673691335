// less than 768px wide
@media only screen and (max-width: $braun-s - 1) {
	.bra-chemicalmixes {

        &__flex-grid_item {
            flex-direction: column;
            width: calc(100% - 40px);
            padding: 0 20px;
        }

        &__text {
            font-size: 13px;
        }

        &__title {
            font-size: var(--fontSize);
        }
    }
}