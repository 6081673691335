.bra-radio {
    @include flex(wrap);
    align-items: flex-start;
    width: 100%;
    height: auto;
    position: relative;
    z-index: 1;

    &__input {
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 34;
    }
    &__placeholder {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 1px solid $ui-09;
        border-radius: 50%;
        background-clip: content-box;
        padding: 3px;
        margin: 0 10px 0 0;
        box-sizing: border-box;
        position: relative;
        z-index: 2;

        // &Color {
        //     display: inline-block;
        //     width: 14px;
        //     height: 14px;
        //     border-radius: 50%;
        //     background-color: $ui-09;
        //     position: absolute;
        //     top: calc(50% - 7px);
        //     left: calc(50% - 7px);
        //     z-index: 3;
        // }
    }
    &__input:checked + .bra-radio__placeholder {
        border: 2px solid $brand-01;
        padding: 2px;
    }
    &__label {
        display: block;
        width: calc(100% - 20px - 10px);
        line-height: 20px;
        font-size: 15px;
    }
    // &[class*="color"] {
    //     & .bra-radio__placeholder::after {
    //         display: inline-block;
    //     }
    // }
    // &--color-black {
    //     & .bra-radio__placeholder::after {
    //         background-color: $brand-01;
    //     }
    // }
    // &--color-white {
    //     & .bra-radio__placeholder::after {
    //         background-color: $brand-03;
    //     }
    // }
    // &--color-gray {
    //     & .bra-radio__placeholder::after {
    //         background-color: $brand-02;
    //     }
    // }
    // &--color-blue {
    //     & .bra-radio__placeholder::after {
    //         background-color: $bra-lochmara;
    //     }
    // }

    &--text {
        height: 95px;
    }
    &--box {
        display: inline-flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        // width: 108px;
        height: 95px;

        & .bra-radio {
            &__placeholder {
                width: 30px;
                height: 30px;
                color: $ui-09;
                border: none;
                // border: 1px solid $ui-09;
                border: 1px solid transparent;
                background-clip: initial;
                margin-right: 0;
                margin-bottom: 12px;

                // &Color {
                //     display: none;
                //     width: 28px;
                //     height: 28px;
                //     border-radius: 50%;
                //     position: absolute;
                //     top: 50%;
                //     left: 50%;
                //     transform: translate(-50%, -50%);
                //     z-index: 3;
                // }
            }
            &__border-hack {
                width: 100%;
                height: 100%;
                border: 1px solid $ui-08;
                border-radius: 5px;
                box-sizing: border-box;
                position: absolute;
                top: 0;
                left: 0;
            }
            &__text {
                @include fontBold;
                width: auto;
                height: auto;
                line-height: normal;
                font-size: 24px;
                border: none !important;
                margin-bottom: 8px;
            }
            &__input:checked + .bra-radio__placeholder {
                color: $brand-01;
                border: 1px solid $brand-01;

                & + .bra-radio__label {
                    color: $brand-01;

                    & + .bra-radio__border-hack {
                        border: 1px solid $brand-01;

                    }
                }
            }
            &__label {
                width: auto;
                font-size: 13px;
                color: $ui-09;
                text-align: center;
            }
        }
    }
}

// Color swatch variant
.bra-color-swatch {
    width: 125px;
    height: 156px;
    border-radius: 5px;
    border: 1px solid $ui-09;

    &--selected {
        border-color: $brand-01;

        .bra-color-swatch__label {
            color: $brand-01;
        }
    }

    &__img {
        width: 115px;
        height: 115px;
    }

    &__label {
        color: $ui-09;
        font-size: 13px;
    }
}