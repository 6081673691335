.dln-popup {
  & &__content &__wrapper {
    .dln-search {
      .dln-popup {
        &__brandLogo {
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;

          .dln-braun &,
          .dln-delonghi & {
            top: 14px;
            padding: 0 20px;
          }

          .dln-kenwood & {
            top: 24px;
            padding: 0 20px;

            @media screen and (min-width: 768px) {
              position: static;
              margin: -80px 0 0 0;
            }
          }

          &__link {
            width: fit-content;
          }

          &__image {
            width: auto;
            height: 24px;

            .dln-braun &,
            .dln-delonghi & {
              @media screen and (min-width: 768px) {
                height: 35px;
              }
            }

            .dln-kenwood & {
              @media screen and (min-width: 1440px) {
                height: 27px;
              }
            }
          }
        }
      }
    }
  }
}
