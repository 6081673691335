.bra-card {
  --txtColor: #{$brand-01};
  --supportColor: #{$support-02};
  $bgColor: $brand-03;

  width: 100%;
  line-height: normal;

  font-size: 15px;
  border-radius: 5px;

  transform: translate3d(0, 0, 0);
  margin-bottom: 20px;
  overflow: hidden;

  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;

    border-radius: 5px;
    background-color: $brand-03;
    overflow: hidden;
  }

  &__link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
  }

  &__visual {
    order: 0;
    width: 100%;
    height: auto;
    overflow: hidden;

    .bra-image {
      img {
        width: 100%;
        height: calc(100% + 40px);
      }
    }
  }
  &__content {
    order: 1;
    padding: 40px;
    padding-bottom: 35px;

    .bra-subtitle,
    .bra-title,
    .bra-paragraph {
      color: $brand-01;
    }

    .bra-subtitle {
      line-height: 22px;
      font-size: 18px;
      margin-bottom: 15px;
    }
    .bra-title {
      line-height: 30px;
      font-size: 24px;
      margin-bottom: 5px;
    }
    .bra-paragraph {
      line-height: 22px;
      font-size: 15px;
    }
  }
  &__actions {
    order: 2;
    padding: 40px;
    padding-top: 0;
  }
  &__productList {
    order: 3;
  }

  &__price {
    flex: 1;

    .--list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 5px;
      list-style: none;
      margin-top: 2px;
    }

    .--mainPrice {
      @include fontMedium;
      font-size: 15px;
      color: $brand-01;
    }

    .--info {
      display: flex;
      gap: 5px;
    }

    .--strikePrice {
      text-decoration: line-through;
      order: 1;
    }

    .--strikePrice,
    .--labelPrice {
      font-size: 13px;
      color: $ui-09;
    }

    .--labelPrice {
      word-break: break-all;
    }
  }

  /* VARIANTS */

  &--bg-image {
    min-height: 45vw;

    .bra-card__visual {
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 1;
      .bra-image {
        height: calc(100% + 40px);
        padding-bottom: 0;
      }

      &::after {
        content: '';
        width: 100%;
        height: 50%;
        background-image: linear-gradient(
          to top,
          rgba(black, 0),
          rgba(black, 1)
        );
        opacity: 0.65;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .bra-card__content {
      padding-bottom: 35px;
      z-index: 2;

      .bra-subtitle,
      .bra-title {
        color: $brand-03;
      }
    }
    .bra-card__actions {
      padding: 40px;
      padding-top: 0;
      z-index: 2;
    }
  }

  &--horizontal {
    @include hover-supported() {
      &:hover {
        .bra-btnIcon--circle {
          background: $brand-03;
          transition: background 0.2s ease-in-out;
          .bra-btnIcon__circle {
            background: $brand-03;
            transition: background 0.2s ease-in-out;
            .bra-icn {
              color: $brand-01;
              transition: color 0.2s ease-in-out;
            }
          }
          .bra-icn {
            color: $brand-01;
            transition: color 0.2s ease-in-out;
          }
        }
      }
    }
    grid-template-areas: 'title visual' 'action visual' 'action visual';
    .bra-card__wrapper {
      grid-template-columns: calc(60% - 10px) calc(40% - 10px);
      grid-gap: 0 20px;
      grid-template-rows: min-content min-content max-content;
      grid-template-areas: 'title visual' 'action visual' 'action visual';

      -ms-grid-columns: 60% 40%;

      display: grid;
      height: 100%;
      border-radius: 0;

      background: transparent;
      overflow: initial;
    }

    .bra-card__content {
      grid-area: title;
      display: block;
      order: 1;
      width: 100%;
      padding: 0;

      .bra-subtitle,
      .bra-title {
        color: $brand-01;
      }

      .bra-subtitle {
        @include font;
        line-height: 22px;
        font-size: 15px;
        margin-bottom: 10px;
      }
      .bra-title {
        line-height: 30px;
        font-size: 24px;
      }

      .bra-btnIcon {
        margin-top: auto;
      }
    }

    .bra-card__visual {
      grid-area: visual;
      order: 2;
      width: 100%;
      height: auto;
      border-radius: 5px;
      padding-bottom: 0;
      overflow: hidden;

      .bra-image {
        height: auto;
        border-radius: 5px;
        transform: none;
        padding-bottom: 0;
      }
    }
    .bra-card__actions {
      @media not all and (hover: none), (-ms-high-contrast: none) {
        height: 40px;
      }

      flex: 1;
      grid-area: action;
      order: 3;
      width: 100%;
      padding: 0;
      padding-top: 22px;
      margin-top: auto;
    }
  }

  &--category {
    @each $key, $val in $colors-list {
      &.bra-theme-color--#{$key} {
        @include hover-supported() {
          &:hover {
            background-color: #{$val} !important;
          }
        }
      }
    }
    @include hover-supported() {
      &:hover {
        .bra-card__visual {
          .bra-card__visual--default {
            opacity: 0;
            transform: translateY(-5%);

            transition: opacity 0.3s linear, transform 0.3s linear;
          }

          .bra-card__visual--hover {
            opacity: 1;
            transform: translateY(0%);

            transition: opacity 0.3s linear, transform 0.3s linear;
          }
        }
        .bra-btnIcon--circle {
          background: $brand-03;
          transition: background 0.2s ease-in-out;
          .bra-btnIcon__circle {
            background: $brand-03;
            transition: background 0.2s ease-in-out;
            .bra-icn {
              color: $brand-01;
              transition: color 0.2s ease-in-out;
            }
          }
          .bra-icn {
            color: $brand-01;
            transition: color 0.2s ease-in-out;
          }
        }
      }
    }
    width: 100%;
    height: 0;

    background: $brand-03;
    transition: background 0.3s linear;
    padding-bottom: 100%;

    .bra-card__wrapper {
      background: transparent;
    }
    & .bra-title--category {
      margin-bottom: 5px;
    }
    .bra-card__visual {
      order: 3;
      padding-bottom: 100%;
      position: absolute;

      .bra-image {
        background: transparent;
      }

      .bra-card__visual--default,
      .bra-card__visual--hover {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .bra-card__visual--default {
        opacity: 1;
        transform: translateY(0%);

        transition: opacity 0.3s linear, transform 0.3s linear;
      }

      .bra-card__visual--hover {
        opacity: 0;
        transform: translateY(5%);

        transition: opacity 0.3s linear, transform 0.3s linear;
      }
    }

    .bra-card__content {
      order: 1;
      padding: 30px 20px;
      z-index: 2;
    }
    .bra-card__actions {
      order: 2;
      padding: 20px;
      margin-top: auto;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;

      a.bra-btnIcon::before {
        content: '';
        display: block;
        width: 100vw;
        height: 100vh;
        background: #ff000000;
        position: absolute;
        top: -80vh;
        left: -70vh;
        z-index: -1;
      }
    }
  }

  &--recipe {
    @include hover-supported() {
      &:hover {
        .bra-card__actions {
          .bra-btn {
            transition: border-color 0.12s linear, background-color 0.12s linear,
              color 0.12s linear;

            &:hover {
              transition: border-color 0.12s linear,
                background-color 0.12s linear, color 0.12s linear;
            }
          }
        }
      }
    }
  }

  &--sub-category {
    background-color: $ui-07;
  }

  &--recipe {
    .bra-card__visual {
      flex-grow: 1;
      display: flex;
      width: 100%;
      height: 100%;
      padding-bottom: 0;
      position: relative;
      z-index: 1;

      .bra-image {
        height: auto;
        padding-bottom: 0;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          transform: scale3d(1, 1, 1);
          position: relative;
          top: 0;
          left: 0;
        }
      }

      &::after {
        content: '';
        width: 100%;
        height: 50%;
        background-image: linear-gradient(
          to bottom,
          rgba(black, 0),
          rgba(black, 1)
        );
        opacity: 0.65;
        transform: translate3d(0, 0, 0);
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    .bra-card__content {
      transform: translateY(0) translate3d(0, 0, 0);
      transition: none;
      padding: 30px;
      position: absolute;
      bottom: 0;
      z-index: 2;

      .bra-subtitle,
      .bra-title {
        color: $brand-03;
      }
    }
    .bra-card__actions {
      transform: translateY(70px);
      transform: translate3d(0, 0, 0);

      transition: none;
      padding: 30px;
      position: absolute;
      bottom: 0;

      z-index: 2;

      .bra-btn {
        transition: none;
      }
    }
  }

  &--product {
    @include hover-supported() {
      &:hover {
        .bra-card__visual {
          .bra-image {
            transform: scale3d(1.09, 1.09, 1.09) translate3d(0, 0, 0);
            transition: transform 0.35s ease-in-out;
          }
        }
        .bra-btnIcon--circle {
          background: $brand-03;
          transition: background 0.2s ease-in-out;
          .bra-btnIcon__circle {
            background: $brand-03;
            transition: background 0.2s ease-in-out;
            .bra-icn {
              color: $brand-01;
              transition: color 0.2s ease-in-out;
            }
          }
          .bra-icn {
            color: $brand-01;
            transition: color 0.2s ease-in-out;
          }
        }
      }
    }
    background: $ui-07;

    .bra-card__wrapper {
      background: $ui-07;
      position: relative;
    }
    .bra-card__visual {
      position: relative;

      .bra-image {
        max-width: 250px;
        background: transparent;

        transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
        transition: transform 0.35s ease-in-out;
        margin: 0 auto;
      }

      .bra-card__badgesList {
        list-style: none;
        position: absolute;
        top: 0;
        right: $padding;

        .bra-card__badge {
          @include fontBold;
          line-height: 1;
          font-size: 15px;
          color: white;
          border-radius: 5px;
          background: $support-02;
          padding: 5px 10px;
        }
      }
    }
    .bra-card__content {
      order: 0;
      text-align: center;
      padding: 25px 20px 20px 20px;
    }

    .bra-card__actions {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 20px;
      padding-top: 30px;
    }
  }

  &--productV2 {
    @include hover-supported() {
      &:hover {
        .bra-card__visual {
          .bra-image {
            transform: scale3d(1.09, 1.09, 1.09) translate3d(0, 0, 0);
            transition: transform 0.35s ease-in-out;
          }
        }
        .bra-btnIcon--circle {
          background: $brand-03;
          transition: background 0.2s ease-in-out;
          .bra-btnIcon__circle {
            background: $brand-03;
            transition: background 0.2s ease-in-out;
            .bra-icn {
              color: $brand-01;
              transition: color 0.2s ease-in-out;
            }
          }
          .bra-icn {
            color: $brand-01;
            transition: color 0.2s ease-in-out;
          }
        }
      }
    }
  }

  &--productV2 {
    background: $ui-07;

    .bra-card__wrapper {
      background: $ui-07;
      padding-top: 20px;
      position: relative;
    }
    .bra-card__visual {
      width: auto;
      padding: 20px 20px 15px 20px;
      position: relative;

      .bra-image {
        max-width: 250px;
        background: $brand-03;
        transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
        transition: transform 0.35s ease-in-out;
        margin: 20px auto 0;
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          background-color: rgba($brand-01, 0.04);
          position: absolute;
          bottom: 0;
          left: 0;
        }
        & > .bra-image__image {
          object-fit: contain;
          object-position: center top;
        }
      }
      .bra-card__badgesList {
        display: flex;
        width: calc(100% - 70px);
        list-style: none;
        position: absolute;
        top: 0;
        left: $padding;

        .bra-card__badge {
          @include fontBold;
          display: flex;
          align-items: center;
          height: 20px;
          line-height: 1;
          font-size: 13px;
          color: white;
          border-radius: 5px;
          background: $support-02;
          padding: 0 10px;

          + .bra-card__badge {
            margin-left: 5px;
          }
        }
      }

      .bra-btnIcon {
        position: absolute;
        top: 0;
        right: $padding;
        z-index: 11;
        .bra-icn__wishlist-inactive:before {
          color: $ui-09;
        }
        .bra-icn__wishlist-active:before {
          color: $support-02;
        }
      }
    }
    .bra-card__content {
      order: 1;
      line-height: 1.27;
      padding: 0 20px 15px 20px;

      h3 {
        @include fontBold;
        line-height: 19px;
        font-size: 15px;
      }
    }

    .bra-card__price {
      margin-top: 5px;
    }
    .bra-card__actions {
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: space-between;
      padding: 20px;
      padding-top: 30px;
    }

    .bra-card__productList {
      line-height: 1.69;
      // list-style-type: disc;
      list-style: none;
      border-top: 1px solid $ui-08;
      padding-top: 15px;
      margin: 0 20px 20px;

      li {
        display: flex;
        line-height: 22px;
        font-size: 13px;
        margin-bottom: 5px;
      }

      li:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: black;
        margin-top: 10px;
        margin-right: 20px;
      }
    }
  }

  &--crosslink {
    .bra-subtitle {
      font-size: 17px;
    }
    .bra-title {
      font-size: 26px;
    }
  }

  &--box {
    background: $ui-07;

    & .bra-card {
      &__visual {
        padding: 20px 0 0 0;

        & .bra-image {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          max-width: 210px;
          height: 280px;
          margin: 0 auto;
          position: relative;

          &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background: rgba($brand-01, 0.04);
            position: absolute;
            top: 0;
            left: 0;
          }
          & img {
            object-position: center;
            display: block;
            width: 100%;
            height: 100%;
            background: $ui-07;
            object-fit: contain;
          }
        }
      }
      &__wrapper {
        height: 100%;
        background: $ui-07;
      }
      &__content {
        text-align: center;
        padding: 20px 25px;

        .bra-title {
          font-size: 15px;
          margin-bottom: 0;
        }
      }
    }
    .bra-btn {
      order: 3;
      width: 160px;
      margin: 0 auto;
    }
    &.bra-card--empty {
      & .bra-card__visual {
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(100% - 55px);
      }
    }
    & .bra-logo__wrapper {
      margin: 0;

      & .bra-logo {
        display: block;
        width: 60px;
        height: 25px;
      }
    }
  }

  &--highlights {
    .bra-card {
      &__wrapper {
        border: 1px solid $ui-08;
      }

      &__content {
        padding: 25px 30px 30px;
        .bra-title {
          line-height: 1.33;
          font-size: 18px;
          margin-bottom: 5px;
        }
      }
    }
  }
}
