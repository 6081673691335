@media only screen and (max-width: $braun-s - 1) {
	.bra-recipe-tiles {
        padding: 0;
        margin: 0;
        
		.swiper-container.recipe-gallery {
			.swiper-slide {
				width: auto;
				max-width: calc( calc( 100vw - 20px ) / 1 - 20px);
			}
		}
	}
}
