.bra-category-shelf__card {
  position: relative;
  background-color: $ui-07;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &-top {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 20px 0 20px;

    @media screen and (min-width: $braun-l) {
      padding: 30px 30px 0 30px;
    }
  }

  &-info {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $brand-01;
    transition: color 0.3s ease-in-out;

    &.-hover,
    &.-hover .bra-category-shelf__card-priceOld {
      color: $brand-03;
    }
  }

  &-title,
  &-subtitle {
    margin: 0;
    font-size: 21px;
    line-height: 26px;
    @include font;
    @include lineClamp(1);

    @media screen and (min-width: $braun-l) {
      font-size: 24px;
      line-height: 27px;
    }
  }

  &-title {
    @include fontBold;
  }

  &-subtitle {
    @include lineClamp(2);
  }

  &-price {
    margin: 10px 0 0;
    font-size: 15px;
    line-height: 19px;
    min-height: 20px;
    @include font;
  }

  &-priceFrom {
    @include fontBold;
  }

  &-priceOld {
    transition: color 0.3s ease-in-out;
    color: $ui-09;
    text-decoration: line-through;
  }

  &-link-wrapper {
    margin-top: 20px;
    min-height: 40px;
  }

  &-image {
    display: block;
    margin-top: 20px;
    align-self: center;
    max-width: 100%;
  }

  &-mask {
    position: absolute;
    display: none;
    opacity: 0;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to bottom,
      rgba($brand-01, 0.7) 0%,
      transparent 70%
    );
  }

  &-imageHover {
    display: none;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-details-wrapper {
    display: none;
    opacity: 0;
    height: 0;
    width: 100%;
    padding: 0 20px 20px 20px;

    @media screen and (min-width: $braun-l) {
      padding: 0 30px 30px 30px;
    }
  }

  &-detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1px solid $ui-02;
    padding: 15px 0;
  }

  &-detail-title,
  &-detail-subtitle {
    margin: 0;
    font-size: 15px;
    line-height: 19px;
    @include font;
  }

  &-detail-title {
    @include fontBold;
  }
}
