.bra-cookieDiscalimer {
	font-size: 15px;
	line-height: normal;

	position: fixed;
	bottom: 0;
	left: 0;

	transform: translateY(100%);
	transition: transform 0.4s ease-in-out;

	background: rgba($brand-01,.8);
	padding: 20px;

	&.visible {
		transform: translateY(0%);
        transition: transform 0.4s ease-in-out;
        z-index: 2000;
	}

	&__wrapper {
		margin: 0 auto;

		display: flex;
		align-items: center;
	}

	.bra-paragraph {
		color: $brand-03;
	}

	button {
		@include fontBold;
		padding-left: 2em;
		padding-right: 2em;

		margin-left: 2em;
	}
}