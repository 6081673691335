@media only screen and (max-width: $braun-s - 1) {
    .bra-grid-box {
        &__grid {
            width: 100%;
            margin: 0;
        }
        .bra-card {
            width: calc(50% - 5px);
            // flex: 0 1 calc(50% - 5px);
            flex: initial;
            margin: 0 10px 10px 0;

            &:nth-of-type(4n+4) {
                margin: inherit;
            }
            &:nth-of-type(2n+2) {
                margin: 0 0 10px 0;
            }
            &__visual {
                padding: 15px 15px 0 15px;
            }
            &__content {
                padding: 10px 15px;

                & .bra-title {
                    font-size: 11px;
                }
            }
        }
    }
}
