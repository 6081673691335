.bra-frequently-bought-together {
    width: 100%;

    &__title {
        @include fontBold;
        font-size: 24px;
        line-height: 0.92;
        margin-bottom: 36px;
    }
    &__badge {
        font-size: 18px;
        height: 40px;
        width: 40px;
        position: absolute;
        top: 15px;
        right: 15px;
        color: $brand-03;
        background-color: $brand-01;
        z-index: 1;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__box {
        border: 1px solid $ui-08;
        border-radius: 5px;
        padding: 30px;
        width: calc(100% - 60px);
        overflow: hidden;
    }
    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;

        .swiper-container {
            width: calc(100% - 230px);
            overflow: visible;

            .swiper-scrollbar {
                display: none;
            }
            .swiper-slide + .swiper-slide:before {
                @include fontBold;
                font-size: 30px;
                line-height: 35px;
                content: '+';
                text-align: center;
                width: 40px;
                box-sizing: border-box;
                border: 1px solid $ui-07;
                height: 40px;
                background: $brand-03;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translate(calc(-50% - 10px), -50%);
                z-index: 1;
                border-radius: 50%;
            }

        }

        .bra-btn {
            margin-top: 20px;
        }

        .bra-card--productV2 {
            margin: 0;
        }
    }
    &__add-to-cart {
        padding: 50px 20px 50px 50px;
        flex: 1 1 160px;
    }
    &__price {
        @include fontBold;
        font-size: 24px;
        line-height: 1.25;
        margin-bottom: 5px;
    }
    &__price-total {
        @include font;
        font-size: 18px;
        line-height: 1.33;
        color: $ui-09;
        padding-right: 10px;
        // padding: 0 10px 0 20px;
        text-decoration: line-through;
    }
    &__discount {
        font-size: 18px;
        line-height: 1.33;
    }
}