.bra-where-to-buy {
    box-sizing: border-box;
    padding: 30px;
    max-width: 910px;

    .dln-popup__headTitle {
        text-align: left;
    }
    .dln-image {
        border: none;
    }
    .bra-btn {
        min-width: none;
    }

    &__grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px -20px;
        box-sizing: border-box;
    }
    
    &__item {
        flex: 1 0 33.33%;
        max-width: 33.33%;
        padding: 0 10px;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    &__box {
        border: 1px solid $ui-08;
        border-radius: 5px;
        box-sizing: border-box;
        text-align: center;
        padding: 20px 30px;
    }
    &__price-section {
        margin: 10px 0 20px;
        font-size: 15px;
        line-height: 1.47;
    }
    &__availability {
        color: $support-02;
    }
}