.dlg-omnibus-price {
  display: flex;
  flex-direction: column;
  gap: 5px;
  list-style: none;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    align-items: flex-end;
  }

  li {
    display: flex;
    flex-direction: column;
    gap: 5px;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  &__main-price,
  &__strike-price {
    flex-shrink: 0;
  }

  &__strike-price {
    text-decoration: line-through;
  }
}
