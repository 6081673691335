.bra-compareTray {
    @include flex(wrap);
    align-content: flex-start;
    background: $brand-03;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
    transform-origin: 50% 100%;
    transition: top 0.35s ease;
    overflow: hidden;
    position: fixed;
    top: calc(100% - 243px);
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;

    &__main {
        @include flex(wrap);
        align-content: flex-start;
        width: 100%;
        background-color: $brand-03;
        opacity: 1;
        transition: opacity 0.22s linear;
        box-sizing: border-box;
    }
    &__head {
        @include flex(wrap);
        align-items: center;
        width: 100%;
        height: 58px;
        padding: 0 30px;
        box-sizing: border-box;
        cursor: pointer;
        position: relative;
    }
    &__title {
        width: calc(100% - 32px - 16px);
        height: 58px;
        line-height: 58px;
        font-size: 18px;
        white-space: nowrap;
        text-overflow: ellipsis;

        & strong {
            @include fontBold;
        }
    }
    &__head {
        & .bra-icn__arrow--down {
            display: inline-block;
            width: 16px;
            height: 10px;
            font-size: 0;
            opacity: 1;
            transform: rotateX(0deg);
            transform-origin: center;
            transition: transform 0.18s ease, opacity 0.12s linear;
            margin: 0 0 0 auto;

            &::before {
                display: inline-block;
                width: 16px;
                height: 10px;
                line-height: 10px;
                font-size: 16px;
            }
        }
        & .bra-btnIcon--closeTray {
            width: 19px;
            height: 19px;
            line-height: 19px;
            font-size: 19px;
            opacity: 0;
            pointer-events: none;
            position: absolute;
            top: calc(50% - 8px);
            right: 28px;

            & .bra-btnIcon__wrap {
                display: inline-block;
                width: 19px;
                height: 19px;

                & .bra-icn {
                    display: inline-block;
                    width: 19px;
                    height: 19px;
                    font-weight: 600;

                    &::before {
                        font-size: 19px;
                    }
                }
            }
        }
    }
    &__actions {
        @include flex(wrap);
        align-content: center;
        justify-content: center;
        width: 25%;
        padding: 0 20px 20px 50px;
        box-sizing: border-box;

        & .bra-btn {
            width: 100%;
            margin: 0 0 10px 0;

            &:last-of-type {
                margin: 0;
            }
        }
    }
    &__products {
        @include flex(wrap);
        width: 75%;
        padding: 0 30px 20px 20px;
        box-sizing: border-box;


        & .bra-prodTile {
            width: calc(33.33% - 13.34px);
            margin: 0 20px 0 0;

            &:nth-child(3) {
                margin: 0;
            }
        }
    }
    &__addProd {
        @include flex(wrap);
        align-items: center;
        justify-content: center;
        width: calc(33.33% - 13.34px);
        height: 120px;
        border: none;
        border-radius: 5px;
        background-color: $ui-07;
        margin: 0 20px 0 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: default;

        &:nth-child(3),
        &:last-child,
        &:only-child {
            margin: 0;
        }
        & .bra-icn__plus {
            &::before {
                font-size: 16px;
                color: $brand-03;
            }
        }
    }
    &--collapsed {
        top: calc(100% - 58px);

        & .bra-compareTray__head .bra-icn__arrow--down {
            transform: rotateX(180deg);
        }
    }
    &--expanded {
        overflow-y: scroll;
        top: 0;

        & .bra-compareTray__main {
            &--sticky {
                box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.25);
                padding: 0 15px 0 0;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 2;
            }
        }
        & .bra-compareTray__head {
            height: 100px;
            cursor: default;

            & .bra-compareTray__title {
                height: 100px;
                line-height: 100px;
                font-size: 24px;
            }
            & .bra-icn__arrow--down {
                opacity: 0;
            }
            & .bra-btnIcon--closeTray {
                opacity: 1;
                pointer-events: initial;
            }
        }
        & .bra-compareTray__actions {
            & .bra-btn {
                display: none;
            }
        }
        & .bra-compareTray__addProd {
            cursor: pointer;
        }
    }
}