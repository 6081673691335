.bra-content-slider {
	font-size: 15px;
	line-height: normal;

	.swiper-container.content-slider {		
		.swiper-slide {
			display: flex;
			height: auto;
			border-radius: 5px;
			// box-shadow: inset 0 0 0 1px $ui-08;
			border: 1px solid $ui-01;
			overflow: hidden;
			max-width: calc(100% - 60px);			
			box-sizing: border-box;

			.bra-card {
				margin: 0;
				.bra-card__wrapper {
					background: transparent;
				}
			}

			.bra-sideCard {
				--textAlign: left;
				background: transparent;
				margin: 0;
				max-width: 1440px;

				&__textContent {
					background: transparent;
					padding: 90px;

					.bra-sideCard__title {
						font-size: 36px;
						line-height: 40px;
					}
					.bra-sideCard__description {
						font-size: 15px;
						line-height: 22px;
					}
				}
			}
		}
	}


	&.bra-content-slider--primary {
		.swiper-container.content-slider {
			.swiper-slide {
				@media only screen and (min-width:1500px) {
					max-width: calc( 1440px + 0px );
					&:first-of-type {
						margin-left: 0;
					}
					&:last-of-type {
						margin-right: 0;
					}
				}

				.bra-sideCard__image {
					max-height: 720px;
				}
			}
		}
	}
	&.bra-content-slider--secondary {
		scroll-margin-top: 95px;
		.swiper-container.content-slider {
			padding: 0 30px !important;

			.swiper-slide {                
				width: calc(100% / 100 * 33 - 40px);

				&:last-child {
					margin: 0;
				}

				.bra-card--highlights {
					.bra-card__wrapper {
						border: 0;
					}
				}

				.bra-card__content {
					padding: 30px;
					padding-bottom: 20px;

					.bra-title {
						margin-bottom: 10px;
					}
				}
				.bra-card__actions {
					padding: 30px;
					padding-top: 0;
					padding-bottom: 40px;
				}
			}
		}
	}
}