@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-padding {
		height: 90;

		&--large {
			height: 90px;
		}
		&--medium {
			height: 60px;
		}
		&--small {
			height: 45px;
		}
		&--extra-small {
			height: 30px;
		}
	}
}
