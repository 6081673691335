.bra-anchorsBar {
    @include flex(wrap);
    width: 100%;

    font-size: 15px;
    line-height: normal;
    
    & .bra-subtitle {
        margin: 0 0 32px 0;
    }
    &__list {
        @include flex(wrap);
        width: 100%;
        height: auto;
        list-style: inside none;
    }
    &__item {
        display: inline-block;
        width: auto;
        margin: 0 24px 16px 0;
    }    
}