.bra-sideCard {
    font-size: 15px;
    line-height: normal;

    @include flex(wrap);
    width: 100%;
    max-width: calc(1440px + 60px);
    margin: 0 30px;
    background-color: $brand-01;
    position: relative;

    &__image,
    &__textContent {
        @include flex(wrap);
        width: 50%;
    }
    &__image {
        background: none;
        background-color: transparent;
        position: relative;
        
        & .bra-image {
            background: none;
            background-color: transparent;
        }
    }
    &__textContent {
        align-items: center;
        align-content: center;
        justify-content: center;
        background-color: $brand-01;
        padding: 40px;
        box-sizing: border-box;
        overflow: hidden;
        position: relative;
    }
    &__subTitle {
        display: block;
        width: 100%;
        margin: 0 0 20px 0;

        @include fontBold;
        font-size: 17px;
        line-height: 24px;
        color: $brand-03;
        text-align: center;
    }

    &__title {
        display: block;
        width: 100%;
        margin: 0 0 20px 0;

        @include fontBold;
        font-size: 36px;
        line-height: 40px;
        color: $brand-03;
        text-align: center;
    }
    &__description {
        display: block;
        width: 100%;

        @include font;

        font-size: 15px;
        line-height: 22px;
        color: $brand-03;
        text-align: center;
    }
    &__disclaimer {
        display: block;
        width: auto;
        @include font;
        font-size: 12px;
        line-height: 18px;
        color: $brand-03;
        position: absolute;
        left: 0;
        bottom: 1em;
        text-align: right;
        opacity: 0.5;
        padding: 0 40px;
    }

    &__link {
        margin: 10px 0;
    }

    &__listBadges {
        list-style: none;
        display: flex;
        flex-direction: row;
        width: 100%;
        margin: 2em 0;

        &--right {
            justify-content: flex-end; 
        }

        &--left {
            justify-content: flex-start;
        }
    }
    &__listBadgeItem {
        height: 100px;
        width: 70px;

        * {
            background: transparent;
        }
    }

    &__listBadgeItem + &__listBadgeItem {
        margin-left: 30px
    }



    &--light {
        background-color: $brand-03;

        // .bra-sideCard__image {
        //     background-color: $brand-01;
        // }
        .bra-sideCard__textContent {
            background-color: $brand-03;
        }
        .bra-sideCard__subTitle,
        .bra-sideCard__title,
        .bra-sideCard__description,
        .bra-sideCard__disclaimer {
            color: $brand-01;
        }

        .bra-sideCard__image, 
        .bra-sideCard__image .bra-image {
            border-radius: 5px;
        }
    }
    &--grey {
        background-color: $ui-03;

        // .bra-sideCard__image {
        //     background-color: $brand-01;
        // }
        .bra-sideCard__textContent {
            background-color: $ui-03;
        }
        .bra-sideCard__subTitle,
        .bra-sideCard__title,
        .bra-sideCard__description,
        .bra-sideCard__disclaimer {
            color: $brand-01;
        }
    }
    &--reverse {
        & .bra-sideCard__image {
            order: 2;
        }
        & .bra-sideCard__textContent {
            order: 1;
        }
        & .bra-sideCard__disclaimer {
            text-align: left;
        }
    }

    &--text-aside {
        .bra-sideCard__subTitle,
        .bra-sideCard__title,
        .bra-sideCard__description,
        .bra-sideCard__disclaimer {
            text-align: left;
        }

        .bra-sideCard__textContent {
            justify-content: flex-start;
        }
    }
    &.bra-sideCard--reverse.bra-sideCard--text-aside {
        .bra-sideCard__subTitle,
        .bra-sideCard__title,
        .bra-sideCard__description,
        .bra-sideCard__disclaimer {
            text-align: left;
        }
    }
}