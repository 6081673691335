.bra-comparsionTable {
    @include flex(wrap);
    width: 100%;
    height: auto;
    // flex-direction: column;
    // background: lightgoldenrodyellow;
    background-color: $brand-03;
    padding: 20px 30px 40px 30px;
    box-sizing: border-box;

    // & * {
    //     background: rgba(0,0,0,.25);
    // }
    // & *:nth-of-type(odd) {
    //     background: rgba(0,0,0,.1);
    // }
    &__head,
    &__body,
    &__row,
    &__cell {
        @include flex(wrap);
        flex-direction: row;
        width: 100%;
        height: auto;
    }    
    &__head {
        width: 25%;
    }
    &__cell {
        align-items: center;
        justify-content: center;
        height: 60px;
        border-bottom: 1px solid $ui-08;
        box-sizing: border-box;  
        margin: 0;
        
        .bra-icn {
            font-size: 18px;
        }
    }    
    &__title,
    &__data {
        width: 100%;
        line-height: 19px;
        font-size: 15px;
    }
    &__title {
        @include fontBold;        
        text-align: left;
    }
    &__body {
        width: 75%;

        & .bra-comparsionTable__row {
            width: 33.33%;
        }
    }     
    &__data {
        text-align: center;
    }
    &__mobileTitle {
        display: none;
    }
}