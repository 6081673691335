@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-imageBlock {
		// .bra-title {
		// 	font-size: 35px;
		// 	line-height: 44px;
		// }
		.bra-imageBlock__wrapper {
			.bra-subtitle {
				margin: 15px auto 10px auto;
				margin-bottom: 8px;
			}
			.bra-paragraph {
				margin-bottom: 15px;
			}
		}

		&__images {
			flex-direction: row;
			flex-wrap: wrap;

			// .bra-imageBlock__wrapper {
			// 	width: calc(50% - 10px);
			// 	flex: auto
			// }
		}


		&--slim {
			.bra-imageBlock__wrapper {
				max-height: 120px;
			}
		}
	}
}