.bra-product-gallery-popup {
    padding: 14px 30px 30px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        & .bra-prodGallery {
            &__slideImage {
                height: auto !important;

                & .bra-image {
                    height: auto !important;
                }
            }
            &__previewImage {
                & > img {
                    width: auto;
                    max-width: 100%;
                    object-fit: initial;
                    height: auto;
                    margin: 0 auto;
                }
            }
        }
    }
}