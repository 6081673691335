@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-card {
        &__content {
            padding: 30px;
            padding-bottom: 25px;

            .bra-subtitle {
				font-size: 15px;
			}
			.bra-title {
				line-height: 22px;
				font-size: 18px;
			}
        }
        &__actions {
            padding: 30px;
        }

        &--category {
            .bra-card__content {
                padding: 25px 20px;

                & .bra-title--category {
                    line-height: 30px;
                    font-size: 24px;
                }
                & .bra-paragraph--category {
                    line-height: 22px;
                    font-size: 15px;
                    margin-top: 0;
                }
            }
            .bra-card__actions {
                padding: 20px;
            }
        }

        &--sub-category {
            .bra-card__content {
                padding: 25px 20px;

                & .bra-title--category {
                    line-height: 24px;
                    font-size: 18px;
                }
                & .bra-paragraph--category {
                    line-height: 22px;
                    font-size: 15px;
                    margin-top: 0;
                }
            }
            .bra-card__actions {
                padding: 20px;
            }
        }

        &--bg-image {
            .bra-card__content {
                padding-bottom: 25px;
            }
            & .bra-card__actions {
                padding: 0 30px 30px 30px;
            }
        }

        &--product {
            .bra-card__content {
                padding: 25px 20px 20px 20px;
            }
            .bra-card__actions {
                padding: 20px;
                padding-top: 30px;
            }
            .bra-card__visual {
                .bra-image {
                    max-width: 200px;
                }
            }
        }

        &--bg-image {
            .bra-card__content {
                padding-bottom: 30px;

                .bra-subtitle {
                    line-height: 22px;
                    font-size: 18px;
                    margin-bottom: 15px;
                }
                .bra-title {
                    line-height: 30px;
                    font-size: 24px;
                    margin-bottom: 0;
                }
                .bra-paragraph {
                    line-height: 22px;
                    font-size: 15px;
                }
            }
        }
		&--recipe {
			.bra-card__visual {
				.bra-image {
					img {
						transform: scale3d(1.05, 1.05, 1.05);
						transition: transform .8s ease-in-out;
					}
				}
			}
			.bra-card__content {
				opacity: 1;
				transform: translateY(-54px);
				transition: transform .6s ease-in-out;
			}
			.bra-card__actions {
                opacity: 1;
				transform: translateY(0);
				transition: transform .6s ease-in-out;

				.bra-btn {
					opacity: 1;
					transition: opacity .6s ease-in-out;
					transition-delay: .2s;
				}

			}
		}
        &--horizontal {
			.bra-card__visual {
				object-fit: cover;
				.bra-image {
					height: auto;
					transform: none;
					padding-bottom: 0;
					object-fit: cover;
				}
			}

			.bra-card__content {
				.bra-subtitle {
					font-size: 15px;
					margin-bottom: 10px;
					// line-height: 18px;
				}
				.bra-title {
					line-height: 22px;
					font-size: 18px;
				}
			}

			// .bra-card__actions {
			// 	.bra-btnIcon {
			// 		font-size: 15px
			// 	}
			// }
        }
        &--box {
            & .bra-card__visual {
                & .bra-image {
                    max-width: 165px;
                    height: 220px;
                }
            }
        }
    }
}