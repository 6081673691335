@media only screen and (max-width: $braun-s - 1) {
    .bra-comparsionTable {
        padding: 10px 20px 30px 20px;
        
        &__head {
            display: none;
        }
        &__body {
            width: 100%;
        }
        &__row {
            border-right: 1px solid $ui-08;
            box-sizing: border-box;
            position: relative;

            &:last-of-type {
                border-right: none;
            }
        }
        &__cell {  
            height: auto;
            min-height: 46px;
            border-bottom: none;
            margin-top: 46px; 
            padding: 10px;                          
        }
        &__row.-mobileTitle {
            & .bra-comparsionTable__cell {
                position: relative;
                z-index: 1;
            }
        }
        &__data {
            font-size: 13px;
        }
        &__mobileTitle {
            @include fontBold;
            display: flex;
            flex-wrap: wrap;
            align-items: center;            
            width: 300%;
            height: auto;
            min-height: 46px;            
            background-color: $ui-07;
            transform: translateY(-100%);
            padding: 0 15px;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            & > h3 {
                display: block;
                width: 100%;
                line-height: 21px;
                font-size: 13px;
                padding: 12.5px 0;
            }
        }
    }       
}