@media only screen and (max-width: $braun-s - 1) {
    .bra-videoBanner {
        width: calc(100% - 40px);
        height: 100%;
        margin: 0 20px;

        &--doubleVideoAside {
            display: block;
            width: 100%;
            height: 100%;
        }
        &__caption {
            position: relative;
        }

        &--centered {
            .bra-videoBanner {
                &__caption {
                    position: absolute;
                    width: 60px;
                    height: 60px;
                }

                &__playIcon {
                    min-width: 60px;
                    min-height: 60px;
                }
            }
        }

        &--double-video-aside {
            &:first-child {
                margin: 0 20px 30px;
            }
        }

    }
}