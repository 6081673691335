.bra-paragraph {
    display: block;
    width: 100%;

    @include font;

    font-size: 17px;
    line-height: 23px;

    color: $brand-01;

    &--center {
        text-align: center;
    }
    &--brand-02 {
        color: $brand-02;
    }
    &--brand-03 {
        color: $brand-03;
    }
}