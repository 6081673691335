.bra-index {
    width: 100%;
    height: auto;
    list-style: outside disc;
    padding: 0 0 0 15px;

    font-size: 17px;
    line-height: 23px;

    &__item {
        width: 100%;
        height: auto;
    }
    &__link {
        &:link,
        &:visited {
            width: auto;
            @include font;
            color: $brand-01;
            text-decoration: underline;

            font-size: 17px;
            line-height: 23px;
        }
        @include hover-supported() {
            &:hover {
                text-decoration: none;
            }
        }
        &:active {
            text-decoration: none;
        }
    }
}