.bra-sidenav {
    font-size: 15px;
    line-height: normal;

    @include flex(wrap);
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    list-style: inside none;

    &__item {
        @include flex(wrap);
        flex-wrap: wrap;       
        width: 100%;
    }
    &__title {
        display: block;
        width: 100%;
        line-height: 32px;
        font-size: 26px;
        font-weight: 400;
        color: $brand-01;
        margin: 0 0 24px 0;
    }
    &__link {
        display: inline-block;
        width: auto;
        line-height: 20px;
        font-size: 15px;
        font-weight: bold;
        color: $brand-01;
        text-decoration: none;
        transition: color 0.22s linear 0.08s;

        @include hover-supported() {
            &:hover {
                color: $support-02;
                text-decoration: underline;
            }
        }
    }
    &__item--main {
        & .bra-sidenav__link {
            font-size: 17px;
            margin: 0 0 16px 0;
        }        
    }
    &__item--last {
        & .bra-sidenav__link {
            margin: 0 0 16px 0;
        }
    }
    &__item--current {
        & .bra-sidenav__link {
            color: $support-02;

            @include hover-supported() {
                &:hover {
                    color: $support-02;
                }
            }
        }
    }
}