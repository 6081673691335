@media only screen and (max-width: $braun-s - 1) {
    .bra-campaign-steps {
        padding: 40px 20px;

        &__title {
            font-size: 24px;
            line-height: 1.13;
        }

        &__title,
        &__wrap-list {
            margin-bottom: 30px;
        }

        &__item-title {
            font-size: 15px;
            line-height: 1.47;
        }
    
        &__item-description {
            font-size: 13px;
            line-height: 1.46;
            padding-top: 5px;
        }

      




        &--with-image{
            .bra-campaign-steps{

                &__wrap-image{
                    width: 100%;
                    flex-direction: column-reverse;
                    flex-wrap: nowrap;

                    .bra-image {
                        margin-left: 0;
                        width: 100%;
                        min-width: 100%;
                        margin-left: 0;
                        margin-bottom: 30px;

                        &__image{
                            position: static;
                        }
                    }

                    & .bra-campaign-steps__wrap-list {
                        width: 100%;
                    }
                } 
               
            }
            
        }

    }
}
