.bra-basic-form {
  padding: 60px 30px;

  &__title {
    @include fontBold;
    font-size: 36px;
    line-height: 1.11;
    margin-bottom: 60px;
  }

  &__wrap {
    width: 90%;
    margin-bottom: 30px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px 20px;

    .bra-input-animated {
      box-sizing: border-box;
      margin: 0 10px;
      flex: 0 1 calc(33.33% - 20px);
    }
  }

  input {
    padding: 12px 8px !important;
  }

  .gigya-label {
    &:before {
      border-radius: 5px;
    }

    &:after {
      margin-top: 0 !important;
    }
  }

  .gigya-input-checkbox {
    +label.gigya-label {
      &:after {
        top: -2px; 
      }

      &:before {
        top: 3px;
      }
    }
  }

 .gigya-checkbox-text { 
    line-height: 19px;
  }

  .subscription-checkbox {
    position: absolute;
    z-index: 8;
    opacity: 1 !important;
    width: 0;
    height: 0;
  
    +.subscription-name-label {
      font-size: 14px;

      &:before {
        position: relative;
        content: "";
        display: inline-block;
        margin-right: 12px;
        margin-bottom: -1px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        display: inline-block;
        border: 1px solid var(--ui-02);
      }

      &:after {
        display: none
      }

      i {
        background: #000;
        width: 15px;
        height: 15px;
        display: inline-block;
        color: var(--ui-03);
        border-radius: 50%;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        position: relative;
        cursor: pointer;
      }
    }
  }

  .subscription-checkbox:checked:before {
    content: "\E0";
    font-family: var(--fontNameIcons);
    background-color: transparent;
    transform: scale(1);
    position: absolute;
    left: 9px;
    top: 0;
  }
}
