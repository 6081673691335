@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-horizontalCard {
        width: 100%;
        padding: 0;
        margin: 0 30px;
        margin-bottom: 20px;

		&__title {
			line-height : 22px;
			font-size : 15px;
		}
		.bra-paragraph {
			line-height : 19px;
			font-size : 13px;
		}
		&__image {
			margin: 0 15px 0 0;
		}
		&__wrap {
			width: calc(100% - 80px - 15px);
		}

		&--small {
			margin: 0;
		}
	}
	.bra-horizontalCard__wrapper {
		.bra-horizontalCard {
            width: 100%;
			margin: 0 0 30px 0;
		}
    }
}
