.bra-newsletter-support {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 60px);
    box-sizing: border-box;
    justify-content: space-between;

    &__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: self-start;
        box-sizing: border-box;
        width: calc( 50% - 10px );

        background-color: $brand-01;
        border-radius: 5px;
        padding: 30px 40px 40px 40px;

        .bra-title,
        .bra-paragraph {
            color: $brand-03;
        }

        .bra-title {
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        .bra-paragraph {
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 30px;
        }
        .bra-btn {
            margin-top: auto;
        }
    }
}