.bra-playBtn {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: $brand-03;

    & .bra-icn__play {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        line-height: 30px;

        &::before {
            font-size: 10px;
            margin: 0 0 0 2px;
        }
    }
}