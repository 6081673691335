@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-toolbarPlp {        
        &__wrap {
            & .bra-btnView {
                display: inline-flex;
            } 
        }
        &__accordion {
            & .bra-input--checkbox {
                width: 33.33%;

                & .bra-input__label {
                    font-size: 13px;
                }
            }
            & .bra-radio {
                width: 33.33%;

                &__label {
                    font-size: 13px;
                }
            }
        }        
    }    
}
