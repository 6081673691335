.bra-sortBtn {
    display: inline-flex;
    flex-wrap: wrap;
    width: auto;
    height: 30px;
    position: relative;
    z-index: 10;

    & .bra-btnIcon--sort {
        text-decoration: none !important;
        margin: 0;

        & .bra-btnIcon__text {
            pointer-events: none;
        }
    }
    & .bra-sortDropdown {
        opacity: 0;
        transform: translateY(-16px);
        transition: transform 0.22s ease, opacity 0.12s linear;
        pointer-events: none;
        position: absolute;
        top: 30px;
        left: 0;
        z-index: 11;
    }
    &--open {
        & .bra-sortDropdown {
            opacity: 1;
            transform: translateY(0);
            pointer-events: initial;
        }
    }
}