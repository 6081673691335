.productsList__filterBar {
	display: flex;
	flex-wrap: wrap;

	&.productsList__filterBar--upper {
		margin-bottom: 20px;
		padding-bottom: 20px;
		border-bottom: 1px solid $ui-01;
	}
	&.productsList__filterBar--lower {
		margin-top: 20px;
		padding-top: 20px;
		border-top: 1px solid $ui-01;
	}

	.bra-pagination {
		margin-left: auto
	}
	.bra-input--select + .bra-input--select {
		margin-left: 1em
	}
}

.productsList__productsConatiner {
	display: flex;
	flex-direction: inherit;
	flex-wrap: wrap;
	justify-content: flex-start;

	.bra-productCard {
		width: calc(30% - 10px);

		@include hover-supported() {
			&:hover {
				a {
					text-decoration: none
				}
			}
		}
	}
	.bra-productCard + .bra-productCard {
		margin-left: 10px
	}
}


