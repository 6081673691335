.bra-btn {
    font-size: 15px;
    height: 40px;
    min-width: 160px;
    padding: 0 25px;

    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-shrink: 0;

    box-sizing: border-box;
    position: relative;
    appearance: none;
    cursor: pointer;

    width: auto;
    min-width: 160px;

    white-space: nowrap;
    text-overflow: ellipsis;
    
    height: 40px;

    line-height: 37px;

    font-weight: 500;
    color: $brand-03;
    text-decoration: none;
    
    border: 1px solid;
    border-radius: 2px;

    border-color: $brand-01;
    background-color: $brand-01;

    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);

    transition: border-color 0.12s linear,
                background-color 0.12s linear,
                color 0.12s linear;

    @include hover-supported() {
        &:hover {
            border-color: $support-02;
            background-color: $support-02;
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &:hover {
            border-color: $support-02;
            background-color: $support-02;
        }
    }

    &:active {
        color: $brand-01;
        border-color: $ui-02;
        background-color: $ui-01;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
        transition: border-color 0.12s linear,
                    background-color 0.12s linear,
                    color 0.12s linear,
                    box-shadow 0.12s linear;
    }

    &:disabled,
    &:disabled:hover {
        color: $brand-02;
        background-color: $ui-01;
        border-color: $ui-01;
    }


    &--inverted {
        background-color: $brand-03;
        color: $brand-01;
        border-color: $brand-03;
    }

    &--rounded {
        @include fontBold;
        border-radius: 2em;
        padding: 0 34px;
    }

    &--bordered {
        background-color: transparent;
        color: $brand-01;

        @include hover-supported() {
            &:hover {
                background-color: transparent;
                color: $support-02;
            }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            &:hover {
                background-color: transparent;
                color: $support-02;
            }
        }
    }

    &--pill {
        border: 0;
        border-radius: 10em;
        padding-left: calc( 3em + 0.6em);
        box-shadow: 0 0 0 0.2em $support-02;

        @include fontBold;

        &::before {
            content: '';
            display: block;
            width: 2.8em;
            height: 2.8em;
            border-radius: 50%;
            background-color: $support-02;
            transform: rotateY(0deg);
            transition: transform 0.22s ease-in-out;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            box-shadow: 0 0 0 0.2em $brand-03,
                        inset 0 0 0 0.2em $brand-03
        }

        @include hover-supported() {
            &:hover {
                border-color: $support-02;
                background-color: $support-02;
            }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            &:hover {
                border-color: $support-02;
                background-color: $support-02;
            }
        }
    }
    &--pill-alt {
        border: 0;
        border-radius: 10em;
        padding-left: calc( 3em + 16px );
        box-shadow: 0 0 0 0.2em $support-03;

        @include fontBold;

        &::before {
            content: '';
            display: block;
            width: 2.8em;
            height: 2.8em;
            border-radius: 50%;
            background-color: $support-03;
            transform: rotateY(0deg);
            transition: transform 0.22s ease-in-out;
            box-sizing: border-box;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;

            box-shadow: 0 0 0 0.2em $brand-03,
                        inset 0 0 0 0.2em $brand-03
        }

        @include hover-supported() {
            &:hover {
                border-color: $support-03;
                background-color: $support-03;
            }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            &:hover {
                border-color: $support-03;
                background-color: $support-03;
            }
        }
    }
}