.bra-promo-tiles {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
	max-width: 1440px;
	line-height: normal;
	font-size: 15px;
    padding: 0 30px;
	margin: 0 auto;
    box-sizing: border-box;

	.bra-card {
		width: calc( 50% - 10px );

		.bra-card__wrapper {
			.bra-card__content {
				.bra-subtitle {
					@include font;
				}
			}
		}
	}
	.bra-card,
	.bra-card .bra-card__wrapper,
	.bra-card__visual {

		// @include hover-supported() {
		// 	min-height: 45vw !important;
        // }
        @include hover-supported() {
			//min-height: 45vw !important;
		}
        // height: calc( calc(100vw / 2) - 40px ) !important;
        height: 45vw !important;
		min-height: initial !important;
        // max-height: 710px !important;
        max-height: calc( calc(1440px / 100) * 45 ) !important;
		border-radius: 5px;
	}
}