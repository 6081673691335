@media only screen and (max-width: $braun-s - 1) {
	.bra-introText {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		justify-content: space-between;
		align-items: flex-end;

		margin-bottom: 30px;
		width: calc(100% - 40px);

		.bra-title,
		.bra-paragraph {
			width: 100%;
		}

		.bra-title + .bra-paragraph {
			margin-top: 10px
		}

		&--right-button {
			align-items: flex-start;

			.bra-btn {
				margin-top: 30px;
			}

		}
	}
}