@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-multi-accordion{
        &--terms-and-conditions {
            .bra-multi-accordion {
                &__title {
                    font-size: 15px;
                    line-height: 1.47;
                }

                &__description {
                    font-size: 13px;
                    line-height: 1.47;
                }

                &--specifications {
                    .bra-multi-accordion {
                        &__title {
                            font-size: 18px;
                            line-height: 1.33;
                        }
                        &__list-item {
                            font-size: 13px;
                            line-height: 1.23;
                        }
                    }
                }

                &__item {
                    .bra-multi-accordion__expander.active {
                        .bra-multi-accordion__title {
                            padding-left: 40px;
                            padding-right: 40px;
                        }
                    }
                }

                &__content {
                    padding-left: 40px;
                    padding-right: 40px;
                }
            }
        }
        &--promo {
            .bra-multi-accordion {
                &__item {
                    .bra-multi-accordion__expander.active {
                        + .bra-multi-accordion__content {
                            max-height: 184px;
                            width: 100%;
                            overflow: visible;
                            opacity: 1;
                            padding: 0;
                        }
                    }
                }
                .bra-multi-accordion__expander {
                    .bra-multi-accordion__title {
                        text-align: left;
                    }
                }
                &__image {
                    width: 202px;
                    min-width: 202px;
                    height: 202px;
                }
                &__description-wrap {
                    .bra-multi-accordion__title {
                        font-size: 18px;
                        line-height: 1.22;
                    }
                }
                &__description {
                    font-size: 13px;
                    line-height: 1.46;
                }
            }
        }
    }
}
