[class^="bra-"] {
    .swiper-container {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        overflow: hidden;
        position: relative;
        /* Fix of Webkit flickering */
        z-index: 1;
    }
    .swiper-container-vertical > .swiper-wrapper {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .swiper-wrapper {
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        height: 100%;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        position: relative;
        z-index: 1;
    }

    .swiper-wrapper {
        -webkit-transform: translate3d(0px, 0, 0);
        transform: translate3d(0px, 0, 0);
    }
    .swiper-slide {
        -ms-flex-negative: 0;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        -o-transition-property: transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-flex-shrink: 0;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        position: relative;
    }
      /* Auto Height */
      /* 3D Effects */
      /* IE10 Windows Phone 8 Fixes */
    .swiper-button-prev,
    .swiper-button-next {
        width: 27px;
        height: 44px;
        background-repeat: no-repeat;
        background-size: 27px 44px;
        background-position: center;
        margin-top: -22px;
        cursor: pointer;
        position: absolute;
        top: 50%;
        z-index: 10;

        &.swiper-button-lock {
            display: none;            
        }
    }
    .swiper-button-prev.swiper-button-disabled,
    .swiper-button-next.swiper-button-disabled {
        opacity: 0.35;
        cursor: auto;
        pointer-events: none;
    }
    .swiper-button-prev {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
        left: 10px;
        right: auto;
    }
    .swiper-button-next {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E");
        left: auto;
        right: 10px;
    }
    .swiper-pagination {
        text-align: center;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        -webkit-transition: 300ms opacity;
        -o-transition: 300ms opacity;
        transition: 300ms opacity;
        position: absolute;
        z-index: 10;
    }
      /* Common Styles */

    .swiper-container-horizontal > .swiper-pagination-bullets {
        width: 100%;
        bottom: 10px;
        left: 0;
    }
      /* Bullets */
    .swiper-pagination-bullet {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: #000;
        opacity: 0.2;
    }
    button.swiper-pagination-bullet {
        border: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    .swiper-pagination-clickable .swiper-pagination-bullet {
        cursor: pointer;
    }
    .swiper-pagination-bullet-active {
        background: #007aff;
        opacity: 1;
    }
    .swiper-container-vertical > .swiper-pagination-bullets {
        -webkit-transform: translate3d(0px, -50%, 0);
        transform: translate3d(0px, -50%, 0);
        top: 50%;
        right: 10px;
    }
    .swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
        display: block;
        margin: 6px 0;
    }
    .swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
        margin: 0 4px;
    }
      /* Progress */
      /* Scrollbar */
    .swiper-scrollbar {
        -ms-touch-action: none;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        position: relative;
    }
    .swiper-container-horizontal > .swiper-scrollbar {
        width: 98%;
        height: 5px;
        position: absolute;
        bottom: 3px;
        left: 1%;
        z-index: 50;
    }
    .swiper-container-vertical > .swiper-scrollbar {
        width: 5px;
        height: 98%;
        position: absolute;
        top: 1%;
        right: 3px;
        z-index: 50;
    }
    .swiper-scrollbar-drag {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.5);
        position: relative;
        top: 0;
        left: 0;
    }
      /* Preloader */
    @keyframes swiper-preloader-spin {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
      /* a11y */
    .swiper-container .swiper-notification {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1000;
    }

    .swiper-container {
        .swiper-slide {
            display: flex;

            max-width: 1440px;
            height: auto;
        }

        .swiper-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 85%;
            max-width: 1200px;
            margin: 0 auto;
            margin-bottom: 60px;

            .swiper-pagination {
                display: flex;
                position: relative;

                .swiper-pagination-bullet {

                    @include fontBold;
                    display: flex;
                    width: auto;
                    height: auto;
                    line-height: 1;
                    font-size: 15px;
                    color: #fff;
                    border-radius: 0;
                    border-radius: 5px;
                    background: none;

                    background: #000;
                    opacity: 1;

                    transition: background-color 0.12s linear,
                                color 0.12s linear;
                    padding: 14px 20px;

                    &:hover {
                        background: $support-02;

                        transition: background-color 0.12s linear,
                                    color 0.12s linear;
                    }
                }

                .swiper-pagination-bullet + .swiper-pagination-bullet {
                    margin-left: 10px;
                }
            }
        }
        .swiper-footer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            width: 85%;
            max-width: 1200px;
            margin: 0 auto;
            margin-top: 55px;

            .swiper-button-prev {
                order: 1;
            }
            .swiper-scrollbar {
                order: 2;
            }
            .swiper-button-next {
                order: 3;
            }

            .swiper-scrollbar {
                width: 85%;
                height: 3px;
                background: $ui-08;
                position: relative;
                bottom: 0;
                left: 0;

                .swiper-scrollbar-drag {
                    background: $brand-01;
                }
            }

            .swiper-button-next,
            .swiper-button-prev {
                width: auto;
                height: auto;
                background: none;
                margin: 0;
                position: relative;

                top: 0;
                bottom: 0;
                left: 0;
                right: 0;

                &.swiper-button-disabled {
                    opacity: .15;
                    cursor: auto;
                    pointer-events: none;
                }
            }
        }

    }
    @media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    }
    @media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
        .swiper-container {
            .swiper-header {
                width: calc(100% - 60px);
                .swiper-pagination {
                    .swiper-pagination-bullet {
                        font-size: 13px;
                        padding: 12px 10px;
                    }
                }
            }
            .swiper-footer {
                justify-content: space-around;
                width: calc(100% - 60px);
                margin-top: 30px;

                .swiper-scrollbar {
                    width: 100%;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    display: none;
                }
            }
        }
    }
    @media only screen and (max-width: $braun-s - 1) {
        .swiper-container {
            .swiper-header {
                display: none;
            }
            .swiper-footer {
                justify-content: space-around;
                width: calc(100% - 40px);
                margin-top: 30px;

                .swiper-scrollbar {
                    width: 100%;
                }

                .swiper-button-next,
                .swiper-button-prev {
                    display: none;
                }
            }
        }
    }
}
