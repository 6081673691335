@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-awards {
        margin: 0 30px;

        & .bra-introText {
            margin: 0 0 40px 0;
        }
        // & .content_section__small {            
        //     max-width: 0;
        //     width: 100%;
        // }
        &__box {
            & > .bra-image {
                width: 100px;
                height: 100px;
            }
        }
        &__text {        
            width: calc(100% - 100px - 20px);
        }
        &__title {
            font-size: 15px;
            line-height: 1.47;
        }
        &__description {
            font-size: 13px;
            line-height: 1.46;
        }
    }
}
