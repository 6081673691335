.bra-list {
    display: block;
    width: 100%;
    height: auto;

    --fontSize: 17px;
    --lineHeight: 23px;

    font-size: 17px;
    line-height: 23px;

    &__title {
        display: block;
        width: 100%;
        font-size: 17px;
        line-height: 23px;

        @include fontBold;
        color: $brand-01;
        margin: 0 0 24px 0;
    }
    &__list {
        @include font;

        width: 100%;
        list-style-position: outside;
        padding: 0 0 0 20px;

        &--unordered {
            list-style: outside disc;
        }
    }
    &__item {
        height: auto;
        font-size: 17px;
        line-height: 23px;
    }
}