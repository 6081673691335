@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-content-slider {
		.swiper-container.content-slider {
			.swiper-slide {
				max-width: calc(100vw - 60px);

				.bra-sideCard {
					&__textContent {
						padding: 90px;

						.bra-sideCard__title {
							font-size: 30px;
							line-height: 34px;
						}
						.bra-sideCard__description {
							font-size: 15px;
							line-height: 22px;
						}
					}
				}
			}
		}
	}

	.bra-content-slider.bra-content-slider--secondary {
		scroll-margin-top: 95px;
		.swiper-container.content-slider {
			.swiper-slide {
                width: calc(33.33% - 30px) !important;

				.bra-card__content {
					padding: 20px;
					padding-bottom: 40px;
				}
				.bra-card__actions {
					padding: 20px;
					padding-top: 0;
					padding-bottom: 30px;
				}
			}
		}
	}
}