//.wrapper,
html {
    scroll-behavior: smooth;
}
html, body {
    overflow-x: hidden;
}
body {
    --pageBackground: #ffffff;

    &.homepage {
        background: #f5f5f5;
        --pageBackground: #f5f5f5;
    }
    .bra-header,
    .bra-footer {
        opacity: 0;
    }
    .page__wrapper {
        opacity: 0;
        transition: opacity 320ms ease-in-out;
        position: relative;

        &--ui-07 {
            background-color: $ui-07;
        }
    }
    &.headerReady {
        .bra-header,
        .bra-footer {
            opacity: 1;
        }
        .page__wrapper {
            opacity: 1;
            transition: opacity 320ms ease-in-out, margin-top 320ms linear;
            will-change: margin-top;
        }
        > .bra-loader,
        > strong .bra-loader  {
            opacity: 0;
            height: 0;
            &:before,&::after {
                display: none
            }
        }
    }
}

.braun-header.hasStripe + .page__wrapper.noGapHeader {
    margin-top: 46px !important;
}

.braun-header.hasStripe + .page__wrapper {
    margin-top: calc(var(--headerHeight) + 46px) !important;
}

.page__wrapper {
    margin-top: var(--headerHeight);
    &.noGapHeader {
        margin-top: 0 !important
    }

    &.noMarginAuto {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1440px;
        margin: 0;
        margin-left: 30px;
        justify-content: space-between;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        max-width: 1440px;
        margin: 0 auto;
        justify-content: space-between;

        box-sizing: border-box;
        @media only screen and (min-width:1441px) and (max-width:1499px) {
            padding: 0;
            width: calc(100% - 60px)
        }
        @media only screen and (min-width:$braun-s) and (max-width:1440px) {
            padding: 0 30px;
        }

        @media only screen and (max-width:$braun-s - 1) {
            padding: 0 20px;
        }
    }

    .content_section {
        &.costrain {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            max-width: calc(1440px + 60px);
            margin: 0 auto;
            justify-content: center;
        }
        &.content_section--ui-07 {
            background-color: #F5F5F5;    
            padding: 0 calc((100% - 1500px) / 2);
            box-sizing: border-box;
            margin: 0;
            max-width: none;
            
            @media (-ms-high-contrast: none) {
                padding: 0;             
            }
        }

        .page_margin {
           padding-left:30px;
           padding-right:30px;
        }

        .content_section__small {
            margin: 0 auto;
            width: 85%;
            max-width: 1200px;

            @media only screen and (max-width: 991px) {
                width: 100%;
            }

            .page_margin {
                margin-left: 0;
                margin-right: 0;

                @media only screen and (max-width: $braun-m - 1) {
                    margin-left: 30px;
                    margin-right: 30px;
                }
                @media only screen and (max-width: $braun-s - 1) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }

        .page_margin {
            box-sizing: border-box;
            margin-left: 30px;
            margin-right: 30px;
            width:100%;

            @media only screen and (max-width: $braun-s - 1) {
                margin-left: 20px;
                margin-right: 20px;
            }
        }
    }
    .page_header {
        padding: 22px 0;

        .bra-breadcrumbs + .bra-pageHeader__wrapper {
            margin-top: 22px;
            .bra-title {
                margin-bottom: 0
            }
        }
    }
    .page__body {
        .page__aside {
            width: calc(25% - 10px);

            @media only screen and (max-width:991px) {
                width: 100%;
            }
        }
        .page__content {
            width: 100%;
        }
        .page__aside + .page__content {
            width: calc(75% - 10px);
            @media only screen and (max-width:991px) {
                width: 100%;
            }
        }
    }
    .page__closing {
        --bgColor: #{$ui-03};

        background: var(--bgColor);
        margin-top: 33px;
    }
}



.editorialContent {
    border: 2px dashed red;
    padding: 64px;
    margin: 0 auto;
    max-width: 1440px;
    width: 100%;
    margin: 0 30px;
    text-align: center;

    @media only screen and (max-width:$braun-xs) {
        margin: 0 20px;
    }
}

/*XL*/
.bra-item--breakpoints .bra-item--breakpoints_default {display:block}
.bra-item--breakpoints .bra-item--breakpoints_l {display:none}
.bra-item--breakpoints .bra-item--breakpoints_m {display:none}
.bra-item--breakpoints .bra-item--breakpoints_s {display:none}
.bra-item--breakpoints .bra-item--breakpoints_xs {display:none}

/*L*/
@media only screen and (min-width: $braun-l) and (max-width: 1440px) {
    .bra-item--breakpoints .bra-item--breakpoints_default {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_l {display:block}
    .bra-item--breakpoints .bra-item--breakpoints_m {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_s {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_xs {display:none}
}

/*M*/
@media only screen and (min-width: $braun-m) and (max-width: $braun-l - 1) {
    .bra-item--breakpoints .bra-item--breakpoints_default {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_l {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_m {display:block}
    .bra-item--breakpoints .bra-item--breakpoints_s {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_xs {display:none}
}

/*S*/
@media only screen and (min-width: $braun-s) and (max-width: $braun-m - 1) {
    .bra-item--breakpoints .bra-item--breakpoints_default {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_l {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_m {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_s {display:block}
    .bra-item--breakpoints .bra-item--breakpoints_xs {display:none}
}

/*XS*/
@media only screen and (max-width: $braun-s - 1) {
    .bra-item--breakpoints .bra-item--breakpoints_default {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_l {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_m {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_s {display:none}
    .bra-item--breakpoints .bra-item--breakpoints_xs {display:block}
}

/* landscape notched device fix */
@media (orientation: landscape) {
    body {
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        
        // .bra-header {
        //     padding-left: env(safe-area-inset-left) !important;
        //     padding-right: env(safe-area-inset-right) !important;
        // }
    }
}