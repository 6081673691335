@media only screen and (max-width: $braun-s - 1) {
    .bra-compare-product {
        &__table {
            order: 2;
            flex: 1 1 100%;
            margin-top: 20px;
        }
    
        &__selected,
        &__slides {
            flex: 1;
        }

        &__selected,
        &__col-selected {
            display: none;
        }

        &__col {
            flex: 1 1 50%;

            .bra-cell {
                justify-content: flex-end;
            }
        }
    
        &__col-property .bra-cell {
            justify-content: flex-start;
        }

        &__slides {
            order: 1;
            .bra-card__visual {
                & .bra-image {
                    max-width: 160px;
                    height: 140px;

                    img {
                        object-fit: unset;
                    }
                }
            }
        }

        &__selected {
            .bra-card__visual {
                & .bra-image {
                    max-width: 160px;
                    height: 140px;

                    img {
                        object-fit: unset;
                    }
                }
            }
        }

        .swiper-container .swiper-footer .swiper-button-next, 
        .swiper-container .swiper-footer .swiper-button-prev {
            display: block;
        }
    }
}
