.bra-list-richlinks {
	font-size: 15px;
	line-height: normal;

	display: flex;
	flex-direction: row;
	justify-content: space-around;

	width: 100%;
	list-style: none;
	margin: 0;
	padding: 0;

	.bra-list-richlinks__item {
		flex-grow: 1;
		flex-basis: 0;
		padding: 40px 20px;
	}
}