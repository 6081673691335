@media print {
    @page {
        size: A4;
    }
}

body.custom-print {
    @media print {
        * {
            -webkit-print-color-adjust: exact;
        }
        .bra-header,
        .bra-header.bra-header--shrink {
            position: initial;
            background: #ffffff;
            padding-left: 0 !important;
            padding-right: 0 !important;

            & .bra-header__buttons-container {
                display: none;
            }
            & .bra-header__logo .bra-logo__wrapper .bra-logo .logo--fill {
                fill: #000000;
            }
        }
        .page__wrapper {
            margin-top: 0;
        }
        .bra-crosslink-box {
            display: none !important;
        }
        .swiper-footer {
            display: none !important;
        }
        .bra-gallery__container .bra-gallery__swiperWrapper {
            display: none;
            opacity: 0;
        }
        .bra-footer {
            display: none !important;
        }
    }
}