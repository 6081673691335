@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-stories {
        &__screen {
            .bra-subtitle {
                font-size: 30px;
                line-height: 36px;
            }
            &--start {
                & .buttonTap.tapToStart {
                    bottom: 60px;
                }
            }
        }
    }
    .swiper-container.stories-slider {
        .swiper-slide {
            .bra-stories__slide--content {
                font-size: 15px;
                line-height: 22px;
                margin-left: 10%;
                max-width: calc( calc( 100% - 20% ) / 2 )
            }
            .bra-stories__slide--visual {
                .mobileVersion {
                    display: none
                }
                .desktopVersion {
                    display: block;
                    height: 100%;
                }
            }
        }
        .swiper-pagination {
            bottom: 30px;
            width: calc( 80% + 8px );
        }
    }
}
