@media only screen and (max-width: $braun-s - 1) {
	.bra-stage {
		&__wrapper {
			min-height: initial;
			&.centered {		
				box-shadow: 0 10px 5px -5px rgba(0,0,0,.2);
				display: flex;
				flex-direction: column-reverse;
				.bra-stage__content { 
					align-self: center;
					padding: 0;
					

					.bra-stage__cardWrapper {
						margin: 0;
						background-color: rgba(0,0,0,.7);
						border-radius: 0;

						.bra-title {
							font-size: 35px;
						}
					}
				}

				.bra-stage__image {
					position: static;
					z-index: 0;
				}
			}

			&.countdown {
				.bra-stage__content .bra-stage__card_info__heading {
					margin-bottom: 5px;
				}
			}
		}
		&__image {
			.bra-image {
				min-height: initial;
			}
		}
		&__content {
			position: relative;
			padding: 20px;

			&::after {
				content: '';
				background: var(--pageBackground);
				width: 100%;
				height: 100%;
				top: 50%;
				left: 0;
				position: absolute;
				z-index: -1
			}

			.bra-stage__cardWrapper {
				width: 100%;
				margin-bottom: 10px;
				background: black;

				.bra-stage__card {
					margin: 20px;
				}
			}


			.bra-stage__card_info {
				&__heading {
					margin-bottom: 10px;

					.bra-stage__badgesList {
						margin-right: 5px;

						.bra-stage__badge {
							font-size: 13px;
							padding: 3px 5px;
						}
					}
					.bra-subtitle {
						font-size: 13px;
					}
				}

				&__main {
					.bra-stage__card_info__content {
						margin-right: 10px;
						margin-bottom: 0;
					}

					.bra-title {
						font-size: 18px;
						line-height: 22px;
					}
					.bra-paragraph {
						font-size: 13px;
						line-height: 16px;
					}
					.bra-btn {
						padding: 0 20px;
					}


					.bra-stage__card_visual {
						min-width: 90px;
						width: 90px;
						max-width: 90px;
						max-height: 120px;
						min-height: initial;
					}
				}
			}

			.bra-stage__chevron {
				display: none !important;
			}

			.bra-stage__discalimerWrapper {
				max-width: 100%;
				width: 100%;
			}
		}

		&__countdown {
			margin-bottom: 20px;
	
			&-label {
				font-size: 11px;
				margin-top: 5px;
			}
	
			&-days,
			&-hours,
			&-minutes,
			&-seconds {
				font-size: 36px;
				line-height: 1.11;
			}
	
			&-box {
				height: 60px;
				width: 54px;
			}
		}

		&__breadcrumbs {
			padding: 80px 0 0;
		}
	}
}
