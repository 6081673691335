@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-stories {
		&__wrapper {
			padding-top: 75%;
			// margin: 0 30px;
		}
		&__screen {
			.bra-subtitle {
				font-size: 24px;
				line-height: 30px;
            }
            &--start {
                & .buttonTap.tapToStart {
                    bottom: 60px;
                }
            }
		}
	}
	.swiper-container.stories-slider {
		.swiper-slide {
			&.topAligned {
				align-items: flex-end;
			}
			.bra-stories__slide--content {
				font-size: 15px;
				line-height: 22px;

				margin-left: 10%;
				max-width: calc( calc( 100% - 20% ) / 2 );
			}
		}
		.swiper-pagination {
			bottom: 30px;
			width: calc( 80% + 8px );
		}
	}
}
