.bra-breadcrumbs {
  @include flex(wrap);
  width: 100%;
  height: auto;

  font-size: 15px;
  line-height: normal;

  &__link {
    display: inline-flex;
    align-items: center;
    width: auto;
    @include fontBold;
    line-height: 24px;
    font-size: 15px;
    color: $brand-01;
    text-decoration: none;
    transition: color 0.22s linear 0.08s;
    margin: 0 0.6em 0 0;
    position: relative;

    &:first-child {
      margin: 0 0.6em 0 0;
    }
    &::before {
      content: '•';
      display: inline-block;
      margin: 0 0.6em 0 0;
      width: 1em;
      text-align: center;
      position: relative;
      bottom: 1px;
    }
    &:first-child::before {
      display: none;
    }
    @include hover-supported() {
      &:hover {
        color: $support-02;
        text-decoration: underline;
        &::before {
          text-decoration: none;
        }
      }
    }
  }
  & span.bra-breadcrumbs__link {
    pointer-events: none;
  }

  &__pdp {
    width: calc(100% - 60px);
    padding: 0 20px;
  }
}
