.bra-label {
    @include ellipsis;
    display: inline-block;
    width: auto;
    max-width: 100%;
    height: 40px;
    line-height: 2.2;
    font-size: 20px;
    font-weight: bold;
    color: $brand-03;
    border-radius: 20px;
    background-color: $support-03;
    padding: 0 16px;
    box-sizing: border-box;
}