@media only screen and (max-width: $braun-s - 1) {
    .bra-article-stage {
        &__wrapper {
            display: flex;
            flex-direction: column;
            padding: 0 20px;
        }

        &__content,
        &__visual {
            display: inline-flex;
            width: 100%;
        }

        &__content {
            .bra-subtitle {
                font-size: 15px;
                line-height: 22px;
                margin-bottom: 10px;
            }

            .bra-title {
                margin-bottom: 30px;
            }
        }

        &__visual {
            border-radius: 5px;
            overflow: hidden;

            .bra-image {
                // height: 0;
                // min-height: initial;
                // padding-bottom: 56.25%;
            }
        }

        &__heading {}

        &__actions {
            position: relative;
            bottom: initial;
            width: 100%;
            padding: 20px 0;
        }
    }
}