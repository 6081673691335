@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
	.bra-interactiveBlock {
		&__imageWrapper {
			width: calc(100% - 40px);

			.bra-interactiveBlock__image {
				&--handle {
					&__leftArrow {
						transform: translateX(-50%) translateY(-50%) scale(.7);
					}
					&__rightArrow {
						transform: translateX(50%) translateY(-50%) scale(.7);
					}
				}
			}
		}
	}
}
