.bra-prodSwiper {
    font-size: 15px;
    line-height: normal;

    border-bottom: 1px solid $ui-01;

    & .swiper-container {
        &.gallery-top {
            border-bottom: 1px solid $ui-01;
        }
        &.gallery-thumbs {
            width: 60%;
            height: 90px;
            // border: 1px solid $ui-01;
            // border-width: 1px 0 1px 0;
            margin: 0 20%;
            pointer-events: none;
            user-select: none;
            position: relative;
            
            & .swiper-slide {
                pointer-events: initial;
            }
        }
    }
    & .swiper-button-next,
    & .swiper-button-prev {
        width: 10px;
        height: 22px;
        background-image: none !important;
    }
    & .swiper-scrollbar {
        display: none;
    }
    &__arrow {
        &Color {
            transition: stroke 0.12s linear;
        }
        @include hover-supported() {
            &:hover {
                & .bra-prodSwiper__arrowColor {
                    stroke: $support-02 !important;
                }
            }
        }
    }
    &__line {
        display: block;
        width: 57.12%;
        height: 4px;
        background: $support-02;
        transition: left 0.22s ease 0.18s;
        position: absolute;
        left: 0;
        z-index: 2;

        &--top {
            top: 0;
            &::after {                
                content: " ";
                width: 0;
                height: 0;
                border: solid transparent;
                border-width: 6px;
                border-top-color: $support-02;
                pointer-events: none;
                position: absolute;
                top: 100%;
                left: calc(50% - 6px);
            }
        }
        &--bottom {
            bottom: 0;
        }
    }
    &__productSmall {
        display: block;
        height: 88px;
        overflow: hidden;

        &Img {
            display: block;
            width: 100%;
            height: 100%;
            background-image: url('/assets/images/bg/bg-product-preview.jpg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            transform: scale(1, 1);
            
            @include hover-supported() {
                &:hover {
                    transform: scale(1.1, 1.1);
                }
            }
        }
    }
}