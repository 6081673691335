@media only screen and (min-width:$braun-m) and (max-width:$braun-l - 1) {
	.bra-newsletter-support {
		width: calc(100% - 60px);

		&__content {
			padding: 20px 30px 30px 30px;

			.bra-title {
				font-size: 24px;
				line-height: 40px;
				margin-bottom: 10px;
			}
			.bra-paragraph {
				font-size: 15px;
				line-height: 22px;
				margin-bottom: 30px;
			}
			.bra-btn {
				margin-top: auto;
			}
		}
	}
}
