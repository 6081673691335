@media only screen and (max-width: $braun-s - 1) {
    .bra-padding {
    	height: 60px;

		&--large {
			height: 60px;
		}
		&--medium {
			height: 45px;
		}
		&--small {
			height: 30px;
		}
		&--extra-small {
			height: 15px;
		}
	}
}
