@media only screen and (max-width: $braun-s - 1) {
    .bra-toolbarPlp {
        padding-top: 0;

        &__wrap {
            & .bra-btnView {
                display: inline-flex;
            } 
            &.-filters {
                min-width: 100vw;                
                margin: 0 0 0 -20px;
            }
            &.-filtersTags {
                min-width: 100vw;
                width: 100vw;
                transform: translateX(-20px);
                padding: 15px 20px;
                box-sizing: border-box;
            }
        }
        &__accordion {
            max-height: 57px;

            &Head {
                padding-top: 20px;

                & .bra-icn__arrow--down {
                    top: 25px;
                }
            }
            & .bra-input--checkbox {
                width: 100%;
            }
            & .bra-radio {
                width: 100%;
            }
        } 
        & .bra-toolbarPlp__rangeLabelWrap {
            & .bra-toolbarPlp__rangeLabel {
                &.-max {
                    left: calc(100% + 8px);
                }
            }
        }

        /* swiper PLP */
        & .swiper-slide {
            width: auto !important;
        }
        & .swiper-pagination {
            display: none !important;
        }
    }    
}