@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
	.bra-promo-tiles {
		.bra-card,
		.bra-card .bra-card__wrapper,
		.bra-card__visual {
			height: 45vw !important;
		}

		.bra-card {
			width: calc( 50% - 10px );

			.bra-card__wrapper {
				.bra-card__content {
					.bra-subtitle {
						@include font;
						margin-bottom: 10px;
					}
				}
			}
		}
	}
}
