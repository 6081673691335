@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-sku-slider {

		&.bra-sku-slider--v2 {
			.swiper-container.sku-slider .swiper-slide {
				width: calc(33% - 32px) !important;
			}
		}

		.swiper-container.sku-slider {
			.swiper-slide {
				max-width: none;
				width: calc(50% - 43px) !important;

				.bra-sideCard {
					&__textContent {
						padding: 40px;
					}

					&__title {
						font-size: 24px;
						line-height: 30px;
					}
					&__description {
						font-size: 13px;
						line-height: 19px;
					}
				}
			}

			.swiper-footer {
				width: calc(100% - 60px);
				margin-top: 30px;
				justify-content: space-around;

				.swiper-scrollbar {
					width: 100%;
				}

				.swiper-button-next,
				.swiper-button-prev {
					display: none;
				}
			}

		}
	}
}
