.bra-chemicalmixes {
    &__wrapper {
        background: $ui-07;
        padding: 40px 0px;
    }

    &__flex-grid {
        display: flex;
        flex-wrap: wrap;
        margin: 0 auto;
        max-width: 1500px;
        justify-content: center;
    }

    &__flex-grid_item {
        display: flex;
        flex-direction: row;
        gap: 20px;
        width: calc(100% - 120px);
    }

    &__image {
        width: 60px;
        height: auto;

        img {
            width: inherit;
        }
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding-inline: 0px;
        font-size: var(--fontSize);
    }

    &__title {
        font-family: var(--fontNameBold);
        font-weight: 700;
        font-size: REM(18px);
    }

    &__description {
    }

    &__links a {
        font-family: var(--fontNameBold);
        color: var(--brand-01);
        font-weight: 700;
    }

    &__links a:not(:last-child) {
        margin-right: 10px;
    }
}

// more than 1440px wide
@media only screen and  (min-width:$braun-xl) {}