.bra-highlights {
    width: 100%;
    margin-bottom: 100px;

    .swiper-container {
        overflow: visible;
        z-index: 0;
    }

    .swiper-slide {
        .bra-card--highlights {
            display: flex;
            flex-wrap: wrap;

            .bra-card {
                &__wrapper {
                    flex-basis: 100%;
                }
            }
        }
    }

    .swiper-footer {
        margin: 0;
    }

    .bra-image--highlights{
        height: 0;
        padding-bottom: 100%;

        .bra-image__image{
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
