@media only screen and (max-width:$braun-s - 1) {
	.bra-newsletter-support {
		width: calc( 100% - 40px);

		&__content {
			width: 100%;
			padding: 20px;

			.bra-title {
				font-size: 18px;
				line-height: 22px;
				margin-bottom: 10px;
			}
			.bra-paragraph {
				font-size: 13px;
				line-height: 19px;
				margin-bottom: 20px;
			}
			.bra-btn {
				margin-top: auto;
			}
		}

		.bra-newsletter-support__content + .bra-newsletter-support__content {
			margin-top: 20px;
		}
	}
}
