.dlg-category-links-component {
  width: calc(100% - 60px);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;
  margin-inline: auto;
  box-sizing: border-box;
  row-gap: 30px;
  max-width: 1500px;

  &__title {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    height: 84px;
    font-size: 24px;
    line-height: 30px;

    @media only screen and (min-width:$braun-s) {
      font-size: 32px;
      line-height: 36px;
    }

  }
  &__cards {
    width: 100%;
    display: flex;
    column-gap: 16px;
    row-gap: 8px;
    justify-content: space-between;
    flex-grow: 1;
    flex-wrap: wrap;
    block-size: fit-content;

    @media only screen and (min-width:$braun-m) {
      flex-wrap: nowrap;
    }

    &__single-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: calc(50% - 8px);
      color: inherit;
      text-decoration: none;
      row-gap: 16px;
      padding-bottom: 16px;

      picture {
        background-color: $ui-07;
        border-radius: 5px;
        aspect-ratio: 1 / 1;
        max-width: 100%;
        overflow: hidden;

        img {
          max-width: 100%;
          aspect-ratio: 1 / 1;
          object-fit: cover;
          transition: transform .3s;
          border-radius: 5px;

          &:hover {
            transform: scale(1.1);
          }
        }
      }
      &__text {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        flex-grow: 1;
        width: 100%;
        row-gap: 8px;

        &__title {
          font-weight: bold;
          text-align: center;
          font-size: 14px;
          line-height: 20px;

          @media only screen and (min-width:$braun-s) {
            font-size: 18px;
            line-height: 22px;
          }
          @media only screen and (min-width: $braun-l) {
            font-size: 24px;
            line-height: 30px;
          }
        }
        &__subtitle {
          text-align: center;
          font-size: 14px;
          line-height: 20px;

          @media only screen and (min-width: $braun-l) {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}