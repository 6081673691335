@media only screen and (max-width: $braun-s - 1) {
	.bra-pageHeader__wrapper {
		flex-direction: column;
		padding-bottom: 18px;

		.bra-title {
			font-size: 35px;
			line-height: 44px;
		}
		.bra-link {
			margin-left: initial;
		}

		&.bra-pageHeader--category-header {

			.bra-title {
				font-size: 21px;
				line-height: 26px;
			}
			.bra-link {
				font-size: 13px;
				line-height: 18px;
				margin-left: initial;
			}
		}
	}
}