@media only screen and (max-width: $braun-s - 1) {
    .bra-basic-form {
        padding: 40px 20px;

        &__wrap {
            width: 100%;
        }

        &__title {
            font-size: 24px;
            line-height: 1.13;
            margin-bottom: 30px;
        }

        &__grid {
            .bra-input-animated {
                flex: 0 1 calc(100% - 20px);

                + .bra-input-animated {
                    margin-top: 10px;
                }
            }
        }
    }
}
