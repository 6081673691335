@media only screen and (max-width: $braun-s - 1) {
	.bra-article-link {
		padding: 0;
		padding-top: 30px;

		&__wrapper {
			padding: 0;

			position: relative;
			margin: 0 20px;
			width: calc(100% - 40px);
		}

		&__heading {
			padding: 30px 0;
			width: 100%;

			.bra-title {
				font-size: 24px;
				line-height: 1;
				margin: 0;
			}
		}
		&__content {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			//padding: 0 20px;

			padding-top: 30px;
			padding-bottom: 0;
			margin-bottom: 30px;

			border-top: 0;
			border-bottom: 1px solid $ui-08;

			.bra-card {
				position: relative;
				width: 100%;
				overflow: visible;
				margin-bottom: 60px;
				&::after {
					content: '';
					position: absolute;
					top: -30px !important;
					height: 1px;
					width: 100%;
					background: $ui-08;
				}
			}
			.bra-card:nth-last-child(-n+2) {
				margin-bottom: 60px;
			}
			.bra-card:last-child {
				margin-bottom: 30px;
			}

		}

	}
}