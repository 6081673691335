@media only screen and (max-width: $braun-s - 1) {
	.bra-productCard {
		padding: 30px 10px;
		&__figure {
			.bra-productCard__link {
			}
			.bra-image {
				margin-bottom: 20px;
			}

			.bra-productCard__badgesList {
				.bra-productCard__badge {
					@include font;
					font-size: 13px;
					line-height: 18px;
					padding: 4px 10px;
				}
			}

		}

		&__figureCaption {
			text-align: center;
			margin: 0;

			.bra-productCard__title {
				font-size: 15px;
				line-height: 21px;
			}
		}
	}
}