@media only screen and (max-width: $braun-s - 1) {
	.bra-navigation {
		&__list {
			margin-bottom: 30px;

			&-container {
				width: 100%;
			}

			&-item {
				label {
					font-size: 20px;
					padding-bottom: 10px;
				}
			}

			&-title {
				font-size: 13px;
				margin-bottom: 20px;
			}

			li.active + .bra-navigation__categoryWrapper {
				padding-top: 0;
			}
		}

		#bra-navigation__list-spacer--fixed {
			background: rgba(white,0.86);
		}

		&.active {
			background: white;

			#bra-navigation__list-spacer--fixed {
				background: rgba(white,0.86);
			}
			.bra-navigation__list-container {
				.bra-navigation__list-item {
					// &.active {
					// 	opacity: 0.3;
					// }
				}
			}
		}

		.bra-navigation__categoryWrapper {
			flex-direction: column;
			margin-top: 5px;
			margin-bottom: 30px;
			overflow: hidden;

			.bra-navigation__category-item {
				width: 100%;
				margin-right: 0;

				&:nth-child(3n+2) {
					margin: 0;
				}

				.mobileOnly {
					display: block;
				}

				.bra-card {
					display: none
				}
			}
			.bra-navigation__category-item  + .bra-navigation__category-item {
				margin-top: 10px
			}
		}

		&__wrapper {
			width: calc(100% - 20px);
			height: 100%;
			padding: 0;
			padding-left: 20px;
			justify-content: space-between;
		}

		&__visual {
			display: none;
			&-container {
				display: none;
			}
		}

		&__footer {
			width: auto;
			padding-right: 20px;
			padding-bottom:20px;

			max-width: calc(100% + 40px);
			width: calc(100% - 20px);
			padding-right: 0px;
			padding-bottom: 20px;
			padding-left: 0px;

			.bra-navigation__supportWrapper {
				.bra-link {
					font-size: 13px
				}
			}
		}
	}
}
