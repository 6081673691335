@media only screen and (max-width: $braun-s - 1) {
    .bra-awards {
        &__wrapper {
            margin: 0 20px;
        }
        
        &__box {      
            width: 100%;
            margin: 0 0 20px 0;
            padding: 20px 20px 25px 20px;

            &:nth-child(2n+2) {
                margin: 0 0 20px 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:nth-last-child(2) {
                margin-bottom: 20px;
    
                &:nth-child(even) {
                    margin-bottom: 20px;
                }
            }
            & > .bra-image {
                width: 120px;
                height: 120px;
                margin: 0 auto 10px auto;
            }
        }
        &__text {
            width: 100%;
        }
        &__title {
            font-size: 15px;
            line-height: 1.47;
        }
        &__description {
            font-size: 13px;
            line-height: 1.46;
        }    
    }
}
