.bra-articleBanDataBox {
    font-size: 15px;
    line-height: normal;

    display: inline-flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 100%;
    border-radius: 4px;
    background-color: $ui-04;
    padding: 24px 24px 40px 24px;

    &__topText {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 15px;
        font-weight: bold;
        color: $brand-03;
        margin: 0 0 24px 0;

        &--support-02 {
            color: $support-02;
        }
        &--support-04 {
            color: $support-04;
        }
        &--support-05 {
            color: $support-05;
        }
    }
    & .bra-title {
        margin: 0 0 16px 0;
    }
    & .bra-subtitle {
        margin: 0 0 16px 0;
    }
    & .bra-paragraph {
        margin: 0 0 24px 0;
    }
    & .bra-label {
        margin: 0 auto 24px 0;
    }
    &--transparent {
        background: none;
        background-color: transparent;
    }
}