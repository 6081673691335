@media only screen and (min-width:$braun-s) and (max-width:$braun-m - 1) {
    .bra-newsletter-support {
        width: calc(100% - 60px);
        margin: 0 30px;

        &__content {
            padding: 20px;

            .bra-title {
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 10px;
            }
            .bra-paragraph {
                font-size: 13px;
                line-height: 19px;
                margin-bottom: 20px;
            }
            .bra-btn {
                margin-top: auto;
            }
        }
    }
}
