.bra-toolbarPlp {
    @include flex(wrap);
    align-content: flex-start;
    width: 100%;
    height: auto;
    padding: 15px 0;

    &__wrap {
        @include flex(wrap);
        width: 100%;
        height: auto;
        padding: 15px 0;

        & .bra-btnIcon--filter,
        & .bra-btnIcon--sort {
            font-size: 20px;

            & .bra-btnIcon__text {
                font-size: 15px;
            }
        }
        & .bra-btnView {
            display: none;
            margin: 0 0 0 auto;
        }
        &.-filtersTags {
            padding: 15px 0 0 0;

            & .swiper-slide {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: auto;
                margin: 0 10px 0 0;

                &:nth-last-of-type(2) {
                    margin: 0 20px 0 0;
                }
            }
        }
        &.-filters {
            max-height: 0;
            transition: max-height 0.35s ease;
            padding: 0;
            overflow: hidden;

            & .bra-btn--apply {
                width: 230px;
                margin: 0 auto 30px auto;
                box-sizing: border-box;
            }
            // &.-open {
            //     display: flex;
            // }
        }
    }
    & .swiper-scrollbar {
        display: none !important;
    }
    &__filterContainer {
        @include flex(wrap);
        width: 100%;
        height: auto;
        background-color: $ui-07;
        border-radius: 5px;
        padding: 0 30px;
        margin: 15px 0;
        box-sizing: border-box;
    }
    &__removeFilterBtn {
        @include fontBold;
        display: inline-flex;
        align-items: center;
        width: auto;
        font-size: 13px;
        text-decoration: underline;
        border: none;
        background: none;
        background-color: transparent;
        -webkit-appearance: none;
        -moz-appearance: none;
        cursor: pointer;
    }
    &__accordion {
        @include flex(wrap);
        width: 100%;
        height: auto;
        max-height: 67px;
        border-bottom: 1px solid $ui-08;
        transition: max-height 0.22s ease;
        overflow: hidden;

        &:last-of-type {
            margin: 0 0 30px 0;
        }
        &Head {
            @include flex(wrap);
            width: 100%;
            height: auto;
            padding: 30px 0 0 0;
            cursor: pointer;
            position: relative;
        }
        & .bra-toolbarPlp__filterTitle {
            @include fontBold;
            display: block;
            width: 100%;
            line-height: 18px;
            font-size: 18px;
            font-weight: 700;
            margin: 0 0 20px 0;
        }
        &.-priceRange {
            & .bra-toolbarPlp__filterTitle {
                margin-bottom: 27px;
            }
        }
        & .bra-icn__arrow--down {
            display: inline-block;
            width: 16px;
            height: 10px;
            font-size: 0;
            transform: rotateX(0deg);
            transform-origin: center;
            transition: transform 0.18s ease;
            position: absolute;
            top: 34px;
            right: 0;

            &::before {
                display: inline-block;
                width: 16px;
                height: 10px;
                line-height: 10px;
                font-size: 16px;
            }
        }
        &.-open {
            & .bra-icn__arrow--down {
                transform: rotateX(180deg);
            }
        }
        & .bra-toolbarPlp__filterContent {
            @include flex(wrap);
            width: 100%;
            height: auto;
        }
        & .bra-input__wrapper {
            align-items: flex-start;
            width: 25%;
            padding: 0 10px 0 0;
            margin: 0 0 0 auto;
            margin: 0 0 20px 0;
            box-sizing: border-box;

            & .bra-input__input {
                width: 20px;
                min-width: initial;
                max-width: none;
                height: 20px;
                min-height: initial;
                max-height: none;
                margin: 0 5px 0 0;
                position: relative;
                z-index: 1;

                &::before {
                    width: 20px;
                    height: 20px;
                    border: 1px solid $ui-09;
                    border-radius: 5px;
                    // background-color: $brand-03;
                    background-color: transparent;
                    box-shadow: none;
                    box-sizing: border-box;
                    pointer-events: none;
                    position: absolute;
                    z-index: 2;
                }
                &::after {
                    @include fontIcon;
                    content: '\e80b';
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    font-size: 14px;
                    opacity: 0;
                    transition: opacity 0.22s linear;
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 3;
                }
                &:checked {
                    &::after {
                        opacity: 1;
                    }
                }
            }
            & .bra-input__label {
                order: 1;
                // height: 20px;
                height: auto;
                line-height: 16px;
                font-size: 13px;
                font-weight: 700;
                padding: 2px 0;
            }
            &.bra-input--disabled {
                opacity: 0.25;
            }
            &.bra-input--checkbox {
                & .bra-input__label {
                    width: calc(100% - 25px);
                }
            }
        }
        // & .bra-input--checkbox {
        //     align-items: flex-start;
        //     width: 25%;
        //     padding: 0 10px 0 0;
        //     margin: 0 0 20px 0;
        //     box-sizing: border-box;

        //     & .bra-input__label {
        //         width: calc(100% - 30px);
        //         line-height: 20px;
        //         font-size: 15px;
        //         padding: 0;

        //         &--sup {
        //             line-height: 20px;
        //             opacity: 1;
        //             margin: 0 0 0 5px;
        //         }
        //     }
        //     & .bra-input__input {
        //         display: inline-block;
        //         width: 20px;
        //         min-width: initial;
        //         max-width: none;
        //         height: 20px;
        //         min-height: initial;
        //         max-height: none;
        //         border: 1px solid $ui-09;
        //         border-radius: 5px;
        //         box-sizing: border-box;

        //         &::before {
        //             box-shadow: none;
        //         }
        //     }
        //     &.bra-input--disabled {
        //         opacity: 0.25;
        //     }
        // }
        & .bra-radio {
            width: 25%;
            padding: 0 10px 0 0;
            margin: 0 0 20px 0;
            box-sizing: border-box;
        }
    }
}

/* input range */

.bra-toolbarPlp__range {
    @include flex(wrap);
    width: 100%;
    height: auto;
    // background: lightgoldenrodyellow;
    padding: 0 10px;
    margin-bottom: 30px;
    box-sizing: border-box;

    &Bar {
        width: 100%;
        max-width: calc(366px - 16px);
        margin-bottom: 20px;
        position: relative;

        &::before,
        &::after {
            content: '';
            width: 8px;
            height: 6px;
            background-color: $ui-09;
            position: absolute;
            top: calc(50% - 3px);
            left: -8px;
        }
        &::after {
            left: initial;;
            right: -8px;
        }
    }
    &LabelWrap {
        @include flex(wrap);
        width: 100%;
        height: 15px;
        position: relative;

        & .bra-toolbarPlp__rangeLabel {
            display: inline-block;
            height: 15px;
            line-height: 15px;
            transform: translateX(-50%);
            position: absolute;
            top: 0;

            &.-min {
                transform: none;
                left: -10px;
            }
            &.-max {
                transform: translateX(-100%);
                left: calc(366px - 8px);
            }
        }
    }
}

.bra-toolbarPlp__rangeBar.noUi-target.noUi-ltr.noUi-horizontal {
    height: 6px;
    border: none;
    border-radius: 0;
    background-color: #9b9b9b;
    box-shadow: none;
}
.noUi-connect {
    background-color: #000000;
}
.noUi-handle.noUi-handle-lower,
.noUi-handle.noUi-handle-upper {
    width: 20px;
    height: 20px;
    border: 1px solid $ui-09;
    border-radius: 50%;
    box-shadow: none;
    box-sizing: border-box;
    top: -6px;
    right: -10px !important;

    // & .noUi-touch-area {

        &:hover {
            cursor: grab;
        }
        &:active {
            cursor: grabbing;
        }
    // }
}
.noUi-handle.noUi-handle-lower::before,
.noUi-handle.noUi-handle-lower::after,
.noUi-handle.noUi-handle-upper::before,
.noUi-handle.noUi-handle-upper::after {
    display: none;
}