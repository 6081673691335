.bra-title {
    font-size: 48px;
    line-height: 56px;

    display: block;
    width: 100%;
    
    font-size: 48px;
    line-height: 56px;
    margin-bottom: .7rem;

    @include fontBold;
    color: $brand-01;

    &--light {
        @include font;
        line-height: 1;
    }
    &--underlined {
        border-bottom: 1px solid $ui-01;
        padding: 16px 0 8px 0;
    }
    &--center {
        text-align: center;
    }
    &--brand-03 {
        color: $brand-03;
    }
    &--support-02 {
        color: $support-02;
    }
    &--support-03 {
        color: $support-03;    
    }
}


/*M*/
@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-title {
        font-size: 36px;
        line-height: 40px;
    }
}
/*S*/
@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-title {
        font-size: 30px;
        line-height: 36px;
    }
}
/*XS*/
@media only screen and (max-width: $braun-s - 1) {
    .bra-title {
        font-size: 24px;
        line-height: 27px;
    }
}