.braun-navigation-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: flex-start;

  &__mask {
    display: none;
    @media screen and (min-width: $braun-s) {
      border-radius: 10px;
      background-image: linear-gradient(
        to right,
        rgba($brand-01, 0.6),
        transparent
      );
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  &__image {
    max-width: 100%;
    height: auto;
    display: block;
    border-radius: 10px;
    margin-bottom: 20px;
    @media screen and (min-width: $braun-s) {
      margin-bottom: 0;
    }
  }

  &__content {
    @media screen and (min-width: $braun-s) {
      position: absolute;
      top: 30px;
      left: 30px;
      bottom: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 0;
      width: calc(100% - 60px);
    }
  }

  &__text {
    width: 100%;
  }

  &__kicker,
  &__title {
    @media screen and (min-width: $braun-s) {
      color: $brand-03;
    }
  }

  &__kicker {
    @include font;
    margin: 0 0 10px 0;
    font-size: 16px;
    line-height: 20px;

    @media screen and (min-width: $braun-m) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__title {
    @include fontBold;
    margin: 0 0 20px 0;
    font-size: 21px;
    line-height: 26px;

    @media screen and (min-width: $braun-s) {
      line-height: 27px;
      max-width: 60%;
    }

    @media screen and (min-width: $braun-m) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &__link {
    display: inline-flex;
    background-color: $brand-01;
    border-color: $brand-01;
    text-decoration: none;
    color: $brand-03;
    @include fontBold;
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: $braun-s) {
      background-color: $brand-03;
      color: $brand-01;
      border-color: $brand-03;
    }

    &:hover {
      background-color: $support-02;
    }

    .-spacer-wrapper {
      display: flex;
      padding: 9px 0;
      .-spacer {
        display: inline-flex;
        width: 1px;
        min-height: 100%;
        margin: 0 10px;
        background-color: $brand-03;
        @media screen and (min-width: $braun-s) {
          background-color: $ui-08;
        }
      }
    }

    span {
      @include font;
    }
  }
}
