.bra-prodTile {

  &__priceWrap {
    height: auto;
  }

  &__vat-included {
    @include font;
    display: block;
    font-size: 10px;
    line-height: 2;
    letter-spacing: normal;
    color: var(--ui-06);
    width: 100%;
  }
}
