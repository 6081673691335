@media only screen and (max-width: $braun-s - 1) {
	.bra-gallery {
		margin: 0;

		&__wrapper {
			display: none;
		}

		&__swiperWrapper {
			display: block;
			position:relative !important;
			visibility: visible;
            border-radius: 0;
            background: transparent;

			.swiper-slide {
				max-width: calc(100vw - 40px) !important;

				.bra-image {
					padding-bottom: 100%;
					height: 0;

					.bra-image__image {
						position: absolute;
					}
				}
			}

			.swiper-footer {
                position: relative;
				display: flex;
				justify-content: space-around;
				align-items: center;
				width: calc(100% - 40px) !important;
				margin: 0 auto !important;

				.swiper-button-close {
					display: none;
				}

				.swiper-button-prev {
					order: 1;
				}
				.swiper-scrollbar {
                    display: block;
					order: 2;
					display: block;
				}
				.swiper-button-next {
					order: 3;
				}

				.swiper-button-next,
				.swiper-button-prev {
					display: none
				}

				.swiper-scrollbar {
					position: relative;
					left: 0;
					bottom: 0;
					height: 3px;
					width: 90%;
					background: $ui-08;
					margin-top: 30px;

					.swiper-scrollbar-drag {
						background: $brand-01;
					}
				}
			}
		}
	}
}
