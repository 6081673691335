.bra-crosslinkCardGrid {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background-color: $ui-07;
    // padding: 40px 20px;
    padding: 40px 40px;
    box-sizing: border-box;

    &--two {
        & .bra-card.bra-card--crosslink   {
            width: calc(50% - 10px);
            margin: 0 20px 20px 0;

            &:nth-child(2n+2) {
                margin: 0 0 20px 0;
            }
        }
    }
    @media (-ms-high-contrast: none) {
        max-width: 1440px;
    }
}