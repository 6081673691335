@media only screen and (min-width:$braun-m) and (max-width: $braun-l - 1) {
    .bra-article-link {
		&__wrapper {
			padding: 0;
		}

		&__heading {
			padding: 60px 0;
			padding-bottom: 30px;

			.bra-title {
				font-size: 30px;
				line-height: 1;
				margin: 0;
			}
		}
		&__content {
			.bra-card {
				width: calc(50% - 15px);
				margin-bottom: 80px;
			}
		}

	}
}
