@media only screen and (max-width: $braun-s - 1) {
    .bra-cookieDiscalimer {
        &__wrapper {
            flex-direction: column;
            align-items: flex-end;

            .bra-btn {
                display: block;
                width: 100%;
                margin: 0;
                margin-top: 20px;
            }
        }
    }
}