@media only screen and (max-width: $braun-s - 1) {
	.bra-card {
		width: 100%;
		margin-bottom: 20px;

		&__wrapper {
			height: auto;
			min-height: 0;
			padding-bottom: 0;
		}

		&__visual {
			position: relative;
			border-radius: 5px 5px 0 0;
			overflow: hidden;
			height: auto;
			padding-bottom: 0;
			.bra-image {
				padding-bottom: 0;
				height: auto;
			}
		}

		&__content {
			padding: 20px;

			.bra-subtitle {
				margin-bottom: 5px;
				font-size: 15px;
				line-height: 18px;
			}
			.bra-title {
				font-size: 18px;
				line-height: 22px;
			}
		}
		&__actions {
			padding: 20px;
			padding-bottom: 30px;
			padding-top: 0;
		}

		&--bg-image {
			.bra-card__content {
                padding-top: 30px;
                padding-bottom: 20px;

				.bra-subtitle,
				.bra-title {
					color: $brand-01;
				}
			}

			.bra-card__visual {
				position: relative;
				width: 100%;
				max-height: 214px !important;
				z-index: 1;
				padding-bottom: 0;

				border-radius: 5px 5px 0 0;

				.bra-image {
					padding-bottom: 0%;
					height: auto;
				}

				&::after {
					display: none
				}
			}

			.bra-card__actions {
				padding: 20px;
				padding-top: 0;

				.bra-btn {
					border-color: $brand-01;

					@include hover-supported() {
						&:hover {
							border-color: $support-02;
							background: transparent;
							color: $support-02;
						}
					}
				}
			}
		}

		&--horizontal {
			.bra-card__visual {
				object-fit: cover;

				.bra-image {
					height: auto;
					padding-bottom: 0;
					object-fit: cover;
					transform: none;
				}
			}

			.bra-card__content {
				padding: 0;

				.bra-subtitle {
					margin-bottom: 5px;
					font-size: 13px;
					line-height: 19px;
				}
				.bra-title {
					font-size: 15px;
					line-height: 19px;
				}
			}
			.bra-card__actions {
				padding: 0;
				margin-top: auto;
				padding-top: 15px;

				.bra-btnIcon {
					font-size: 15px
				}
			}
		}

		&--recipe {
			.bra-card__visual {
				position: relative;
				width: 100%;
				height: 100%;
				z-index: 1;
				padding-bottom: 0;
				display: flex;
				flex-grow: 1;

				.bra-image {
					padding-bottom: 0;
					height: auto;
					position: relative;

					img {
						position: relative;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						transform: scale3d(1, 1, 1);
						object-fit: cover;

						transition: transform .8s ease-in-out;
					}
				}

				&::after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 50%;
					background-image: linear-gradient(to bottom, rgba(black, 0), rgba(black, 1));
					opacity: 0.65;
					transform: translate3d(0,0,0);
				}
			}
			.bra-card__content {
				position: absolute;
				bottom: 0;
				opacity: 0;
				transform: translateY(30px);
				z-index: 2;
				transition: transform .6s ease-in-out;

				padding: 20px;
			}
			.bra-card__actions {
				position: absolute;
				padding: 20px;
				padding-top: 0;
				padding-bottom: 20px;
				bottom: 0;
				transform: translateY(100px) translate3d(0,0,0);;

				z-index: 2;

				transition: transform .6s ease-in-out;

				.bra-btn {
					opacity: 0;
					transition: opacity 1s ease-in-out;
				}
			}


			&.active{
				.bra-card__visual {
					.bra-image {
						img {
							transform: scale3d(1.05, 1.05, 1.05);
							transition: transform .8s ease-in-out;
						}
					}
				}
				.bra-card__content {
					opacity: 1;
					transform: translateY(-54px);
					transition: transform .36s ease-in-out;
				}
				.bra-card__actions {
					transform: translateY(0);
					transition: transform .36s ease-in-out;

					.bra-btn {
						opacity: 1;
						transition: opacity .6s ease-in-out;
						transition-delay: .12s;
					}
				}
			}

		}

		&--category {
			.bra-card__content {
                padding: 20px;

                .bra-title--category {
					font-size: 18px;
					line-height: 22px;
				}

				.bra-paragraph--category {
					font-size: 13px;
					line-height: 19px;
					margin-top: 5px;
				}
			}
			.bra-card__actions {
				padding: 20px;
			}
			&.active {
				// background: white;

				// &.bra-corvette {
				// 	background-color: $bra-corvette
				// }
				// &.bra-sweet-corn {
				// 	background-color: $bra-sweet-corn
				// }
				// &.bra-cotton-seed {
				// 	background-color: $bra-cotton-seed
				// }
				// &.bra-pastel-purple {
				// 	background-color: $bra-pastel-purple
				// }
				// &.bra-carolina-blue {
				// 	background-color: $bra-carolina-blue
				// }
				// &.bra-yellow-green {
				// 	background-color: $bra-yellow-green
				// }

				.bra-card__visual {
					.bra-card__visual--default {
						opacity: 0;
						transform: translateY(-5%);

						transition: opacity 0.3s linear,
									transform 0.3s linear;
					}

					.bra-card__visual--hover {
						opacity: 1;
						transform: translateY(0%);

						transition: opacity 0.3s linear,
									transform 0.3s linear;
					}
				}
			}
		}

		&--product {
			.bra-card__wrapper {
			}
			.bra-card__visual {
				.bra-image {
					max-width: 200px;
				}

				.bra-card__badgesList {
					right: 15px;

					.bra-card__badge {
					}
				}
			}
			.bra-card__content {
				padding: 20px 15px;
			}

			.bra-card__actions {
				padding: 20px 15px;
				padding-top: 0;
			}
		}

		&--box {
			.bra-card {
				&__content {
					padding: 10px 20px;

					.bra-title {
						font-size: 13px;
					}
				}
            }
            & .bra-card__visual {
                & .bra-image {
                    max-width: 105px;
                    height: 140px;
                }
            }
		}

		&--highlights {
			.bra-card {
				&__content {
					padding: 20px 25px 25px;
					.bra-title {
						margin-bottom: 5px;
						font-size: 15px;
						line-height: 1.47;
					}

					.bra-paragraph {
						font-size: 13px;
  						line-height: 1.46;
					}
				}
			}
		}
	}
}
