@media only screen and (max-width: $braun-s - 1) {
	.bra-crosslink-box {
        width: calc( 100% - 40px);
        padding: 40px 30px;

        &__wrap,
        &__content {
            width: 100%;
        }
        &__wrap {
            margin: 0 0 10px 0;

            & .bra-title {
                line-height: 27px;
                font-size: 24px;
                margin: 0;
            }
            & .bra-btn {
                display: none;
            }
        } 
        &__content {
            margin: 0 0 25px 0;

            & .bra-paragraph {
                line-height: 19px;
                font-size: 13px;
            }      
        }
        & .bra-btn--mobile {
            display: inline-flex;
        }
	}
    // .bra-crosslink-box {
	// 	padding: 0;
    //     width: calc(100vw - 40px);
    //     margin: 0 20px;

	// 	&__wrapper {
	// 		padding: 20px 0;
    //     }
    //     &__inner {
    //         display: flex;
    //         width: 100%;
    //         margin: 0 30px;
    //     }
    //     &__title,
    //     &__content {
    //         width: 100%;
    //     }
    //     &__content {
    //         margin-top: 10px
    //     }

    //     .bra-title {
    //         font-size: 24px;
    //         line-height: 27px;
    //     }
    //     .bra-paragraph {
    //         font-size: 13px;
    //         line-height: 19px;
    //     }

    //     &__CTA {
    //         margin-top: 25px;

    //         .bra-btn {
    //             font-size: 13px;
    //             padding: 0 25px;
    //         }
    //     }
	// }
}