.bra-productLink {
	@include flex(wrap);
	align-items: center;
	justify-content: center;
	width: 100%;

	font-size: 15px;
	line-height: normal;

	&__imageWrap {
		display: block;
		width: 100%;
		height: 204px;
		background-color: $ui-03;
	}
	&__image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	& .bra-link {
		text-align: center;
	}
}