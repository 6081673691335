.bra-logo__wrapper {
	display: inline-flex;
	flex-direction: column;
	text-align: center;
	@include fontBold;

	user-select: none;

	.bra-logo {
		height: 1em;

		.logo--fill {
			fill: #000;
		}

		&--black {
			.logo--fill {
				fill: #000;
			}
		}
		&--white {
			.logo--fill {
				fill: #fff;
			}
		}

	}
}