@keyframes loaderAnimation {
	50% {
		transform: translateX(-50%) translateY(50%) scale(0.6);
		opacity: 1;
	}
	0%, 100% {
		transform: translateX(-50%) translateY(50%) scale(1);
		opacity: 0;
	}
}
@keyframes pulse {
    from {opacity :0}
    to {opacity: 1}
}

.bra-loader {
	z-index: 50000;

	position: absolute;
	@media (-ms-high-contrast:none) {
		position: fixed;
	}
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(50%);
	user-select: none;

	font-size: 10px;
	line-height: normal;

	transition: height 0.8s ease-in-out,
				opacity 0.5s ease-in-out;


	color: $brand-01;

	span {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%) translateY(-50%);

		padding: 7px;
		margin-top: 70px;
		animation: pulse 0.5s infinite;
	}



	&::after {
		display: block;
		position: absolute;
		top: -200%;
		left: 50%;
		content: '';
		width: 30px;
		height: 30px;
		border-radius: 60px;

		transform: translateX(-50%) translateY(50%);

		box-shadow: 0 0 0 0.7px $brand-01;
		animation: loaderAnimation 2.5s infinite ease-in-out;
	}

	&--overlay {
		cursor: progress;

		

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translateX(-50%) translateY(-50%);
			width: 100vw;
			height: 100vh;
			background: rgba(white,0.8);
			overflow: hidden;
			
		}
	}

	&--inverted {
		color: $brand-03;

		&::before {
			background: rgba(black,0.8);
		}
		&::after {
			box-shadow: 0 0 0 0.7px $brand-03;
		}
	}
}