$paddingContainer: 30;
$tweakpointIcons: 360;

.braun-header {
  opacity: 0;
  width: 100vw;
  height: 60px;
  background-color: $brand-01;
  position: fixed;
  z-index: 1000;
  top: 0;
  transition: background 0.2s ease-in-out, opacity 0.2s ease-in-out;

  &.hasStripe {
    height: 106px;
  }

  &.hasStripe.--stripe-hidden {
    height: 60px;

    @media screen and (min-width: $braun-m) {
      height: 80px;
    }

    .braun-header-stripe {
      display: none;
    }
  }

  &.--transparent {
    background-image: linear-gradient(
      to bottom,
      rgba($brand-01, 0.8),
      transparent
    );
    background-color: transparent;
  }

  &.--has-corporate-stripe {
    height: 80px;
    @media screen and (min-width: $braun-m) {
      height: 100px;
    }
  }

  @media screen and (min-width: $braun-m) {
    height: 80px;

    &.hasStripe {
      height: 136px;
    }
  }

  &.hasStripe.--stripe-hidden {
    .braun-header-container {
      height: 60px;
      padding: 0 20px;
      
      @media screen and (min-width: $braun-m) {
        height: 80px;
        margin-top: 0;
      }
    }
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 20px;
    height: 60px;

    .hasStripe & {
      height: 106px;
      padding: 46px 20px 0;

      @media screen and (min-width: $braun-m) {
        height: 90px;
      }
    }

    @media screen and (min-width: $braun-m) {
      height: 80px;
      max-width: calc(1440px + #{($paddingContainer * 2)}px);
      padding: 0 #{$paddingContainer}px;

      .hasStripe & {
        margin-top: 46px;
        padding: 0 #{$paddingContainer}px;
      }
    }
  }

  &-logo-link {
    cursor: pointer;
  }

  &-logo {
    width: 70px;
    height: 30px;

    @media screen and (min-width: $braun-m) {
      width: 82px;
      height: 35px;
    }

    .--fill {
      fill: $brand-03;
    }
  }

  &-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include gap(15px, 'r');

    &-wrapper {
      display: none;
      @media screen and (min-width: #{$tweakpointIcons}px) {
        display: flex;
        @include gap(15px, 'r');
      }
    }

    .bra-btnIcon {
      width: 20px;
      background-color: transparent;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      &:hover {
        color: $brand-03;
        .bra-icn {
          color: $brand-03;
        }
      }
    }

    .bra-icn {
      display: block;
      font-size: 19px;
      color: $brand-03;
    }

    .braun-navigation-cta {
      background-color: $brand-03;
      border: none;
      padding: 8px 15px;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include gap(10px, 'r');
      font-size: 13px;
      @include fontBold;
      color: $brand-01;
      cursor: pointer;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 50px;
      }

      &:hover {
        .bra-icn,
        span {
          color: $brand-01;
        }
      }

      @media screen and (min-width: $braun-m) {
        display: none;
      }
    }

    .bra-icn__hamburger-menu {
      font-size: 14px;
      color: $brand-01;
    }
  }

  &-card-hidden-wrapper {
    display: none;
  }

  &-menu-links {
    display: none;

    @media screen and (min-width: $braun-m) {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: flex-start;
      padding: 0;
      margin: 0 0 0 60px;
      list-style: none;
      height: 100%;

      > li {
        height: 100%;
      }
    }
  }

  &-menu-link {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
    color: $brand-03;
    font-size: 18px;
    line-height: 20px;
    @include fontBold;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
    margin-right: 30px;
    height: 100%;

    &.--open,
    &:hover {
      color: $support-02;
    }
  }

  &.--open {
    .braun-header-container {
      position: relative;
      z-index: 1000;

      .braun-header-logo,
      .braun-header-logo .--fill,
      .braun-header-menu-link,
      .bra-btnIcon,
      .bra-icn {
        color: $brand-01;
        fill: $brand-01;
      }
    }

    .braun-header-menu-link {
      &.--open,
      &:hover {
        color: $support-02;
      }
    }
  }
}
