/* Chrome hack: SVG is rendered more smooth in Windows. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'bra-icons';
        src: url('../font/bra-icons.svg?85022927#bra-icons') format('svg');
    }
}
*/

[class*="bra-icn__"]:before {
    font-family: $fontNameIcons;
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: auto;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1.07em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.bra-icn__account:before { content: '\e800'; } /* '' */
.bra-icn__hamburger-menu:before { content: '\e801'; } /* '' */
.bra-icn__cart:before { content: '\e802'; } /* '' */
.bra-icn__arrow--down:before { content: '\e803'; } /* '' */
.bra-icn__logo:before { content: '\e804'; } /* '' */
.bra-icn__search:before { content: '\e805'; } /* '' */
.bra-icn__social--facebook:before { content: '\e806'; } /* '' */
.bra-icn__social--twitter:before { content: '\e807'; } /* '' */
.bra-icn__social--youtube:before { content: '\e808'; } /* '' */
.bra-icn__plus:before { content: '\e809'; } /* '' */
.bra-icn__no:before { content: '\e80a'; } /* '' */
.bra-icn__yes:before { content: '\e80b'; } /* '' */
.bra-icn__print:before { content: '\e80c'; } /* '' */
.bra-icn__chat:before { content: '\e80d'; } /* '' */
.bra-icn__arrow--left:before { content: '\e80e'; } /* '' */
.bra-icn__arrow--right:before { content: '\e80f'; } /* '' */
.bra-icn__arrow--up:before { content: '\e810'; } /* '' */
.bra-icn__share:before { content: '\e811'; } /* '' */
.bra-icn__faqs:before { content: '\e812'; } /* '' */
.bra-icn__icn-sort:before { content: '\e813'; } /* '' */
.bra-icn__icn-play:before { content: '\e814'; } /* '' */
.bra-icn__close-light:before { content: '\e815'; } /* '' */
.bra-icn__instagram:before { content: '\e816'; } /* '' */
.bra-icn__star:before { content: '\e81a'; } /* '' */
.bra-icn__outline-star:before { content: '\e81b'; } /* '' */
.bra-icn__filter:before { content: '\e81c'; } /* '' */
.bra-icn__detail:before { content: '\e81f'; } /* '' */
.bra-icn__grid:before { content: '\e820'; } /* '' */
.bra-icn__social--pinterest:before { content: '\e828'; } /* '' */
.bra-icn__wishlist:before { content: '\e832'; } /* '' */
.bra-icn__bookmark-active:before { content: '\e838'; } /* '' */
.bra-icn__bookmark-inactive:before { content: '\e839'; } /* '' */
.bra-icn__recipe-difficulty:before { content: '\e83a'; } /* '' */
.bra-icn__recipe-time:before { content: '\e83b'; } /* '' */
.bra-icn__wishlist-active:before { content: '\e83c'; } /* '' */
.bra-icn__wishlist-inactive:before { content: '\e83d'; } /* '' */
.bra-icn__error:before { content: '\e83e'; } /* '' */
.bra-icn__returns:before { content: '\e84a'; } /* '' */
.bra-icn__delivery:before { content: '\e84b'; } /* '' */
.bra-icn__where-to-buy:before { content: '\e84c'; } /* '' */
.bra-icn__secure-shipping:before { content: '\e84d'; } /* '' */

[class*="__legacy"]:before {
	font-family: $fontNameIcons__legacy;
	font-style: normal;
	font-weight: normal;
    speak: none;

	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	text-align: center;
	font-size: 1.5em;
	/* opacity: .8; */

	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;

	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;

	/* Animation center compensation - margins should be symmetric */
	/* remove if not needed */

	/* you can be more comfortable with increased icons size */
	/* font-size: 120%; */

	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* Uncomment for 3D effect */
	/* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.bra-icn__legacy-arrow--left:before {content: ""}
.bra-icn__legacy-arrow--right:before {content: ""}
.bra-icn__legacy-arrow--down:before {content: ""}
.bra-icn__legacy-close:before {content:''}
.bra-icn__legacy-minus:before {content:''}
.bra-icn__legacy-plus:before {content:''}
.bra-icn__legacy-dot:before {content:''}
.bra-icn__legacy-thunder:before {content:''}