@media only screen and (max-width: $braun-s - 1) {
	.bra-selectionBar,
	.bra-selectionBar.visible {
		position: relative;
		bottom: initial;
		left: initial;

		transform: none;
		transition: none;

		width: calc(100% - 40px);
		margin: 0 auto;

		&__wrapper {
			flex-direction: column;

			.bra-selectionBar__action {
				width: auto;
				padding: 22px 20px;
				display: flex;

				.bra-paragraph {
					margin-bottom: 22px;
				}
			}
			.bra-selectionBar__carousel {
				width: calc( 100% - 40px );
				margin: 0 auto;
				border-top: 1px solid $ui-08;
			}
		}
	}
	
}