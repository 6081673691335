.bra-subHeadline__wrapper {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 30px;

    font-size: 15px;
    line-height: normal;
}
.bra-subHeadline {
    display: block;
    width: 100%;
    
	font-size: var(--fontSize);
	line-height: var(--lineHeight);

    margin-bottom: .7rem;

    @include fontBold;

    color: $brand-01;

    font-size: 48px;
    line-height: 56px;
}