.bra-movingLetters {
  @media screen and (max-width: $braun-m) {
    max-width: 840px;
  }

  &__container {
    @media screen and (max-width: $braun-m) {
      height: 96px;
    }
  }

  &__title {
    @media screen and (max-width: $braun-m) {
      font-size: 96px;
    }
  }

  &__subtitle {
    @media screen and (max-width: $braun-m) {
      line-height: 1.25;
    }
  }
}
