@media only screen and (max-width: $braun-s - 1) {
    .bra-loadMore {
        padding: 20px 0 0 0;
        
        &__text {
            font-size: 15px;
            margin-bottom: 20px;
        }
    }
}
