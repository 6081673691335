@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
    .bra-category-slider {
		.swiper-container.category-slider {
			.swiper-slide {
				max-width: 344px;

				width: calc( calc( 100vw - 40px ) / 2 - 20px );
			}
		}
	}
}
