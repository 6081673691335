@media only screen and (max-width: $braun-s - 1) {
    .bra-sticky-navigation {
        padding: 18px 20px 0;
        
        &[data-new-header].open {
            top: 60px
        }

        .bra-star-rating,
        .bra-sticky-navigation__title {
            display: none;
        }

        .bra-sticky-navigation__slide {
            overflow: visible;
        }
        &__name {
            font-size: 15px;
            line-height: 1.27;
        }
        &__price {
            font-size: 13px;
            margin-right: 10px;
        }
        &__identifier {
            display: none;
        }
        &__modelNum {
            display: block;
        }

        // &__gradient-left,
        // &__gradient {
        //     background-image: linear-gradient(to right, rgba(255, 255, 255, 0) -51%, $ui-07 100%);
        //     display: block;
        //     position: absolute;
        //     right: 0;
        //     bottom: 0;
        //     width: 30px;
        //     height: 38px;
        //     z-index: 1;
        // }

        // &__gradient-left {
        //     left: 0;
        //     transform: rotate(180deg);
        // }
    }
}
