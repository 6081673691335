.bra-mqs__wrapper {
  padding-top: 140px;
  &[data-new-header] {
    padding-top: 100px;
  }
}

.bra-mqs {
  overflow: hidden;

  // flex flex-col items-center text-center mb-10 relative z-10
  &__texts {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }

  // text-[18px] text-lime-600 lg:text-[24px] opacity-0
  &__kicker {
    font-family: var(--fontNameRoman);
    font-size: 18px;
    color: var(--support-02);
    opacity: 0;
    @media screen and (min-width: $braun-s) {
      font-size: 24px;
    }
  }

  // text-[60px] font-bold lg:text-[120px] -translate-y-[200px] opacity-0
  &__title {
    font-family: var(--fontNameBold);
    font-size: 60px;
    font-weight: bold;
    color: var(--brand-04);
    transform: translateY(-200px);
    opacity: 0;
    @media screen and (min-width: $braun-s) {
      font-size: 120px;
    }
  }

  // relative
  &__blobWrapper {
    position: relative;
  }

  // absolute w-[200px] h-[300px] bg-lime-600 rounded-full left-1/2 -translate-x-1/2 top-20
  &__blob {
    position: absolute;
    width: 150px;
    height: 280px;
    background-color: var(--support-02);
    border-radius: 9999px;
    top: 85px;
    left: calc(50% + 60px);
    transform: translateX(-50%);
    transform-origin: bottom center;
    @media screen and (min-width: 600px) {
      top: 150px;
      width: 200px;
      height: 450px;
      left: calc(50% + 120px);
    }
  }

  // relative block w-[400px] left-1/2 -translate-x-1/2 z-20 origin-top translate-y-[200%]
  &__engine {
    position: relative;
    z-index: 20;
    display: block;
    width: 200px;
    left: 50%;
    transform: translate(-50%, 200%);
    transform-origin: top;
    @media screen and (min-width: 600px) {
      width: 400px;
    }
  }

  // relative top-0 max-w-none block left-1/2 -translate-x-1/2 z-30
  &__blender {
    position: relative;
    z-index: 30;
    top: 0;
    max-width: none;
    display: block;
    left: 50%;
    transform: translateX(-50%);
  }

  // relative max-w-none block left-1/2 -translate-x-1/2 z-10 -top-[1000px]
  &__accessories {
    position: relative;
    z-index: 10;
    top: -1000px;
    max-width: none;
    display: block;
    left: 50%;
    transform: translateX(-50%);
  }
}