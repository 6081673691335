.bra-btnView {
    display: inline-flex;
    width: auto;
    height: auto;

    &__button {
        @include flex(wrap);
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border: none;
        background: none;
        background: lightpink;
        background-color: transparent;
        padding: 5px;
        box-sizing: border-box;
        -webkit-appearance: none;
        -moz-appearance: none;

        & .bra-icn {
            display: inline-block;
            width: 20px;
            height: 20px;

            &::before {
                display: inline-block;
                width: 20px;
                height: 20px;
                line-height: 20px;
                font-size: 20px;
                color: $ui-09;
            }
        }
        &.-active {
            & .bra-icn {
                &::before {
                    color: $brand-01;
                }
            }
        }
    }
}