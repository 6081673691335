.bra-articleBanner {
    font-size: 15px;
    line-height: normal;

    @include flex(wrap);
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 776px;
    background-color: $ui-03;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 48px;
    box-sizing: border-box;

    & .bra-articleBanDataBox {
        width: 45%;
    }
}
