.bra-imageBlock {
	display: flex;
	@media (-ms-high-contrast: none) {
		display: block;
	}
	flex-direction: column;

	width: calc(100% - 60px);
	max-width: 1440px;
	margin: 0 30px;

	font-size: 15px;
	line-height: normal;

	&__title {
		display: flex;
	}
	&__images {
		display: flex;
	}

	.bra-imageBlock__wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;

		text-align: center;

		.bra-subtitle {
			margin: 20px auto 10px auto;
		}
		.bra-paragraph {
			margin-bottom: 20px;
		}
	}

	&--spaced {
		.bra-imageBlock__wrapper + .bra-imageBlock__wrapper {
			margin-left: 20px
		}
	}

	&--slim {
		.bra-imageBlock__wrapper {
			max-height: 200px;
		}
		// .bra-image {
		// 	max-height: 50%;
		// }
	}

	&--captioned {
		.bra-imageBlock__wrapper {
			height: max-content;
			.bra-image {
				max-height: 50%;
			}
		}
	}
	&__content {
		padding: 0 10px;
		box-sizing: border-box;

		& .bra-subtitle {
			margin-bottom: 10px;
		}
		& .bra-paragraph {
			font-size: 15px;
		}
	}
}