.bra-color-slider {
    width: 100%;
	font-size: 15px;
	line-height: normal;

	.swiper-container.color-slider {
		.swiper-footer {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 85%;
			max-width: 1200px;
			margin: 0 auto;
			margin-top: 55px;

			.swiper-button-prev {
				order: 1;
			}
			.swiper-button-next {
				order: 3;
			}
			
			.swiper-scrollbar {
				order: 2;
				position: relative;
				left: 0;
				bottom: 0;
				height: 3px;
				width: 85%;
				background: $ui-08;

				.swiper-scrollbar-drag {
					background: $brand-01;
				}
			}

			.swiper-button-next,
			.swiper-button-prev {
				position: relative;
				background: none;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				width: auto;
				height: auto;
				margin: 0;
			}
		}
	}
}
