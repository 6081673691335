.bra-recipe-box-slides {
    max-width: 100%;
    width: 100%;

    .swiper-container {
        overflow: visible;
    }

    & .bra-recipe-box {
        @include hover-supported() {
            &:hover {
                & .bra-recipe-box__title {
                    border: none;
                }
            }
        }
    }
}