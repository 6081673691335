.bra-accordion {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    // max-height: 60px;
    border-bottom: 1px solid $support-02;
    background-color: $brand-03;
    transition: max-height 0.3s ease-out;
    will-change: max-height;
    box-sizing: content-box;
    overflow: hidden;

    font-size: 15px;
    line-height: normal;

    &__head {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
        min-height: 50px;
        // border-bottom: 1px solid $ui-01;
        padding-bottom: 10px;
        box-sizing: content-box;
        position: relative;
    }

    &__title {
        // @include ellipsis;
        display: block;
        width: calc(100% - 16px - 40px);
        height: auto;
        // min-height: 57px;
        line-height: 24px;
        font-size: 17px;
        color: $brand-01;
        transition: color 0.12s linear;
        padding: 13px 0 0 0;
        margin: 0 auto 0 0;
        box-sizing: border-box;
        // cursor: pointer;

        // &:hover {
        //     color: $brand-01;
        // }
    }
    &__arrowWrap {
        @include flex(wrap);
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;

        cursor: pointer;
        position: absolute;
        top: 10px;
        right: 0;
    }
    &__arrow {
        display: block;
        transform-origin: center;
        pointer-events: none;
        transform: rotate(0deg);
        transition: transform 0.22s ease;
    }
    &__content {
        display: flex;
        flex-wrap: wrap;
        // width: calc(100% - 32px);
        width: 100%;
        opacity: 0;
        //transition: opacity 0.12s linear;
        padding: 0 0 32px 0;
        box-sizing: content-box;
    }

    &--open {
        transition: max-height 0.2s ease-in;

        & .bra-accordion__title {
            color: $brand-01;
        }
        & .bra-accordion__arrow {
            transform: rotate(180deg);
        }
        & .bra-accordion__content {
            opacity: 1;
            //transition: opacity 0.32s linear;
        }
    }






    &--faq {
        border: 0;
        .bra-accordion__head {
            min-height: initial;
            padding: 5px 0;

            @include hover-supported() {
                &:hover {
                    .bra-accordion__title,
                    .bra-accordion__arrowWrap {
                        color: $support-02;
                    }
                }
            }
        }
        .bra-accordion__title {
            margin: 0;
            padding: 0;
            padding-left: 1.5em;
        }
        .bra-accordion__arrow {
            display: none;
        }
        .bra-accordion__arrowWrap {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            justify-content: flex-start;

            &::before {
                content: '';
                font-family: $fontNameIcons__legacy;
                font-style: normal;
                font-weight: normal;
                speak: none;
                display: inline-block;
                text-decoration: inherit;
                width: 1em;
                margin-right: .2em;
                text-align: center;
                font-variant: normal;
                text-transform: none;
                line-height: 1em;
                margin-left: .2em;
                -webkit-font-smoothing: antialiased;
                transition: transform .3s ease-in-out;
            }
        }

        &.bra-accordion--open {
            .bra-accordion__head {
                .bra-accordion__title,
                .bra-accordion__arrowWrap {
                    color: $support-02;
                }
            }
            .bra-accordion__arrowWrap {
                &::before {
                    transform: rotate(90deg);
                    transition: transform .3s ease-in-out;
                }
            }
        }
    }
}