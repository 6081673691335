.dln-braun {
  .dlg-omnibus-price {
    &__main-price {
      font-size: REM(15px);
      color: $brand-01;
    }

    &__old-price {
      font-size: REM(13px);
      color: $ui-09;
    }

    &__strike-price {
      order: 2;
    }

    &__label {
      order: 1;
    }
  }
}
