@media only screen and (min-width:$braun-s) and (max-width: $braun-m - 1) {
}


@media only screen and (max-width: $braun-m - 1) {
    .bra-prodGallery {
        &__thumbnails {
            width: 100%;
            height: auto;
            margin: 0;

            & .bra-prodGallery__swiperContainer {
                height: auto;
                padding: 0;

                & > .swiper-wrapper {
                    height: auto;
                    margin-bottom: 30px;

                    & .bra-prodGallery__slide {
                        align-items: center;
                        width: 100%;
                        height: 518px;
                        margin: 0;

                        &Image {
                            width: 100%;
                            height: 518px;

                            & .bra-image {
                                width: 100%;
                                height: 100%;
                                background-color: $brand-03;                                

                                & > img {
                                    width: 100%;
                                    height: 100%;
                                    object-fit: contain;
                                }
                            }
                        }
                        &--iframe {
                            background-color: $brand-01;
                        }
                        &Iframe {
                            width: 100%;
                            height: 0;
                            padding-bottom: 52.65%;
                            position: relative;

                            & > iframe {
                                width: 100%;
                                height: 100%;
                                position: absolute;
                                top: 0;
                                left: 0;
                                pointer-events: unset;
                            }
                            &Overlay,
                            &Poster {
                                // display: none;
                            }
                            &Overlay {
                                pointer-events: initial;
                                cursor: pointer;
                            }
                        }
                    }
                }
                & > .swiper-footer {
                    display: flex;
                    width: 100%;
                    margin: 0;
                }
                & > [class^="swiper-button"] {
                    display: none;
                }
            }
        }
        &__preview {
            display: none;
        }
    }
}
