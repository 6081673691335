.bra-recipe {
    font-size: 15px;
    line-height: normal;

    @include flex(wrap);
    align-content: flex-start;
    width: 100%;
    height: auto;

    & .bra-videoBanner {
        margin: 0 0 24px 0;
    }
    &__title {
        display: block;
        width: 100%;
        line-height: 20px;
        font-size: 17px;
        font-weight: bold;
        color: $brand-01;
        margin: 0 0 24px 0;
    } 
    &__unorderedList,
    &__orderedList {
        display: block;
        width: 100%;
        height: auto;
        font-size: 17px;
        list-style-position: inside;
        margin: 0 0 24px 0;

        &Item {
            line-height: 20px;
            font-size: 17px;
            font-weight: 400;
            color: $brand-01;
        }
    }
    &__unorderedList {
        list-style-type: disc;
    }
    & .bra-link {
        margin: 0 0 0 auto;
    }
}